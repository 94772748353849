<template>
  <v-card outlined>
    <v-card-title class="pl-8">Новый объект к объявлению</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" validation>
        <v-list>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12>
                <v-text-field
                  :maxlength="$store.getters.titleAdMaxLength"
                  v-model="name"
                  type="text"
                  label="Название"
                  :rules="[v => !!v || 'Необходимо ввести название объявления']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="volume"
                  type="text"
                  label="Объем работ"
                  :rules="[v => !!v || 'Необходимо ввести объем работ']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="street"
                  type="text"
                  label="Улица"
                  :rules="[
                    v => !!v || 'Необходимо ввести улицу',
                    v =>
                      (v && v.length <= this.maxStreetLength) ||
                      `Наименование улицы должно быть не более ${this.maxStreetLength} символов`
                  ]"
                  clearable
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="homenumber"
                  type="text"
                  label="Дом"
                  :rules="[
                    v => !!v || 'Необходимо ввести дом',
                    v =>
                      (v && v.length <= this.maxHouseNumberLength) ||
                      `Номер дома должен быть не более ${this.maxHouseNumberLength} символов`
                  ]"
                  clearable
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-card-subtitle class="px-0">Приложения</v-card-subtitle>
          <v-card-text>
            <v-layout v-for="(item, i) in attach" :key="i" wrap class="justify-space-between">
              <v-flex xs12 sm5 class="mb-4">
                <v-file-input
                  type="file"
                  outlined
                  label="Загрузить документ"
                  v-model="item.file"
                  :rules="uploadRules"
                  dense
                ></v-file-input>
              </v-flex>
              <v-flex d-flex xs12 sm5 class="mb-4">
                <v-text-field
                  :maxlength="$store.getters.fileNameMaxLength"
                  v-model="item.name"
                  type="text"
                  label="Имя файла"
                  :rules="[v => !!v || 'Необходимо ввести имя файла']"
                  outlined
                  dense
                ></v-text-field>
                <v-btn x-large icon color="primary" class="ml-3 mt-n2" @click="removeAttach(i)">
                  <v-icon>delete_outline</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-4">
            <v-btn outlined color="primary" @click="addAttach">
              <div>Добавить приложение</div>
            </v-btn>
          </v-card-actions>
        </v-list>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        :to="{
          path: '/organizer/tender',
          query: { id: tenderId }
        }"
      >
        отмена
      </v-btn>
      <v-btn
        outlined
        color="primary"
        @click="openDialogConfirm('Сохранить объект?', 'addLot')"
        :disabled="!valid"
      >
        Сохранить объект
      </v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      tenderId: this.$route.query.tenderId,
      name: '',
      volume: '',
      street: '',
      homenumber: '',
      attach: [{}],
      valid: false,
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.size < 10000000) || 'Не более 10 MB!'
      ],
      maxHouseNumberLength: 50,
      maxStreetLength: 50
    };
  },
  methods: {
    addLot() {
      return new Promise(resolve => {
        const action = 'addLot';
        const dataParams = {
          tenderId: this.tenderId,
          name: this.name,
          street: this.street,
          homenumber: this.homenumber,
          volume: this.volume,
          comment: this.comment,
          attach: this.attach
        };
        const attach = this.attach;
        this.$store
          .dispatch('SendForm', { action, dataParams, attach })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo('Ошибка добавления объекта', 'redirectToMainPage');
            } else {
              this.openDialogInfo('Объект сохранен', 'back');
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    addAnotherLot() {
      return new Promise(resolve => {
        this.addLot().then(resp => {
          if (resp.id) {
            this.$router.push({
              path: '/organizer/addlot',
              query: { tenderId: resp.id }
            });
          }
          resolve(resp);
        });
      });
    },
    addAndPublish() {
      return new Promise(resolve => {
        const action = 'addAndPublish';
        const dataParams = {
          tender: this.tender,
          lots: this.lots
        };
        const attach = this.files;
        this.$store
          .dispatch('SendForm', { action, dataParams, attach })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.push({
        path: '/organizer/tender',
        query: { id: this.tenderId }
      });
    },
    addAttach() {
      if (this.attach.length < 10) {
        this.attach.push({});
      }
    },
    removeAttach(i) {
      if (this.attach.length > 1) {
        this.attach = [...this.attach.slice(0, i), ...this.attach.slice(i + 1)];
      }
    }
  }
};
</script>

<style scoped>
.date-picker {
  height: 48px;
}
.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}
.data_wrap {
  max-width: max-content;
}
</style>
