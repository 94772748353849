<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <h1>Упс. А нет такой страницы :(</h1>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
