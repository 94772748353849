<template>
  <v-card outlined>
    <v-card-title style="justify-content: center">Карточка пользователя</v-card-title>
    <v-card-text>
      <v-list two-line>
        <v-list-item
          v-for="(item, i) in fields"
          :key="i"
        >
          <v-layout
            wrap
            class="item_wrap"
          >
            <v-flex
              xs12
              sm6
              d-flex
              class="title_data"
            >
              <div>{{ item.title }}</div>
            </v-flex>
            <v-flex
              xs12
              sm6
              class="data_wrap"
            >
              <v-text-field
                class="data"
                v-model="item.value"
                type="text"
                outlined
                dense
                readonly
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item v-show="userRole != 'admin'">
          <v-layout
            wrap
            class="item_wrap"
          >
            <v-flex
              xs12
              sm6
              d-flex
              class="title_data"
            >
              <div>Протокол согласия</div>
            </v-flex>
            <v-flex
              xs12
              sm6
              class="data_wrap"
            >
              <v-btn
                outlined
                class="btn_action"
                color="primary"
              >
                <DownloadExcel
                  :data="data"
                  :fields="fileHeaders"
                  worksheet="Протокол"
                  :name="login + '_protocol.xls'"
                >Выгрузить</DownloadExcel>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        @click="back"
        outlined
        class="btn_action"
        color="primary"
      >назад</v-btn>
    </v-card-actions>
    <Dialog
      @dialogDataOut="dialogDataOut = $event"
      :dialogDataIn="dialogDataIn"
    ></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      data: [],
      userRole: '',
      login: '',
      fileHeaders: {
        ID: 'id',
        'ФИО пользователя': 'fio',
        Логин: 'login',
        'E-mail': 'email',
        'Наименование организации': 'orgname',
        Роль: 'role',
        'ИНН компании': 'inn',
        'КПП компании': 'kpp',
        'Дата, время подачи согласия на обработку персональных данных': 'approvedata_datetime',
        'Дата, время подачи согласия на получение рассылки': 'approvemail_datetime',
        'Дата, время отзыва согласия': 'forget_datetime',
        'Дата, время подачи заявления на отзыв согласия': 'revoke_datetime',
      },
      fields: [
        {
          name: 'login',
          title: 'Логин',
          value: ''
        },
        {
          name: 'email',
          title: 'Email',
          value: ''
        },
        {
          name: 'fio',
          title: 'ФИО',
          value: ''
        },
        {
          name: 'userphone',
          title: 'Телефон',
          value: ''
        },
        {
          name: 'orgname',
          title: 'Организация',
          value: ''
        },
        {
          name: 'approvedata_datetime',
          title: 'Согласие на обработку персональных данных',
          value: ''
        },
        {
          name: 'approvemail_datetime',
          title: 'Согласие на получение рассылки',
          value: ''
        },
        {
          name: 'regexp',
          title: 'Срок действия регистрации',
          value: ''
        }
      ],
      userId: this.$route.query.id
    };
  },
  methods: {
    getUserInfoAdmin() {
      return new Promise(resolve => {
        const action = 'getUserInfoAdmin';
        const dataParams = { userId: this.userId };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            const response = resp.data;
            this.fields.forEach(el => {
              el.value = response[el.name] === null ? '' : response[el.name];
              if (el.name == 'approvedata_datetime') el.value = response[el.name] ?? 'Согласие не получено';
              if (el.name == 'approvemail_datetime') el.value = response[el.name] ?? 'Согласие не получено';
            });
            this.userRole = response.role;
            this.login = response.login;
            this.data.push(response);
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },    
    back() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.getUserInfoAdmin();
  },
  computed: {}
};
</script>

<style scoped>
.data {
  max-width: 300px;
}

.title_data {
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.data_wrap {
  display: flex;
  justify-content: left;
}

.item_wrap {
  justify-content: center;
}

@media screen and (min-width: 1px) and (max-width: 599px) {
  .data {
    justify-content: center;
  }

  .title_data {
    justify-content: center;
    padding-right: 0px;
    margin-bottom: 5px;
  }
}
</style>
