import "../css/tender.css";
import "../css/style.css";

export default {
  data() {
    return {
      dialogDataOut: { close: false, action: "" },
      dialogDataIn: {
        modal: false,
        message: "",
        confirm: false,
        action: "",
      },
    };
  },
  methods: {
    openDialogInfo(message, action = "") {
      this.dialogDataIn.modal = true;
      this.dialogDataIn.message = message;
      this.dialogDataIn.action = action;
    },
    openDialogConfirm(message, action) {
      this.dialogDataIn.modal = true;
      this.dialogDataIn.message = message;
      this.dialogDataIn.confirm = true;
      this.dialogDataIn.action = action;
    },
    redirectToMainPage() {
      this.$router.push("/organizer/listNewTenders");
    },
  },
  watch: {
    dialogDataOut(v) {
      //Если нажата кнопка "подтвердить действие", то выполняем метод переданный из компонента модального окна (Dialog.vue)
      if (v.action) eval("this." + v.action + "()");
      this.dialogDataIn.modal = false;
      this.dialogDataIn.message = "";
      this.dialogDataIn.confirm = false;
      this.dialogDataIn.action = "";
      this.dialogDataOut.close = false;
      this.dialogDataOut.action = "";
    },
  },
};
