'use strict';

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import pageSettings from './pageSettings';
import Axios from 'axios';
import VueCookies from 'vue-cookies';

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  modules: { auth, pageSettings },
  state: {
    id: Vue.$cookies.get('id') || '',
    role: Vue.$cookies.get('role') || null,
    token: Vue.$cookies.get('token') || '',
    org: Vue.$cookies.get('org') || '',
    login: Vue.$cookies.get('login') || '',
    insurance: Vue.$cookies.get('insurance') || '',
    limit: Vue.$cookies.get('limit') || '',
    approveData: Vue.$cookies.get('approveData') || false, // согласие на обработку персональных даннных
    approveMail: Vue.$cookies.get('approveMail') || false, // согласие на получение рассылки
    requestRevoke: Vue.$cookies.get('requestRevoke') == 'true' ? true : false, // запрос на отзыв согласий
    restrict: Vue.$cookies.get('restrict') || false,
    ymPathHash: '/#',
    innMinLength: 10,
    innMaxLength: 12,
    kppLength: 9,
    ogrnMinLength: 13,
    ogrnMaxLength: 15,
    accountLength: 20,
    corrLength: 20,
    bicLength: 9,
    titleAdMaxLength: 100,
    fileNameMaxLength: 50,
    menuType: Vue.$cookies.get('menuType') || 'newtenders'
  },
  actions: {
    Api(context, params) {
      return new Promise(resolve => {
        const pr = new URLSearchParams();
        pr.append('action', params.action);
        pr.append('params', JSON.stringify(params.dataParams));
        pr.append('id', this.state.id);
        pr.append('role', this.state.role);
        Axios({
          method: 'POST',
          url: process.env.VUE_APP_API_URL,
          data: pr,
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'x-xsrf-token': this.state.token
          }
        })
          .then(resp => {
            if (
              resp.data.result === 'error' &&
              resp.data.error_msg === 'reader' &&
              context.state.role === 'admin'
            ) {
              alert('Действие запрещено. У вас права на чтение!');
            } else if (
              resp.data.result === 'error' &&
              resp.data.error_msg === 'super' &&
              context.state.role === 'admin'
            ) {
              alert('Действие запрещено!');
            }
            resolve(resp.data);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    SendForm(context, params) {
      return new Promise(resolve => {
        const pr = new FormData();
        pr.append('action', params.action);
        pr.append('params', JSON.stringify(params.dataParams));
        pr.append('id', this.state.id);
        pr.append('role', this.state.role);
        //Если один файл передается то итерировать не надо
        if (Array.isArray(params.attach)) {
          for (const file of params.attach) {
            pr.append('files[]', file.file);
          }
        } else {
          pr.append('files[]', params.attach);
        }

        Axios({
          method: 'POST',
          url: process.env.VUE_APP_API_URL,
          data: pr,
          headers: {
            'content-type': 'multipart/form-data',
            'x-xsrf-token': this.state.token
          }
        })
          .then(resp => {
            if (
              resp.data.result === 'error' &&
              resp.data.error_msg === 'reader' &&
              context.state.role === 'admin'
            ) {
              alert('Действие запрещено. У вас права на чтение!');
            }
            resolve(resp.data);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  mutations: {
    authorize: (state, payload) => {
      state.token = payload.token;
      state.id = payload.id;
    },
    kickoff: state => {
      state.role = null;
      state.id = '';
      state.token = '';
      state.org = '';
      state.login = '';
      state.insurance = '';
      state.limit = '';
      state.approveData = false;
      state.approveMail = false;
      state.requestRevoke = false;
      state.restrict = false;
      Vue.$cookies.remove('role');
      Vue.$cookies.remove('id');
      Vue.$cookies.remove('token');
      Vue.$cookies.remove('org');
      Vue.$cookies.remove('login');
      Vue.$cookies.remove('insurance');
      Vue.$cookies.remove('limit');
      Vue.$cookies.remove('approveData');
      Vue.$cookies.remove('approveMail');
      Vue.$cookies.remove('requestRevoke');
      Vue.$cookies.remove('restrict');
    },
    setId: (state, payload) => {
      state.id = payload.id;
      Vue.$cookies.set('id', payload.id);
    },
    setRole: (state, payload) => {
      state.role = payload.role;
      Vue.$cookies.set('role', payload.role);
    },
    setInsurance: (state, payload) => {
      const insurance = payload.insurance ? 1 : 0;
      state.insurance = insurance;
      Vue.$cookies.set('insurance', insurance);
    },
    setLimit: (state, payload) => {
      const limit = payload.limit ? 1 : 0;
      state.limit = limit;
      Vue.$cookies.set('limit', limit);
    },
    setToken: (state, payload) => {
      state.token = payload.token;
      Vue.$cookies.set('token', payload.token);
    },
    setLogin: (state, payload) => {
      state.login = payload.login;
      Vue.$cookies.set('login', payload.login);
    },
    setPassword: (state, payload) => {
      state.password = payload.password;
    },
    setOrg: (state, payload) => {
      state.org = payload.org;
      Vue.$cookies.set('org', payload.org);
    },
    setMenuType: (state, payload) => {
      state.menuType = payload.menuType;
      Vue.$cookies.set('menuType', payload.menuType);
    },
    setApproveData: (state, payload) => {
      state.approveData = payload.approveData;
      Vue.$cookies.set('approveData', payload.approveData);
    },
    setApproveMail: (state, payload) => {
      state.approveMail = payload.approveMail;
      Vue.$cookies.set('approveMail', payload.approveMail);
    },
    setRequestRevoke: (state, payload) => {
      state.requestRevoke = payload.requestRevoke;
      Vue.$cookies.set('requestRevoke', payload.requestRevoke);
    },
    setRestrict: (state, payload) => {
      const restrict = payload.restrict;
      state.restrict = restrict;
      Vue.$cookies.set('restrict', restrict);
    }
  },
  getters: {
    id: state => state.id,
    role: state => state.role,
    insurance: state => (state.insurance == true || state.insurance == 'true' ? true : false),
    limit: state => (state.limit == true || state.limit == 'true' ? true : false),
    approveData: state => state.approveData,
    approveMail: state => state.approveMail,
    requestRevoke: state => state.requestRevoke,
    login: state => state.login,
    super: state => (process.env.VUE_APP_SUPER_ID == state.id ? true : false),
    token: state => state.token,
    org: state => state.org,
    isAuthorized: state => !!state.token,
    password: state => state.password,
    ymPathHash: state => state.ymPathHash,
    innMinLength: state => state.innMinLength,
    innMaxLength: state => state.innMaxLength,
    kppLength: state => state.kppLength,
    ogrnMinLength: state => state.ogrnMinLength,
    ogrnMaxLength: state => state.ogrnMaxLength,
    accountLength: state => state.accountLength,
    corrLength: state => state.corrLength,
    bicLength: state => state.bicLength,
    titleAdMaxLength: state => state.titleAdMaxLength,
    fileNameMaxLength: state => state.fileNameMaxLength,
    menuType: state => state.menuType,
    restrict: state => (state.restrict == true || state.restrict == 'true' ? true : false)
  }
});
