<template>
  <div>
    <v-card-text>
      <v-form ref="formUser" v-model="validUser" lazy-validation>
        <v-text-field
          class="input_custom"
          name="lastname"
          ref="lastname"
          label="Фамилия"
          type="text"
          v-model="lastname"
          :rules="[(v) => !!v || 'Необходимо ввести фамилию']"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="firstname"
          ref="firstname"
          label="Имя"
          type="text"
          v-model="firstname"
          :rules="[(v) => !!v || 'Необходимо ввести имя']"
          required
          outlined
          dense
          clearable
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="secondname"
          label="Отчество"
          ref="secondname"
          type="text"
          v-model="secondname"
          :rules="[(v) => !!v || 'Необходимо ввести отчество']"
          required
          outlined
          dense
          clearable
        ></v-text-field>       
        <v-text-field
          class="input_custom"
          name="inn"
          ref="inn"
          label="Ввести ИНН"
          type="number"
          :error-messages="innMessage"
          v-model.lazy="inn"
          :rules="INNRules"
          @change="advanceCheckInn()"
          required
          outlined
          dense
          counter
        >
      </v-text-field>
        <v-text-field
          class="input_custom"
          name="email"
          ref="email"
          label="E-mail"
          type="text"
          :error-messages="emailMessage"
          v-model.lazy="email"
          :rules="emailRules"
          @change="advanceCheckEmail()"
          required
          outlined
          dense
          clearable
        >
        </v-text-field>
        <v-text-field
          class="input_custom"
          name="phoneUser"
          label="Телефон пользователя"
          ref="phoneUser"
          v-model="phoneUser"
          :rules="PhoneUserRules"
          :error-messages="phoneUserMessage"
          outlined
          dense
          counter
        ></v-text-field>
        <v-text-field
          class="input_custom"
          name="login"
          label="Логин"
          type="text"
          ref="login"
          :error-messages="loginMessage"
          v-model.lazy="login"
          :rules="nameRules"
          @change="advanceCheckLogin()"
          required
          outlined
          dense
          clearable
        >
        </v-text-field>
        <v-text-field
          id="password"
          ref="password"
          name="password"
          label="Пароль"
          type="password"
          v-model="password"
          :rules="passwordRules"
          outlined
          required
          dense
          clearable
        ></v-text-field>
        <v-text-field
          id="passwordConfirm"
          ref="passwordConfirm"
          label="Подтверждение пароля"
          type="password"
          v-model="passwordConfirm"
          :rules="passwordConfirmRules"
          outlined
          required
          dense
          clearable
        ></v-text-field>
        <v-layout column class="justify-start">
          <v-flex>
            <span>Для регистрации необходимо подтвердить ваше </span>
            <a color="primary" :href="uploadUrl + 'Согласие_на_получение_рассылки.pdf'" target="blank">
              <span>согласие на получение рассылки</span>
            </a>
            <span> и </span>
            <a color="primary" :href="uploadUrl + 'Согласие_на_обработку_ПД.pdf'" target="blank">
              <span>согласие на обработку персональных данных.</span>
            </a>
          </v-flex>
          <v-flex>
            <v-checkbox v-model="approveMail" label="Согласен на получение рассылки"></v-checkbox>
            <v-checkbox v-model="approveData" label="Согласен на обработку ПД"></v-checkbox>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-4">
      <v-btn
        color="primary"
        @click="validateUser"
        :disabled="regUserBtnStatus"
        block
        >Зарегистрироваться</v-btn
      >
    </v-card-actions>
    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="sitekey"
      @verify="regUser"
      @expired="onCaptchaExpired"
    />
    <v-dialog v-model="regDialog" width="300">
      <v-flex>
        <v-card :color="colorRegDialog">
          <v-card-text class="white--text">
            {{ textRegDialog }}
            <v-progress-linear
              indeterminate
              v-if="regLinear"
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-dialog>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export default {
  components: { VueRecaptcha },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      orgId: "",
      tab: null,
      sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      regDialog: false,
      textRegDialog: "",
      colorRegDialog: "",
      regLinear: false,
      lastname: "",
      firstname: "",
      secondname: "",
      inn: "",
      email: "",
      login: "",
      loginMessage: "",
      emailMessage: "",
      innMessage: "",
      emailNewOrgMessage: "",
      phoneUserMessage: "",
      phoneUser: "",
      password: "",
      passwordConfirm: "",
      minPassLen: 6,
      validUser: false,
      innMinLength: this.$store.getters.innMinLength,
      innMaxLength: this.$store.getters.innMaxLength,
      INNRules: [
        (v) => !!v || "Необходимо ввести ИНН",
        (v) =>
          (v.length >= this.innMinLength && v.length <= this.innMaxLength) ||
          `От ${this.innMinLength} до ${this.innMaxLength} цифр`,
      ],
      nameRules: [(v) => !!v || "Необходимо ввести логин"],
      emailRules: [
        (v) => !!v || "Введите E-mail",
        (v) => emailRegex.test(v) || "E-mail указан неверно",
      ],
      passwordRules: [
        (v) => !!v || "Необходимо ввести пароль",
        (v) =>
          (v && v.length >= this.minPassLen) ||
          `Пароль должен быть не менее ${this.minPassLen} символов`,
      ],
      passwordConfirmRules: [
        (v) => !!v || "Введите пароль",
        (v) => v === this.password || "Пароли не соответствует",
      ],
      PhoneUserRules: [
        (v) => !!v || "Введите телефон пользователя",
        (v) => (v.length <= 50 || 'не более 50 символов')
      ],
      noDataText: "Нет данных",
      approveData: false,
      approveMail: false
    };
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    alertRegStatus(colorRegDialog, textRegDialog, regDialog, regLinear) {
      (this.colorRegDialog = colorRegDialog),
        (this.textRegDialog = textRegDialog),
        (this.regDialog = regDialog),
        (this.regLinear = regLinear);
    },
    advanceCheckInn() {
      if (this.$refs.inn.validate()) {
        return new Promise((resolve) => {
          const action = "advanceCheckInn";
          const dataParams = {
            inn: this.inn,
          };
          this.$store
            .dispatch("login", { action, dataParams })
            .then((resp) => {
              if (resp.result == "success") {
                this.innMessage = "";
                this.orgId = resp.id;
              } else {
                this.innMessage =
                  "Организации с таким ИНН не существует";
              }
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    },
    advanceCheckEmail() {
      if (this.$refs.email.validate()) {
        return new Promise((resolve) => {
          const action = "advanceCheckEmail";
          const dataParams = {
            email: this.email,
          };
          this.$store
            .dispatch("login", { action, dataParams })
            .then((resp) => {
              if (resp.result == "success") {
                this.emailMessage = "";
              } else {
                this.emailMessage =
                  "Пользователь с таким E-mail уже существует";
              }
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    },
    advanceCheckLogin() {
      if (this.$refs.login.validate()) {
        return new Promise((resolve) => {
          const action = "advanceCheckLogin";
          const dataParams = {
            login: this.login,
          };
          this.$store
            .dispatch("login", { action, dataParams })
            .then((resp) => {
              if (resp.result == "success") {
                this.loginMessage = "";
              } else {
                this.loginMessage =
                  "Пользователь с таким логином уже существует";
              }
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    },
    validateUser() {
      if (this.$refs.formUser.validate()) this.$refs.recaptcha.execute();
    },
    regUser(recaptchaToken) {
      this.alertRegStatus("blue", "Регистрация...", true, true);
      return new Promise((resolve) => {
        const action = "regUser";
        const dataParams = {
          lastname: this.lastname,
          firstname: this.firstname,
          secondname: this.secondname,
          orgId: this.orgId,
          email: this.email,
          login: this.login,
          phoneUser: this.phoneUser,
          password: this.password,
          approveData: this.approveData,
          approveMail: this.approveMail,
          recaptchaToken: recaptchaToken,
        };
        this.$store
          .dispatch("login", { action, dataParams })
          .then((resp) => {
            switch (resp.result) {
              case "success":
                this.alertRegStatus(
                  "success",
                  "На вашу почту отправлено сообщение. Для подтверждения регистрации перейдите по ссылке в письме",
                  true,
                  false
                );
                setTimeout(() => {
                  this.$router.push("/login");
                }, 6000);
                break;
              case "error_login":
                this.alertRegStatus(
                  "error",
                  "Пользователь с таким логином уже существует",
                  true,
                  false
                );
                setTimeout(() => {
                  this.regDialog = false;
                }, 3000);
                break;
              case "error_email":
                this.alertRegStatus(
                  "error",
                  "Пользователь с таким email уже существует",
                  true,
                  false
                );
                setTimeout(() => {
                  this.regDialog = false;
                }, 3000);
                break;
              default:
                this.alertRegStatus(
                  "error",
                  "Ошибка регистрации. Попробуйте позже",
                  true,
                  false
                );
                setTimeout(() => {
                  this.regDialog = false;
                }, 3000);
                break;
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  computed: {
    regUserBtnStatus() {
      if (
        this.lastname != "" &&
        this.firstname != "" &&
        this.secondname != "" &&
        this.orgId !== "" &&
        this.email != "" &&
        this.login != "" &&
        this.password != "" &&
        this.passwordConfirm != "" &&
        this.validUser &&
        this.approveData && 
        this.approveMail &&
        this.phoneUser
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.advanceCheckInn();
    this.advanceCheckEmail();
    this.advanceCheckLogin();
  },
};
</script>
<style scoped>
.reset_btn_wrap {
  text-align: end;
}
.input_custom > input {
  background-color: rgb(255, 255, 255) !important;
}
</style>
