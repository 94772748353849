<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0">Объявление «{{ tenderInfo.name }}»</v-card-title>
    <v-card-text class="px-0">
      <v-list two-line>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.tenderid }}</v-list-item-title>
                <v-list-item-subtitle>Номер объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>
                  {{ formatCurrency(tenderInfo.startamount) }} руб.
                </v-list-item-title>
                <v-list-item-subtitle>Начальная стоимость работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>г. {{ tenderInfo.city }}</v-list-item-title>
                <v-list-item-subtitle>Полный адрес</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.type }}</v-list-item-title>
                <v-list-item-subtitle>Тип работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.startauctiondate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата начала действия объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.endauctiondate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата окончания действия объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.startworkdate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата начала работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.endworkdate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата окончания работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.orgname }}</v-list-item-title>
                <v-list-item-subtitle>Заказчик работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>
                  {{ tenderInfo.lastname }} {{ tenderInfo.firstname }} {{ tenderInfo.secondname }}
                </v-list-item-title>
                <v-list-item-subtitle>Исполнитель заказчика</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(createDate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата создания объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ getUrgency(tenderInfo.urgency) }}</v-list-item-title>
                <v-list-item-subtitle>Срочность исполнения</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.orderid }}</v-list-item-title>
                <v-list-item-subtitle>Номер заказа на работы</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>
                  {{ tenderInfo.unpublishreason ? tenderInfo.unpublishreason : 'Hет данных' }}
                </v-list-item-title>
                <v-list-item-subtitle>Причины снятия объявления с публикации</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>

        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title class="item_title_custom">
                  {{ whiteList }}
                </v-list-item-title>
                <v-list-item-subtitle>Видимость для подрядчиков</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>
                  {{ tenderInfo.totalcost ? tenderInfo.totalcost : 'Нет суммы' }}
                </v-list-item-title>
                <v-list-item-subtitle>Итоговая стоимость работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>

        <v-list-item class="px-0">
          <v-list-item-content>
            <v-layout wrap>
              <v-flex xs12 sm2 class="mb-4 mr-5">
                <v-list-item-title>{{ proposalsCount }}</v-list-item-title>
                <v-list-item-subtitle>Всего заявок</v-list-item-subtitle>
              </v-flex>
              <v-flex xs12 sm2 class="mb-4 mr-5">
                <v-list-item-title class="new_proposal">{{ proposalsCountNew }}</v-list-item-title>
                <v-list-item-subtitle>Новых заявок</v-list-item-subtitle>
              </v-flex>
              <v-flex xs12 sm2 class="mb-4 mr-5">
                <v-btn
                  class="btn_action"
                  outlined
                  color="primary"
                  :to="{
                    path: '/admin/proposalsListAdmin',
                    query: { tenderId }
                  }"
                >
                  Заявки
                </v-btn>
              </v-flex>
              <v-flex sm12 md2>
                <v-btn
                  v-if="this.tenderInfo.status == 'publish'"
                  outlined
                  color="primary"
                  @click="generateLinkTender()"
                >
                  Сформировать ссылку
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0 mb-4">
          <v-layout>
            <v-flex>
              <v-textarea
                v-model="comment"
                label="Комментарий"
                outlined
                dense
                hide-details
                readonly
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0 my-4 chat_list_item">
          <v-expansion-panels tile>
            <v-expansion-panel>
              <v-expansion-panel-header>
                История
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-card-subtitle v-show="!chat.length">
                        Сообщений нет
                      </v-card-subtitle>
                      <v-card-text class="px-0">
                        <v-list two-line max-height="400" class="overflow-y-auto">
                          <v-list-item v-for="(item, i) in chat" :key="i">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  `${item.datetime} - ${item.lastname} ${item.firstname} ${
                                    item.secondname
                                  } (${translateTenderRole(item.role)})`
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ item.action }}
                              </v-list-item-subtitle>
                              <v-sheet class="pa-4" color="grey lighten-3">
                                <v-flex>
                                  {{ item.message }}
                                </v-flex>
                                <v-flex class="mt-4">
                                  <v-layout column>
                                    <v-flex v-for="(attach, i) in item.attachments" :key="i">
                                      <v-btn
                                        class="pa-0"
                                        x-small
                                        plain
                                        color="primary"
                                        :href="
                                          uploadUrl +
                                            tenderInfo.tenderid +
                                            '/done/' +
                                            attach.attachment
                                        "
                                        download
                                      >
                                        {{ attach.filename }}
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-sheet>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-title>Документация:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-show="!attachments.length && !attachmentsDone.length">
          <v-list-item-subtitle>Документации нет</v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="px-0" v-for="(attach, i) in attachments" :key="i">
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="align-center">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="uploadUrl + attach.tenderid + '/' + attach.attachment"
                download
              >
                скачать
              </v-btn>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0" v-for="(attach, i) in attachmentsDone" :key="'done' + i">
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="align-center">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="uploadUrl + attach.tenderid + '/done/' + attach.attachment"
                download
              >
                скачать
              </v-btn>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item class="px-0">
          <v-list-item-title>Объекты:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-if="!lots.length">
          <v-list-item-subtitle>Объектов нет</v-list-item-subtitle>
        </v-list-item>
        <v-list dense v-else>
          <v-list-item-group color="primary">
            <template v-for="(lot, i) in lots">
              <v-list-item
                :key="i"
                :to="{
                  path: '/admin/lotadmin',
                  query: { id: lot.id }
                }"
                link
              >
                <v-list-item-content>
                  <v-list-item-title v-text="lot.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <v-divider class="px-0"></v-divider>
        <v-list-item v-if="this.tenderInfo.status == 'ready'" class="px-0">
          <v-layout column>
            <v-flex xs12 class="my-6">
              <v-list-item-title>Вернуть на доработку</v-list-item-title>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap class="justify-space-between">
                <v-flex sx12 sm7 d-flex class="align-center mr-2">
                  <v-textarea
                    counter
                    v-model="reworkReason"
                    outlined
                    label="Причина возврата"
                    rows="2"
                    clearable
                    dense
                    no-resize
                    :rules="textAreaRules"
                  ></v-textarea>
                </v-flex>
                <v-flex sx12 sm2 d-flex>
                  <v-btn
                    class="btn_action mt-3"
                    color="primary"
                    :disabled="!reworkReason || reworkReason.length > maxLengthTextarea"
                    @click="openDialogConfirm('Вернуть на доработку?', 'rework')"
                  >
                    Вернуть
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item v-else-if="this.tenderInfo.status == 'cancel'" class="px-0">
          <v-layout column>
            <v-flex xs12 class="mt-6">
              <v-list-item-title>Снять с публикации?</v-list-item-title>
            </v-flex>
            <v-flex xs12 class="my-4">
              <v-layout wrap class="justify-space-around">
                <v-flex sx12 sm2 d-flex class="data_wrap">
                  <v-btn
                    class="mt-3"
                    color="primary"
                    @click="
                      openDialogConfirm(
                        'Объявлению будет присвоен статус: `На доработку`. Вернуть на доработку?',
                        'unpublish'
                      )
                    "
                  >
                    Снять с публикации
                  </v-btn>
                </v-flex>
                <v-flex sx12 sm2 d-flex class="data_wrap">
                  <v-btn
                    class="mt-3"
                    color="primary"
                    @click="openDialogConfirm('Опубликовать объявление?', 'cancelUnPublishTender')"
                  >
                    Вернуть на публикацию
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item v-else-if="this.tenderInfo.status == 'done'" class="px-0">
          <v-layout column>
            <v-flex xs12 class="mt-6">
              <v-list-item-title>Подтвердить выполнение?</v-list-item-title>
            </v-flex>
            <v-flex xs12 class="my-4">
              <v-layout wrap class="justify-space-around">
                <v-flex sx12 sm2 d-flex class="data_wrap">
                  <v-btn
                    class="mt-3"
                    color="primary"
                    :disabled="!cancelDoneReason"
                    @click="
                      openDialogConfirm(
                        'Объявлению будет присвоен статус: `Ожидает выполнения`. Отменить выполнение?',
                        'cancelDone'
                      )
                    "
                  >
                    Отменить
                  </v-btn>
                </v-flex>
                <v-flex sx12 sm2 d-flex class="data_wrap">
                  <v-btn
                    class="mt-3"
                    color="primary"
                    @click="openDialogConfirm('Подтвердить выполнение?', 'confirmDone')"
                  >
                    Подтвердить
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap class="justify-space-between">
                <v-flex sx12 d-flex class="align-center mr-2">
                  <v-textarea
                    counter
                    v-model="cancelDoneReason"
                    outlined
                    label="Причина отмены выполнения"
                    rows="2"
                    clearable
                    dense
                    no-resize
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn outlined color="primary" :to="'/admin/' + menuType">к списку объявлений</v-btn>
      <v-btn
        v-if="tenderInfo.status == 'ready'"
        outlined
        color="primary"
        @click="openDialogConfirm('Опубликовать объявление?', 'publishTender')"
      >
        Опубликовать
      </v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import mixinDialog from '../mixin/js/mixinDialog';
import mixinTender from '../mixin/js/mixinTender';
import Lib from '../mixin/js/mixinLib';

export default {
  mixins: [mixinDialog, mixinTender, Lib],
  data() {
    return {
      maxLengthTextarea: 200,
      reworkReason: '',
      cancelDoneReason: '',
      uploadUrl: process.env.VUE_APP_UPLOADS_TENDER_URL,
      tenderId: this.$route.query.id,
      menuType: this.$store.getters.menuType,
      tenderInfo: '',
      attachments: [],
      attachmentsDone: [],
      whiteList: 'Виден всем',
      lots: [],
      comment: '',
      proposalsCount: '0',
      proposalsCountNew: '0',
      createDate: '',
      textAreaRules: [
        v => !!v || 'Необходимо ввести причину',
        v =>
          v.length <= this.maxLengthTextarea ||
          'Максимальное количество знаков - ' + this.maxLengthTextarea
      ],
      chat: []
    };
  },
  methods: {
    rework() {
      return new Promise(resolve => {
        const action = 'rework';
        const dataParams = {
          tenderId: this.tenderId,
          reworkReason: this.reworkReason
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.openDialogInfo('Возвращено на доработку!', 'back');
            } else {
              this.openDialogInfo('Ошибка возврата!');
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    unpublish() {
      return new Promise(resolve => {
        const action = 'unpublish';
        const dataParams = {
          tenderId: this.tenderId,
          rejectReason: `Объявляние # ${this.tenderId} снято с публикации`
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.openDialogInfo('Возвращено на доработку!', 'back');
            } else {
              this.openDialogInfo('Ошибка возврата!');
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getUrgency(urgency) {
      switch (urgency) {
        case 'non-urgent':
          return 'Не срочно';
        case 'urgent':
          return 'Срочно';
      }
    },
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    //получаем данные по объявлению
    getTenderAdmin() {
      return new Promise(resolve => {
        const action = 'getTenderAdmin';
        const dataParams = {
          tenderId: this.$route.query.id
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.tenderInfo = resp.tender;
            this.createDate = resp.createDate;
            this.attachments = resp.attachments;
            this.attachmentsDone = resp.attachmentsDone;
            this.lots = resp.lots;
            this.comment = resp.tender.comment;
            this.proposalsCount = resp.proposals ? resp.proposals.length : 0;
            this.proposalsCountNew = resp.proposalsNew ? resp.proposalsNew.length : 0;
            this.chat = resp.chat;

            if (resp.whitelist && Object.values(resp.whitelist).length) {
              this.whiteList = resp.whitelist.map(row => row.name).join(', ');
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    publishTender() {
      if (this.tenderInfo.status !== 'ready') {
        this.openDialogInfo("Объявление не в статусе 'готов'");
      } else {
        return new Promise(resolve => {
          const action = 'publishTender';
          const dataParams = {
            tenderId: this.$route.query.id
          };
          this.$store
            .dispatch('Api', { action, dataParams })
            .then(resp => {
              if (resp.statusPublish) this.openDialogInfo('Объявление опубликовано', 'back');
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    cancelUnPublishTender() {
      if (this.tenderInfo.status !== 'cancel') {
        this.openDialogInfo("Объявление не в статусе 'отмена'");
      } else {
        return new Promise(resolve => {
          const action = 'cancelUnPublishTender';
          const dataParams = {
            tenderId: this.$route.query.id
          };
          this.$store
            .dispatch('Api', { action, dataParams })
            .then(resp => {
              if (resp.statusPublish) this.openDialogInfo('Объявление опубликовано', 'back');
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    confirmDone() {
      if (this.tenderInfo.status !== 'done') {
        this.openDialogInfo("Объявление не в статусе 'Подтвердить выполнение'");
      } else {
        return new Promise(resolve => {
          const action = 'confirmDone';
          const dataParams = {
            tenderId: this.$route.query.id
          };
          this.$store
            .dispatch('Api', { action, dataParams })
            .then(resp => {
              if (resp.result == 'success') {
                this.openDialogInfo('Выполнение подтверждено', 'getTenderAdmin');
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    cancelDone() {
      return new Promise(resolve => {
        const action = 'cancelDone';
        const dataParams = {
          tenderId: this.tenderId,
          cancelDoneReason: this.cancelDoneReason
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.openDialogInfo("Статус 'выполнено' отменен.", 'getTenderAdmin');
            } else {
              this.openDialogInfo('Ошибка возврата!');
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  mounted() {
    this.getTenderAdmin();
  }
};
</script>

<style scoped>
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
.item_title_custom {
  white-space: inherit;
}
.chat_list_item {
  max-height: 500px;
}
</style>
