<template>
  <div>
    <v-card flat v-if="!access">
      <v-card-text class="text_no_access">
        У вас нет прав для просмотра данного объявления
      </v-card-text>
    </v-card>
    <v-card v-else outlined class="px-5">
      <v-card-title class="px-0">Объявление «{{ tenderInfo.name }}»</v-card-title>
      <v-card-text class="px-0">
        <v-list two-line>
          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ tenderInfo.tenderid }}</v-list-item-title>
                  <v-list-item-subtitle>Номер объявления</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ formatCurrency(tenderInfo.startamount) }} руб.
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      tenderInfo.insurance
                        ? 'Начальная стоимость работ с учетом страхования'
                        : 'Начальная стоимость работ'
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ tenderInfo.city }}</v-list-item-title>
                  <v-list-item-subtitle>Город</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ tenderInfo.type }}</v-list-item-title>
                  <v-list-item-subtitle>Тип работ</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ formatDate(tenderInfo.startauctiondate) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Дата начала действия объявления</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ formatDate(tenderInfo.endauctiondate) }}</v-list-item-title>
                  <v-list-item-subtitle>Дата окончания действия объявления</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ formatDate(tenderInfo.startworkdate) }}</v-list-item-title>
                  <v-list-item-subtitle>Дата начала работ</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ formatDate(tenderInfo.endworkdate) }}</v-list-item-title>
                  <v-list-item-subtitle>Дата окончания работ</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ tenderInfo.orgname }}</v-list-item-title>
                  <v-list-item-subtitle>Заказчик работ</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ tenderInfo.lastname }} {{ tenderInfo.firstname }} {{ tenderInfo.secondname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Исполнитель заказчика</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ formatDate(createDate) }}</v-list-item-title>
                  <v-list-item-subtitle>Дата создания объявления</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex xs12 sm6>
                <v-list-item-content>
                  <v-list-item-title>{{ getUrgency(tenderInfo.urgency) }}</v-list-item-title>
                  <v-list-item-subtitle>Срочность исполнения</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
            </v-layout>
          </v-list-item>

          <v-list-item class="px-0">
            <v-layout wrap>
              <v-flex xs12 d-flex class="justify-end">
                <v-btn outlined color="primary" @click="generateLinkTender()">
                  Сформировать ссылку
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-layout>
            <v-flex>
              <v-textarea
                v-model="comment"
                label="Комментарий"
                outlined
                dense
                hide-details
                readonly
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-list-item class="px-0">
            <v-list-item-title>Документация:</v-list-item-title>
          </v-list-item>
          <v-list-item class="px-0" v-if="!attachments">
            <v-list-item-subtitle>Документации нет</v-list-item-subtitle>
          </v-list-item>
          <v-list-item class="px-0" v-else v-for="(attach, i) in attachments" :key="i">
            <v-layout wrap class="justify-space-between">
              <v-flex sx12 sm10 d-flex class="data_wrap align-center">
                <div>{{ attach.filename }}</div>
              </v-flex>
              <v-flex sx12 sm2 d-flex class="data_wrap align-center">
                <v-btn
                  class="btn_action"
                  outlined
                  color="primary"
                  :href="uploadUrl + attach.tenderid + '/' + attach.attachment"
                  @click="loggingAction"
                  download
                >
                  скачать
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-divider class="px-0"></v-divider>
          <v-list-item class="px-0">
            <v-list-item-title>Объекты:</v-list-item-title>
          </v-list-item>
          <v-list-item class="px-0" v-if="!lots.length">
            <v-list-item-subtitle>Объектов нет</v-list-item-subtitle>
          </v-list-item>
          <v-list dense v-else>
            <v-list-item-group color="primary">
              <template v-for="(lot, i) in lots">
                <v-list-item
                  :key="i"
                  :to="{
                    path: '/contractor/lot',
                    query: { id: lot.id }
                  }"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="lot.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <v-btn outlined color="primary" @click="back()">к списку объявлений</v-btn>
        <v-btn
          v-if="proposalId == '1' && !expired"
          class="btn_action"
          outlined
          color="primary"
          @click="checkStartDate()"
        >
          Участвовать
        </v-btn>
        <v-btn
          v-else-if="proposalId > 1"
          outlined
          color="primary"
          :to="{
            path: '/contractor/myproposal',
            query: {
              proposalId,
              tenderId,
              insurance: tenderInfo.insurance
            }
          }"
        >
          Перейти к заявке
        </v-btn>
      </v-card-actions>
      <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
    </v-card>
  </div>
</template>

<script>
import mixinDialog from '../mixin/js/mixinDialog';
import mixinTender from '../mixin/js/mixinTender';
import Lib from '../mixin/js/mixinLib';

export default {
  mixins: [mixinDialog, mixinTender, Lib],
  data() {
    return {
      access: true,
      uploadUrl: process.env.VUE_APP_UPLOADS_TENDER_URL,
      tenderId: this.$route.query.id,
      tenderInfo: '',
      attachments: [],
      proposalId: '',
      lots: [],
      comment: '',
      createDate: '',
      expired: false
    };
  },
  computed: {
    headers() {
      return this.$store.getters.getHeaders;
    }
  },
  methods: {
    loggingAction() {
      return new Promise(resolve => {
        const action = 'loggingActionDownload';
        const dataParams = {
          tenderId: this.tenderId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => resolve(resp))
          .catch(err => {
            console.log(err);
          });
      });
    },
    getUrgency(urgency) {
      switch (urgency) {
        case 'non-urgent':
          return 'Не срочно';
        case 'urgent':
          return 'Срочно';
      }
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    getTender() {
      return new Promise(resolve => {
        const action = 'getTender';
        const dataParams = {
          tenderId: this.$route.query.id
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'unresolved') this.access = false;
            this.tenderInfo = resp.tender;
            this.attachments = resp.attachments;
            this.lots = resp.lots;
            this.comment = resp.tender.comment;
            this.proposalId = resp.proposal;
            this.createDate = resp.createDate;
            this.expired = resp.expired;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    checkStartDate() {
      return new Promise(resolve => {
        const action = 'checkStartDateTender';
        const dataParams = {
          tenderId: this.tenderId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'error' && resp.error_msg == 'start_date') {
              this.openDialogInfo('Дата начала действия объявления больше текущей даты!', 'back');
              resolve(resp);
            } else {
              this.$router.push({
                path: '/contractor/addproposal',
                query: {
                  tenderName: this.tenderInfo.name,
                  tenderId: this.tenderId,
                  insurance: this.tenderInfo.insurance,
                  needdoc: this.tenderInfo.needdoc
                }
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.go(-1);
    },
    exit() {
      this.modal = false;
    },
    getTenderInfo() {
      if (this.$refs.form.validate()) {
        alert('заявка принята');
      }
    }
  },
  mounted() {
    this.getTender();
  }
};
</script>

<style scoped>
.text_no_access {
  text-align: center;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .item_wrap {
    flex-direction: column;
  }
}
</style>
