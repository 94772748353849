<template>
  <div>
    <v-data-table
      :loading="!dataTable.tenders"
      :headers="headersTableTenders"
      :sort-by="['id']"
      :sort-desc="[true]"
      :items-per-page.sync="searchData.pagination"
      :items="dataTable.tenders ? dataTable.tenders : []"
      @click:row="goTo"
      locale="ru"
    >
      <template v-slot:item.id="{ item }">
        <div class="tender_id">
          {{ item.id }}
        </div>
      </template>
      <template v-slot:item.orderid="{ item }">
        <div class="tender_orderid">
          {{ item.orderid }}
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="tender_name">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip class="chips_tender tender_status chip_text" :color="getColor(item.status)" dark>
          {{ translateTenderStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.startamount="{ item }" class="text-right">
        <div class="text-no-wrap text-right tender_startamount">
          {{ formatCurrency(item.startamount) }}
        </div>
      </template>
      <template v-slot:item.proposals="{ item }">
        <div class="tender_proposals">
          {{ item.proposals }}
          <sup v-if="item.proposalsnew" style="color: mediumseagreen">
            <b>{{ item.proposalsnew }}</b>
          </sup>
        </div>
      </template>
      <template v-slot:item.amount="{ item }" class="text-right">
        <div class="text-no-wrap text-right tender_amount">
          {{ formatCurrency(item.amount) }}
        </div>
      </template>
      <template v-slot:item.costpolicy="{ item }" class="text-right">
        <div class="text-no-wrap text-right tender_costpolicy">
          {{ formatCurrency(item.costpolicy) }}
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <div class="tender_type">
          {{ item.type }}
        </div>
      </template>
      <template v-slot:item.startauctiondate="{ item }">
        <div class="tender_startauctiondate">
          {{ formatDate(item.startauctiondate) }}
        </div>
      </template>
      <template v-slot:item.endauctiondate="{ item }">
        <div class="tender_endauctiondate">
          {{ formatDate(item.endauctiondate) }}
        </div>
      </template>
      <template v-slot:item.city="{ item }">
        <div class="tender_city">
          {{ item.city }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="tender_action">
          <v-icon
            v-if="(item.status == 'new' || item.status == 'rework') && role != 'admin'"
            color="primary"
            small
            outlined
            class="mr-2"
            @click="editItem(item.id)"
          >
            edit
          </v-icon>
          <v-icon
            v-if="!item.workingproposals && $router.currentRoute.name == 'ClosedTendersOrganizer'"
            class="mr-5"
            large=""
            outlined
            color="green"
            @click.stop="
              openDialogConfirm(
                'Присвоить объявлению статус \'доработать\'?',
                `reopenTender(${item.id})`
              )
            "
          >
            arrow_circle_up
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </div>
</template>

<script>
import Mixin from '../../mixin/js/mixinTender';
import DialogMixin from '../../mixin/js/mixinDialog';
import Lib from '../../mixin/js/mixinLib';

export default {
  mixins: [Mixin, DialogMixin, Lib],
  props: ['dataTable'],
  data() {
    return {
      role: this.$store.getters.role,
      insurance: this.$store.getters.insurance
    };
  },
  methods: {
    goTo(item) {
      this.$store.commit('setMenuType', { menuType: this.dataTable.menuType });
      let routeData = this.$router.resolve({ path: this.path, query: { id: item.id } });
      window.open(routeData.href, '_blank');
    },
    editItem(tenderId) {
      this.$router.push({
        path: '/organizer/edittender',
        query: {
          tenderId
        }
      });
    },
    reopenTender(id) {
      return new Promise(resolve => {
        const action = 'reopenClosedTender';
        const dataParams = { tenderId: id };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.refresh();
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else {
              this.openDialogInfo("Данному объявлению нельзя присвоить статус 'доработать'");
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    refresh() {
      this.$router.go();
    }
  },
  computed: {
    path() {
      const role = this.$store.getters.role;
      if (role == 'admin') {
        return '/admin/tender';
      } else if (role == 'organizer') {
        return '/organizer/tender';
      } else {
        return '/contractor/tender';
      }
    }
  },
  mounted() {
    //Если это таблица объявлений для админа - добавляем столбец с заявками
    if (this.dataTable.insertMenuItem) {
      this.headersTableTenders.splice(4, 0, {
        text: 'Заявки',
        sortable: false,
        value: 'proposals',
        align: 'center'
      });
    }
    //Если роль админ или заказчик - добавляем столбец номер заказа
    if (this.$store.getters.role !== 'contractor') {
      this.headersTableTenders.splice(1, 0, {
        text: 'Заказ',
        sortable: false,
        value: 'orderid'
      });
    }
    //Если роль подрядчик - добавляем столбцы со страхованием
    if (this.$store.getters.role == 'contractor' && this.insurance) {
      this.headersTableTenders[3].text = 'Начальная стоимость с учетом страхования';
      this.headersTableTenders[4].text = 'Стоимость работ с учетом страхования';
      this.headersTableTenders.splice(5, 0, {
        text: 'Стоимость договора страхования СМР',
        sortable: false,
        value: 'costpolicy',
        align: 'right'
      });
      this.headersTableTenders.splice(6, 0, {
        text: 'Стоимость работ без учета страхования',
        sortable: false,
        value: 'betamountinsurless',
        align: 'right'
      });
    }
  }
};
</script>

<style scoped>
.tender_id {
  width: 40px;
}
.tender_orderid {
  width: 50px;
}
.tender_name {
  width: 200px;
}
.tender_status {
  width: 112px;
}
.tender_startamount {
  width: 120px;
}
.tender_proposals {
  width: 42px;
}
.tender_amount {
  width: 120px;
}
.tender_costpolicy {
  width: 120px;
}
.tender_type {
  width: 120px;
}
.tender_startauctiondate {
  width: 75px;
}
.tender_endauctiondate {
  width: 75px;
}
.tender_city {
  width: 130px;
}
.tender_action {
  width: 60px;
}
.chip_text {
  white-space: normal !important;
  line-height: 14px !important;
  text-align: center !important;
}
</style>
