<template>
  <v-card outlined>
    <v-card-title class="pl-8">Новая заявка к объявлению «{{ tenderName }}»</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" validation>
        <v-list>
          <v-list-item>
            <v-layout wrap class="item_wrap">
              <v-flex d-flex class="data_wrap align-start mr-5">
                <div class="subtitle-1">
                  Для участия в объявлении введите сумму:
                </div>
              </v-flex>
              <v-flex d-flex class="data_wrap align-center">
                <v-text-field
                  v-model="betamount"
                  type="number"
                  step="1"
                  min="0"
                  placeholder="0"
                  @input="formatNumber"
                  :rules="rateRules"
                  required
                  dense
                  outlined
                  color="primary"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="insurance == 'true'">
            <v-layout wrap class="item_wrap">
              <v-flex d-flex class="data_wrap align-start mr-5">
                <div class="subtitle-1">
                  Стоимость страхового полиса:
                </div>
              </v-flex>
              <v-flex d-flex class="data_wrap align-center">
                <v-text-field
                  v-model="costpolicy"
                  placeholder="0,00"
                  required
                  dense
                  outlined
                  color="primary"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="insurance == 'true'">
            <v-layout wrap class="item_wrap">
              <v-flex d-flex class="data_wrap align-start mr-5">
                <div class="subtitle-1">
                  Стоимость работ без учета страхования*:
                </div>
              </v-flex>
              <v-flex d-flex class="data_wrap align-center">
                <v-text-field
                  v-model="betamountInsurLess"
                  placeholder="0,00"
                  dense
                  outlined
                  color="primary"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="insurance == 'true'">
            <div class="subtitle-2 font-italic">
              *Указана сумма, которая будет перечислена за выполненные работы
            </div>
          </v-list-item>
          <template v-if="needdoc == 'true'">
            <v-card-subtitle class="px-0">Приложения</v-card-subtitle>
            <v-card-text>
              <v-layout v-for="(item, i) in attach" :key="i" wrap class="justify-space-between">
                <v-flex xs12 sm5 class="mb-4">
                  <v-file-input
                    type="file"
                    label="Загрузить документ"
                    v-model="item.file"
                    :rules="uploadRules"
                    outlined
                    dense
                  ></v-file-input>
                </v-flex>
                <v-flex xs12 sm5 class="mb-4">
                  <v-text-field
                    :maxlength="$store.getters.fileNameMaxLength"
                    :rules="[v => !!v || 'Необходимо ввести название']"
                    type="text"
                    label="Имя файла"
                    v-model="item.name"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
                <v-btn
                  x-large
                  icon
                  color="primary"
                  class="ml-3 mt-n2"
                  @click="removeItemsAttach(i)"
                >
                  <v-icon>delete_outline</v-icon>
                </v-btn>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pl-4">
              <v-btn outlined color="primary" @click="addItemsAttach">
                <div class="title">Добавить приложение</div>
              </v-btn>
            </v-card-actions>
            <v-list-item class="mb-5">
              <v-layout>
                <v-flex>
                  <v-textarea
                    v-model="comment"
                    label="Комментарий"
                    outlined
                    dense
                    hide-details
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-list-item>
          </template>
          <template v-else>
            <v-card>
              <v-card-title>
                Соглашение о соблюдении обязательных требований
              </v-card-title>
              <v-card-text>
                Нажимая кнопку Создать, я подтверждаю, что имею необходимое оборудование,
                используемое при производстве работ и прошедшее проверку и сертификацию в
                соответствии с требованиями законодательства РФ. Сотрудники квалифицированы,
                проходят инструктаж по технике безопасности. Также я ознакомлен с
                <a
                  :href="uploadUrl + 'Пользовательское_соглашение.pdf'"
                  target="blank"
                  class="link-rules"
                >
                  правилами и условиями работы на площадке «Трест 37»
                </a>
                и согласен с ними.
              </v-card-text>
              <v-card-actions>
                <v-checkbox
                  v-model="agree"
                  label="Ознакомлен и подтверждаю"
                  :rules="[v => !!v]"
                ></v-checkbox>
              </v-card-actions>
            </v-card>
          </template>
        </v-list>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn outlined color="primary" @click="back">отмена</v-btn>
      <v-btn outlined color="primary" @click="addProposal" :disabled="!valid">Создать</v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      files: [],
      tenderName: this.$route.query.tenderName,
      tenderId: this.$route.query.tenderId,
      insurance: this.$route.query.insurance,
      needdoc: this.$route.query.needdoc,
      dialog: false,
      valid: false,
      rateRules: [
        v => !!v || 'Необходимо ввести сумму',
        v => (v && v <= this.startamount - 1) || 'Превышена максимальная сумма заявки!'
      ],
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.size < 10000000) || 'Не более 10 MB!'
      ],
      betamount: '',
      startamount: '',
      attach: [{}],
      type: '',
      comment: '',
      agree: false
    };
  },
  methods: {
    getTender() {
      return new Promise(resolve => {
        const action = 'getTender';
        const dataParams = {
          tenderId: this.tenderId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'unresolved') this.access = false;
            this.startamount = resp.tender.startamount;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    addProposal() {
      return new Promise(resolve => {
        const action = 'addProposal';
        const dataParams = {
          tenderId: this.tenderId,
          betamount: this.betamount,
          fileName: this.fileName,
          comment: this.comment,
          attach: this.attach
        };
        const attach = this.attach;
        this.$store
          .dispatch('SendForm', { action, dataParams, attach })
          .then(resp => {
            if (resp.result == 'error' && resp.error_msg == 'unresolved') {
              this.openDialogInfo('У вас нет прав подачи заявки к данному объявлению', 'back');
            } else if (resp.result == 'error' && resp.error_msg == 'time') {
              this.openDialogInfo('Время подачи заявки истекло!', 'back');
            } else if (resp.result == 'error' && resp.error_msg == 'start_date') {
              this.openDialogInfo('Дата начала действия объявления больше текущей даты!', 'back');
            }

            if (resp.id) this.openDialogInfo('Ваша заявка принята!', 'back');

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.go(-1);
    },
    addItemsAttach() {
      if (this.attach.length < 5) {
        this.attach.push({});
      }
    },
    removeItemsAttach(i) {
      this.attach = [...this.attach.slice(0, i), ...this.attach.slice(i + 1)];
      if (!this.attach.length) {
        this.attach = [{}];
      }
    },
    formatNumber() {
      this.betamount = this.betamount ? +this.betamount.replace(/[^0-9]/g, '') : this.betamount;
    }
  },
  computed: {
    costpolicy() {
      return (this.betamount * 20) / 100;
    },
    betamountInsurLess() {
      return (this.betamount * 80) / 100;
    }
  }, 
  mounted() {
    this.getTender();
  }
};
</script>

<style scoped>
.date-picker {
  height: 48px;
}

.link-rules {
  color: blue;
}

.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}

.data_wrap {
  max-width: max-content;
}
</style>
