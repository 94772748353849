<template>
  <v-layout wrap class="justify-start">
    <v-flex xs12 sm4 class=" wrap_column">
      <v-layout column class="px-5">
        <v-flex class="text_field">
          <v-text-field
            v-model="searchData.name"
            append-icon="search"
            label="Название объявления"
            single-line
            hide-details
            clearable
          ></v-text-field
        ></v-flex>
        <v-flex class="text_field">
          <v-select
            :items="statusproposal"
            item-value="type"
            item-text="name"
            label="Статус заявки"
            v-model="searchData.status"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm4 class=" wrap_column">
      <v-layout column class="px-5">
        <v-flex class="text_field">
          <v-text-field
            v-model="searchData.betamount"
            hide-details
            clearable
            type="number"
            label="Стоимость работ"
          ></v-text-field>
          <v-slider
            v-model="searchData.betamount"
            class="align-center slider_wrap mt-8"
            :max="max"
            :min="min"
            hide-details
          >
          </v-slider>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Mixin from "../../mixin/js/mixinProposal";
export default {
  mixins: [Mixin],
  data() {
    return {
      min: 0,
      max: 200000,
    };
  },
  watch: {
    //ставим глубокий обработчик для объекта
    searchData: {
      handler(v) {
        this.$emit("data", v);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.wrap_column {
  max-width: 280px !important;
}
.v-slider--horizontal {
  margin: 0 !important;
}
.slider_field {
  width: 100% !important;
}
.slider_wrap {
  flex-wrap: wrap;
}
.text_field {
  max-width: 240px !important;
}
.date-picker {
  height: 48px;
}
.subheader_sum {
  height: auto;
  margin-top: 10px;
}
</style>
