<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0">
      Заявка по объявлению «{{ proposalData.name }}»
    </v-card-title>
    <v-card-text class="px-0">
      <v-list two-line>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{ proposalData.orgname }}</v-list-item-title>
            <v-list-item-subtitle>Заказчик работ</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title
              >г. {{ proposalData.cityname }}</v-list-item-title
            >
            <v-list-item-subtitle>Адрес</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{
              formatCurrency(proposalData.betamount)
            }}</v-list-item-title>
            <v-list-item-subtitle>Стоимость работ</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0" v-if="proposalData.insurance">
          <v-list-item-content>
            <v-list-item-title>{{
              formatCurrency((proposalData.betamount * 20) / 100)
            }}</v-list-item-title>
            <v-list-item-subtitle>
              Стоимость страхового полиса</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0" v-if="proposalData.insurance">
          <v-list-item-content>
            <v-list-item-title>{{
              formatCurrency((proposalData.betamount * 80) / 100)
            }}</v-list-item-title>
            <v-list-item-subtitle
              >Стоимость работ без учета страхования*</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-flex v-if="proposalData.insurance">
          <div class="subtitle-2 font-italic">
            *Указана сумма, которая будет перечислена за выполненные работы
          </div>
        </v-flex>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title
              ><v-chip
                class="chips_proposal"
                :color="getColor(proposalData.status)"
                dark
                >{{ translateProposalStatus(proposalData.status) }}</v-chip
              >
            </v-list-item-title>
            <v-list-item-subtitle>Статус</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="
                !expired &&
                  (proposalData.status == 'new' ||
                    proposalData.status == 'work')
              "
              class="btn_action"
              outlined
              color="primary"
              :to="{
                path: '/contractor/editproposal',
                query: {
                  proposalId,
                },
              }"
              >изменить</v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="proposalData.rejectreason" class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{
              proposalData.rejectreason
            }}</v-list-item-title>
            <v-list-item-subtitle>Причина отказа</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content v-if="proposalData.comment">
            <v-list-item-title>{{ proposalData.comment }}</v-list-item-title>
            <v-list-item-subtitle>Комментарий</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-subtitle>Комментария нет</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item class="px-0">
          <v-list-item-title>Документация:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-if="!attachments">
          <v-list-item-subtitle>Документации нет</v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          class="px-0"
          v-else
          v-for="(attach, i) in attachments"
          :key="i"
        >
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="data_wrap align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="data_wrap align-center">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="uploadUrl + attach.proposalid + '/' + attach.attachment"
                download
                >скачать</v-btn
              >
            </v-flex>
          </v-layout>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        :to="{
          path: '/contractor/myproposalslist',
        }"
        >вернуться к списку заявок</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import Mixin from "../mixin/js/mixinProposal";
import Lib from "../mixin/js/mixinLib";

export default {
  mixins: [Mixin, Lib],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_PROPOSAL_URL,
      proposalId: this.$route.query.proposalId,
      tenderId: this.$route.query.tenderId,
      type: this.$route.query.type,
      insurance: this.$route.query.insurance,
      proposalData: "",
      attachments: [],
      expired: false,
    };
  },
  methods: {
    getUserProposal() {
      return new Promise((resolve) => {
        const action = "getUserProposal";
        const dataParams = {
          proposalId: this.proposalId,
          tenderId: this.tenderId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.proposalData = resp.proposal;
              this.attachments = resp.attachments;
              this.expired = resp.expired;
            } else if (resp.result == "error") {
              this.$router.go(-1);
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    exit() {
      this.modal = false;
    },
  },
  mounted() {
    this.getUserProposal();
  },
};
</script>

<style scoped>
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
</style>
