<template>
  <v-data-table
    :headers="headersTableMyProposals"
    :items="dataTable.proposals"
    :items-per-page.sync="searchData.pagination"
    @click:row="goTo"
    locale="ru"
  >
    <template v-slot:item.betamount="{ item }">
      {{ formatCurrency(item.betamount) }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip class="chips_proposal" :color="getColor(item.status)" dark>{{
        statusproposal.filter((i) => {
          return i.type == item.status;
        })[0].name
      }}</v-chip>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        v-if="item.status == 'new'"
        color="primary"
        small
        outlined
        class="mr-2"
        @click="editItem(item.proposalid)"
      >
        edit
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import Mixin from "../../mixin/js/mixinProposal";
import Lib from "../../mixin/js/mixinLib";

export default {
  mixins: [Mixin, Lib],
  props: ["dataTable"],
  data() {
    return {
      // headers: this.$store.getters.headersTableMyProposals
    };
  },
  computed: {
    path() {
      const role = this.$store.getters.role;
      if (role == "admin") {
        return "/admin/ProposalAdmin";
      } else if (role == "organizer") {
        return "/organizer/proposal";
      } else {
        return "/contractor/myproposal";
      }
    },
  },
  methods: {
    goTo(item) {
      this.$router.push({
        path: this.path,
        query: {
          proposalId: item.proposalid,
          type: item.type,
        },
      });
    },
    editItem(proposalId) {
      this.$router.push({
        path: "/contractor/editproposal",
        query: {
          proposalId,
        },
      });
    },
  },
};
</script>

<style scoped></style>>
