<template>
  <v-card outlined>
    <v-card-text>
      <v-layout wrap class="justify-start">
        <v-flex xs12 class="text-end mt-4">
          <v-btn outlined color="primary">
            <DownloadExcel
              :data="filtredTenders"
              :fields="json_headers"
              worksheet="My Worksheet"
              name="report.xls"
              >Сохранить</DownloadExcel
            ></v-btn
          >
        </v-flex>
        <v-flex xs12 class="date-picker text_field">
          <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                readonly
                v-model="start_date"
                label="Дата начала объявления"
                prepend-inner-icon="event"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.start_dateISO"
              locale="rus"
              no-title
              @input="menuStart = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12 class="date-picker text_field">
          <v-menu
            v-model="menuEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                readonly
                v-model="end_date"
                label="Дата окончания объявления"
                prepend-inner-icon="event"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.end_dateISO"
              locale="rus"
              no-title
              @input="menuEnd = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-flex class="mt-10">
        <v-data-table
          :headers="headers"
          :items="filtredTenders"
          :items-per-page.sync="searchData.pagination"
          locale="ru"
        >
        </v-data-table>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      start_dateISO: "",
      end_dateISO: "",
      searchData: this.$store.getters[`${this.$router.currentRoute.name}`],
      tenders: [],
      worktypes: [],
      json_headers: {
        "№ п/п": "id",
        "Номер объявления": "tenderid",
        "Дата окончания действия": "endauctiondate",
        "Тип работ": "type",
        "Наименование ПО": "orgname",
        Цена: "amount",
      },
      headers: [
        {
          text: "№ п/п",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Номер объявления",
          align: "left",
          value: "tenderid",
          sortable: false,
        },
        {
          text: "Дата окончания действия",
          align: "left",
          value: "endauctiondate",
          sortable: false,
        },
        { text: "Тип работ", align: "left", value: "type", sortable: false },
        { text: "Наименование ПО", align: "left", value: "orgname" },
        { text: "Цена", align: "left", value: "amount", sortable: false },
      ],
      menuStart: false,
      menuEnd: false,
    };
  },
  methods: {
    getWinTendersReport() {
      return new Promise((resolve) => {
        const action = "getWinTendersReport";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.tenders = resp.tenders;
            this.tenders.map((item) => {
              item.endauctiondate = this.formatDate(item.endauctiondate);
            });
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getWorkTypes() {
      return new Promise((resolve) => {
        const action = "getWorkTypes";
        const dataParams = {};
        this.$store
          .dispatch("login", { action, dataParams })
          .then((resp) => {
            this.worktypes = [{ id: 0, type: "все" }, ...resp.worktypes];
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    parseDateToUnix(string) {
      //Преобразовываем дату unix для сравнения в фильтрах
      if (!string) return null;
      string = string.slice(0, 10);
      const [day, month, year] = string.split(".");
      return Date.parse(`${year}-${month}-${day}`);
    },
    formatDate(data) {
      if (!data) return null;
      const date = data.slice(0, 10);
      // const time = data.slice(11, 19);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
  },
  computed: {
    start_date: {
      get() {
        return this.formatDate(this.searchData.start_dateISO);
      },
      set(v) {
        this.searchData.start_dateISO = v;
        return v;
      },
    },
    end_date: {
      get() {
        return this.formatDate(this.searchData.end_dateISO);
      },
      set(v) {
        this.searchData.end_dateISO = v;
        return v;
      },
    },
    filtredTenders: {
      get() {
        return (
          this.tenders
            // Фильтр по дате
            .filter((i) => {
              return (
                (this.searchData.start_dateISO === null &&
                  this.searchData.end_dateISO === null) ||
                (this.parseDateToUnix(i.startauctiondate) >=
                  this.parseDateToUnix(this.searchData.start_dateISO) &&
                  this.searchData.end_dateISO === null) ||
                (this.searchData.start_dateISO === null &&
                  this.parseDateToUnix(i.endauctiondate) <=
                    this.parseDateToUnix(this.searchData.end_dateISO)) ||
                (this.parseDateToUnix(i.startauctiondate) >=
                  this.parseDateToUnix(this.searchData.start_dateISO) &&
                  this.parseDateToUnix(i.endauctiondate) <=
                    this.parseDateToUnix(this.searchData.end_dateISO))
              );
            })
        );
      },
      set(v) {
        return v;
      },
    },
  },

  mounted() {
    this.getWinTendersReport();
    this.getWorkTypes();
  },
};
</script>
<style scoped>
.wrap_column {
  max-width: 280px !important;
}
.v-slider--horizontal {
  margin: 0 !important;
}
.slider_field {
  width: 100% !important;
}
.slider_wrap {
  flex-wrap: wrap;
}
.text_field {
  max-width: 300px !important;
  margin-right: 15px;
}
.date-picker {
  height: 48px;
}
.subheader_sum {
  height: auto;
  margin-top: 10px;
}
</style>
