<template>
  <v-card outlined>
    <v-card-title class="pl-8">Новое объявление</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" validation>
        <v-list>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12>
                <v-text-field
                  :maxlength="$store.getters.titleAdMaxLength"
                  v-model="name"
                  type="text"
                  label="Название вида работ"
                  :rules="[v => !!v || 'Необходимо ввести название вида работ']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  :label="getTitle"
                  v-model="startAmount"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder="0,00"
                  :rules="[v => !!v || 'Необходимо ввести сумму']"
                  required
                  dense
                  outlined
                  color="primary"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-select
                  v-model="city"
                  :items="citiesList"
                  item-value="id"
                  item-text="name"
                  :rules="[v => !!v || 'Необходимо ввести город']"
                  label="Город"
                  required
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="volume"
                  type="text"
                  label="Объем работ"
                  :rules="[v => !!v || 'Необходимо ввести объем работ']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-select
                  v-model="type"
                  :items="worktypes"
                  item-value="id"
                  item-text="type"
                  label="Тип работ"
                  :rules="[v => !!v || 'Выберите тип работ']"
                  clearable
                  required
                  outlined
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="orderid"
                  type="text"
                  label="Номер заказа на работы"
                  :rules="[v => !!v || 'Необходимо ввести номер заказа']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-select
                  v-model="urgencyId"
                  :items="urgencyList"
                  item-value="id"
                  item-text="name"
                  label="Признак срочности"
                  :rules="[v => !!v || 'Выберите признак срочности']"
                  clearable
                  required
                  outlined
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-menu
                  v-model="menuStartTender"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="filter.start_TenderDate"
                      label="Начало объявления"
                      :rules="startTenderDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="start_TenderDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_TenderDateISO"
                    no-title
                    locale="rus"
                    @input="menuStartTender = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm5>
                <v-menu
                  v-model="menuEndTender"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="filter.end_TenderDate"
                      label="Окончание объявления"
                      :rules="endTenderDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="end_TenderDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_TenderDateISO"
                    no-title
                    locale="rus"
                    @input="menuEndTender = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5 class="">
                <v-menu
                  v-model="menuStartWork"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="filter.start_WorkDate"
                      label="Начало работ"
                      :rules="startWorkDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="start_WorkDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_WorkDateISO"
                    no-title
                    locale="rus"
                    @input="menuStartWork = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm5 class="">
                <v-menu
                  v-model="menuEndWork"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="filter.end_WorkDate"
                      label="Окончание работ"
                      :rules="endWorkDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="end_WorkDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_WorkDateISO"
                    no-title
                    locale="rus"
                    @input="menuEndWork = false"
                    :min="minDate"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Приложения</v-card-subtitle>
              <v-card-text>
                <v-layout v-for="(item, i) in attach" :key="i" wrap class="justify-space-between">
                  <v-flex xs12 sm5 class="mb-4">
                    <v-file-input
                      type="file"
                      outlined
                      label="Загрузить документ"
                      v-model="item.file"
                      :rules="uploadRules"
                      dense
                    ></v-file-input>
                  </v-flex>
                  <v-flex d-flex xs12 sm5 class="mb-4">
                    <v-text-field
                      :maxlength="$store.getters.fileNameMaxLength"
                      v-model="item.name"
                      type="text"
                      label="Имя файла"
                      :rules="[v => !!v || 'Необходимо ввести имя файла']"
                      outlined
                      dense
                    ></v-text-field>
                    <v-btn x-large icon color="primary" class="ml-3 mt-n2" @click="removeAttach(i)">
                      <v-icon>delete_outline</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-start pl-4">
                <v-btn outlined color="primary" @click="addAttach">
                  <div>Добавить приложение</div>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item>
          <v-list-item class="mb-5">
            <v-layout>
              <v-flex>
                <v-textarea
                  v-model="comment"
                  label="Комментарий"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-around">
      <v-btn outlined color="primary" to="/organizer/newtenders">отмена</v-btn>
      <v-btn
        outlined
        color="primary"
        @click="$refs.form.validate() && openDialogConfirm('Сохранить объявление?', 'addTender')"
        :disabled="!valid"
      >
        Сохранить
      </v-btn>
      <v-btn outlined color="primary" @click="$refs.form.validate() && addLot()" :disabled="!valid">
        Добавить объект
      </v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      urgencyId: '',
      urgencyList: [],
      orderid: '',
      startAmount: '',
      name: '',
      city: '',
      citiesList: [],
      type: '',
      worktypes: [],
      permissions: [],
      volume: '',
      comment: '',
      start_TenderDateISO: null,
      end_TenderDateISO: null,
      start_WorkDateISO: null,
      end_WorkDateISO: null,
      attach: [{}],
      filter: {
        status: 'Все',
        start_TenderDate: '',
        end_TenderDate: '',
        start_WorkDate: '',
        end_WorkDate: ''
      },
      date: '',
      menuStartTender: false,
      menuEndTender: false,
      menuStartWork: false,
      menuEndWork: false,
      valid: false,
      startTenderDateRules: [v => !!v || 'Выберите дату'],
      endTenderDateRules: [
        v => !!v || 'Выберите дату',
        () => this.valid_EndTenderDate || 'Выбранная дата меньше либо равна дате начала объявления'
      ],
      startWorkDateRules: [
        v => !!v || 'Выберите дату',
        () =>
          this.valid_StartWorkDate || 'Выбранная дата меньше или равна дате окончания объявления'
      ],
      endWorkDateRules: [
        v => !!v || 'Выберите дату',
        () => this.valid_EndWorkDate || 'Выбранная дата меньше или равна дате начала работ'
      ],
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.size < 10000000) || 'Не более 10 MB!'
      ],
      newTenderId: ''
    };
  },
  methods: {
    addTender() {
      return new Promise(resolve => {
        const action = 'addTender';
        const dataParams = {
          name: this.name,
          cityId: this.city,
          startAmount: this.startAmount,
          worktypeId: this.type,
          urgencyId: this.urgencyId,
          volume: this.volume,
          orderid: this.orderid,
          startauctiondate: this.start_TenderDateISO,
          endauctiondate: this.end_TenderDateISO,
          startworkdate: this.start_WorkDateISO,
          endworkdate: this.end_WorkDateISO,
          comment: this.comment,
          attach: this.attach
        };
        const attach = this.attach;
        this.$store
          .dispatch('SendForm', { action, dataParams, attach })
          .then(resp => {
            if (resp.id) {
              this.newTenderId = resp.id;
              this.back();
            } else if (resp.result === 'error' && resp.error_msg === 'error_dates') {
              this.openDialogInfo('Указаны некорректные даты в объявлении.');
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    addLot() {
      return new Promise(resolve => {
        this.addTender().then(resp => {
          if (resp.id) {
            this.$router.push({
              path: '/organizer/addlot',
              query: { tenderId: resp.id }
            });
          }
          resolve(resp);
        });
      });
    },
    getCitiesList() {
      return new Promise(resolve => {
        const action = 'getCitiesList';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.citiesList = resp.citiesList;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getWorkTypes() {
      return new Promise(resolve => {
        const action = 'getWorkTypes';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.worktypes = resp.worktypes;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getUrgencyList() {
      return new Promise(resolve => {
        const action = 'getUrgencyList';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.urgencyList = resp.urgencyList;
            this.urgencyList.forEach(i => {
              i.name = this.getUrgency(i.name);
            });
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getPermissions() {
      return new Promise(resolve => {
        const action = 'getPermissions';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.permissions = resp.data;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getUrgency(urgency) {
      switch (urgency) {
        case 'non-urgent':
          return 'Не срочно';
        case 'urgent':
          return 'Срочно';
      }
    },
    back() {
      this.$router.push({
        path: '/organizer/tender',
        query: { id: this.newTenderId }
      });
    },
    okPublish() {
      this.dialogPublish = false;
      this.$router.go(-1);
    },
    addAttach() {
      if (this.attach.length < 5) {
        this.attach.push({});
      }
    },
    removeAttach(i) {
      if (this.attach.length > 1) {
        this.attach = [...this.attach.slice(0, i), ...this.attach.slice(i + 1)];
      }
    },
    removeLot(i) {
      if (this.lots.length > 1) {
        this.lots = [...this.lots.slice(0, i), ...this.lots.slice(i + 1)];
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    parseDateToUnix(string) {
      if (!string) return null;
      const [day, month, year] = string.split('.');
      return Date.parse(`${year}-${month}-${day}`);
    }
  },
  computed: {
    getTitle() {
      const insurance = this.permissions.find(
        item => item.cityid === this.city && item.worktypeid === this.type
      );
      if (typeof insurance == 'object' && insurance.insurance) {
        return 'Начальная стоимость работ с учетом страхования';
      } else {
        return 'Начальная стоимость работ';
      }
    },
    minDate() {
      // Устанавливаем минимальную дату на текущую дату
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    valid_EndTenderDate() {
      return this.end_TenderDateISO > this.start_TenderDateISO;
    },
    valid_StartWorkDate() {
      return this.start_WorkDateISO > this.end_TenderDateISO;
    },
    valid_EndWorkDate() {
      return this.end_WorkDateISO > this.start_WorkDateISO;
    }
  },
  watch: {
    start_TenderDateISO() {
      this.filter.start_TenderDate = this.formatDate(this.start_TenderDateISO);
    },
    end_TenderDateISO() {
      this.filter.end_TenderDate = this.formatDate(this.end_TenderDateISO);
    },
    start_WorkDateISO() {
      this.filter.start_WorkDate = this.formatDate(this.start_WorkDateISO);
    },
    end_WorkDateISO() {
      this.filter.end_WorkDate = this.formatDate(this.end_WorkDateISO);
    }
  },
  mounted() {
    this.getCitiesList();
    this.getWorkTypes();
    this.getUrgencyList();
    this.getPermissions();
  }
};
</script>

<style scoped>
.date-picker {
  height: 48px;
}
.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}
.data_wrap {
  max-width: max-content;
}
</style>
