<template>
  <v-card outlined>
    <v-card-title>
      <v-layout column class="px-5">
        <v-flex class="align-self-end mb-5"
          ><v-btn
            outlined
            color="primary"
            :to="{
              path: '/admin/tender',
              query: { id: tenderId },
            }"
            >Назад к объявлению</v-btn
          ></v-flex
        >
        <v-flex class="align-self-center mb-5"
          >Список заявок к объявлению «{{ tenderName }}»</v-flex
        >
        <v-flex>
          <v-layout wrap>
            <v-flex xs12 sm2 class="align-self-start">
              <p class="font-weight-medium">Город:</p>
            </v-flex>
            <v-flex xs12 sm10 class="align-self-start">
              <p class="font-weight-regular">
                {{ city }}
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <SearchProposals @data="searchData = $event"></SearchProposals>
        </v-flex>
        <v-flex class="mt-10">
          <TableProposals :dataTable="dataTable"></TableProposals>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinProposal";
import Lib from "../mixin/js/mixinLib";

export default {
  mixins: [Mixin, Lib],
  data() {
    return {
      tenderId: this.$route.query.tenderId,
      tenderName: "",
      city: "",
      street: "",
      homenumber: "",
    };
  },
  methods: {
    getListProposals() {
      return new Promise((resolve) => {
        const action = "getListProposals";
        const dataParams = {
          tenderId: this.tenderId,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.proposals = resp.proposals;
            this.filteredItems = resp.proposals;
            // (this.tenderName = resp.proposals[0].name),
            this.tenderName = resp.tendername;
            this.city = resp.proposals[0].city;
            this.street = resp.proposals[0].street;
            this.homenumber = resp.proposals[0].homenumber;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  computed: {
    dataTable() {
      return {
        tenderId: this.tenderId,
        proposals: this.filteredItems,
      };
    },
  },
  mounted() {
    this.getListProposals();
  },
};
</script>

<style scoped></style>
