import { render, staticRenderFns } from "./SearchMyProposals.vue?vue&type=template&id=5748b19a&scoped=true&"
import script from "./SearchMyProposals.vue?vue&type=script&lang=js&"
export * from "./SearchMyProposals.vue?vue&type=script&lang=js&"
import style0 from "./SearchMyProposals.vue?vue&type=style&index=0&id=5748b19a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5748b19a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VFlex,VLayout,VSelect,VSlider,VTextField})
