<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0">Объявление «{{ tenderInfo.name }}»</v-card-title>
    <v-card-text class="pa-0">
      <v-list two-line>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.tenderid }}</v-list-item-title>
                <v-list-item-subtitle>Номер объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>
                  {{ formatCurrency(tenderInfo.startamount) }} руб.
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    tenderInfo.insurance
                      ? 'Начальная стоимость работ с учетом страхования'
                      : 'Начальная стоимость работ'
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>г. {{ tenderInfo.city }}</v-list-item-title>
                <v-list-item-subtitle>Полный адрес</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.type }}</v-list-item-title>
                <v-list-item-subtitle>Тип работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.startauctiondate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата начала действия объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.endauctiondate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата окончания действия объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.startworkdate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата начала работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(tenderInfo.endworkdate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата окончания работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.orgname }}</v-list-item-title>
                <v-list-item-subtitle>Заказчик работ</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>
                  {{ tenderInfo.lastname }} {{ tenderInfo.firstname }} {{ tenderInfo.secondname }}
                </v-list-item-title>
                <v-list-item-subtitle>Исполнитель заказчика</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(createDate) }}</v-list-item-title>
                <v-list-item-subtitle>Дата создания объявления</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ getUrgency(tenderInfo.urgency) }}</v-list-item-title>
                <v-list-item-subtitle>Срочность исполнения</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-layout wrap>
            <v-flex v-show="restrict" xs12 sm6>
              <v-list-item-content>
                <v-list-item-title class="item_title_custom">
                  {{ whiteList }}
                </v-list-item-title>
                <v-list-item-subtitle>Видимость для подрядчиков</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
            <v-flex xs12 sm6>
              <v-list-item-content>
                <v-list-item-title>{{ tenderInfo.orderid }}</v-list-item-title>
                <v-list-item-subtitle>Номер заказа на работы</v-list-item-subtitle>
              </v-list-item-content>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-layout wrap>
              <v-flex xs12 sm2 class="mb-4 mr-5">
                <v-list-item-title>{{ proposalsCount }}</v-list-item-title>
                <v-list-item-subtitle>Всего заявок</v-list-item-subtitle>
              </v-flex>
              <v-flex xs12 sm2 class="mb-4 mr-5">
                <v-list-item-title class="new_proposal">{{ proposalsCountNew }}</v-list-item-title>
                <v-list-item-subtitle>Новых заявок</v-list-item-subtitle>
              </v-flex>
              <v-flex xs12 sm2>
                <v-btn
                  class="btn_action"
                  outlined
                  color="primary"
                  :to="{
                    path: '/organizer/proposalsList',
                    query: { tenderId }
                  }"
                >
                  Заявки
                </v-btn>
              </v-flex>
              <v-flex sm12 md2>
                <v-btn
                  v-if="this.tenderInfo.status == 'publish'"
                  outlined
                  color="primary"
                  @click="generateLinkTender()"
                >
                  Сформировать ссылку
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
        <v-layout>
          <v-flex>
            <v-textarea
              v-model="comment"
              label="Комментарий"
              outlined
              dense
              hide-details
              readonly
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-list-item v-if="reworkReason" class="px-0">
          <v-layout wrap>
            <v-flex xs12>
              <v-card elevation="0">
                <v-card-title>Причина возврата на доработку</v-card-title>
                <v-card-text>
                  {{ reworkReason }}
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item class="px-0 my-4 chat_list_item">
          <v-expansion-panels tile>
            <v-expansion-panel>
              <v-expansion-panel-header>
                История
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-card-subtitle v-show="!chat.length">
                        Сообщений нет
                      </v-card-subtitle>
                      <v-card-text class="px-0">
                        <v-list two-line max-height="400" class="overflow-y-auto">
                          <v-list-item v-for="(item, i) in chat" :key="i">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  `${item.datetime} - ${item.lastname} ${item.firstname} ${
                                    item.secondname
                                  } (${translateTenderRole(item.role)})`
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ item.action }}
                              </v-list-item-subtitle>
                              <v-sheet class="pa-4" color="grey lighten-3">
                                <v-flex>
                                  {{ item.message }}
                                </v-flex>
                                <v-flex class="mt-4">
                                  <v-layout column>
                                    <v-flex v-for="(attach, i) in item.attachments" :key="i">
                                      <v-btn
                                        class="pa-0"
                                        x-small
                                        plain
                                        color="primary"
                                        :href="
                                          uploadUrl +
                                            tenderInfo.tenderid +
                                            '/done/' +
                                            attach.attachment
                                        "
                                        download
                                      >
                                        {{ attach.filename }}
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-sheet>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-title>Документация:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-if="!attachments.length && !attachmentsDone.length">
          <v-list-item-subtitle>Документации нет</v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="px-0" v-else v-for="(attach, i) in attachments" :key="i">
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="justify-end">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="uploadUrl + attach.tenderid + '/' + attach.attachment"
                download
              >
                скачать
              </v-btn>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-list-item class="px-0" v-for="(attach, i) in attachmentsDone" :key="'done' + i">
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="justify-end">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="uploadUrl + attach.tenderid + '/done/' + attach.attachment"
                download
              >
                скачать
              </v-btn>
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item class="px-0">
          <v-list-item-title>Объекты:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-if="!lots.length">
          <v-list-item-subtitle>Объектов нет</v-list-item-subtitle>
        </v-list-item>
        <v-list dense v-else>
          <template v-for="(lot, i) in lots">
            <v-layout :key="i" wrap class="justify-space-between">
              <v-flex sx12 sm10 d-flex class="align-center">
                <v-list-item
                  :to="{
                    path: '/organizer/lotorganizer',
                    query: { id: lot.id }
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="lot.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
              <v-flex
                v-if="tenderInfo.status == 'new' || tenderInfo.status == 'rework'"
                sx12
                sm2
                d-flex
                class="align-center justify-end"
              >
                <v-btn
                  class="btn_action"
                  outlined
                  color="primary"
                  @click="confirmDeleteLot(lot.id)"
                >
                  <div>Удалить</div>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-list>
        <v-card-actions class="pl-0">
          <v-btn
            v-if="tenderInfo.status == 'new' || tenderInfo.status == 'rework'"
            outlined
            color="primary"
            :to="{
              path: '/organizer/addlot',
              query: { tenderId }
            }"
          >
            <div>Добавить объект</div>
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-list-item v-if="tenderInfo.status === 'publish'" class="px-0">
          <v-layout column>
            <v-flex xs12 class="my-6">
              <v-list-item-title>Снять с публикации</v-list-item-title>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap class="justify-space-between">
                <v-flex xs12 sm7 d-flex class="align-center mr-2">
                  <v-textarea
                    v-model="unPublishReason"
                    outlined
                    label="Причина снятия с публикации"
                    rows="2"
                    clearable
                    dense
                    no-resize
                    :rules="[v => !!v || 'Необходимо ввести причину']"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm5 d-flex class="data_wrap mt-3">
                  <v-btn
                    :disabled="!unPublishReason"
                    outlined
                    color="primary"
                    @click="openDialogConfirm('Снять с публикации объявление?', 'unPublishTender')"
                  >
                    Снять с публикации
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex><v-divider /></v-flex>
          </v-layout>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-layout xs12 wrap class="justify-space-between mb-4">
      <v-flex xs12 sm12 md3 class="mt-4 text-center text-sm-center text-md-start">
        <v-btn outlined color="primary" :to="'/organizer/' + menuType">к списку объявлений</v-btn>
      </v-flex>
      <v-flex
        v-if="tenderInfo.status == 'new' || tenderInfo.status == 'rework'"
        xs12
        sm12
        md2
        class="mt-4 text-center"
      >
        <v-btn
          outlined
          color="primary"
          :to="{
            path: '/organizer/edittender',
            query: {
              tenderId
            }
          }"
        >
          Редактировать
        </v-btn>
      </v-flex>
      <v-flex
        v-if="(tenderInfo.status == 'new' || tenderInfo.status == 'rework') && lots.length"
        xs12
        sm12
        md2
        class="mt-4 text-center"
      >
        <v-btn
          outlined
          color="primary"
          @click="openDialogConfirm('Опубликовать объявление?', 'readyTender')"
        >
          Опубликовать
        </v-btn>
      </v-flex>
      <v-flex
        v-if="tenderInfo.status == 'new' || tenderInfo.status == 'rework'"
        v-show="restrict"
        xs12
        sm12
        md2
        class="mt-4 text-center"
      >
        <v-btn
          outlined
          color="primary"
          :to="{
            path: '/organizer/whiteList',
            query: { tenderId }
          }"
        >
          Белый список
        </v-btn>
      </v-flex>
      <v-flex
        v-if="
          (tenderInfo.status == 'new' ||
            tenderInfo.status == 'rework' ||
            tenderInfo.status == 'ready') &&
            lots.length
        "
        xs12
        sm12
        md2
        class="mt-4 text-center text-md-end text-sm-center"
      >
        <v-btn
          outlined
          color="primary"
          class="btn_action"
          @click="openDialogConfirm('Закрыть объявление?', 'closeTender')"
        >
          Закрыть
        </v-btn>
      </v-flex>
      <v-flex v-if="tenderInfo.status == 'await'" xs12 sm12 md2 class="mt-4 text-center">
        <v-btn @click="dialogIncompleteDataIn.modal = true" outlined color="primary">
          Отменить
        </v-btn>
      </v-flex>
      <v-flex
        v-if="tenderInfo.status == 'await'"
        xs12
        sm12
        md4
        class="mt-4 text-center text-md-end"
      >
        <v-btn @click="dialogDoneDataIn.modal = true" outlined color="primary">
          Подтвердить выполнение
        </v-btn>
      </v-flex>
    </v-layout>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
    <DialogIncomplete
      :dialogDataIn="dialogIncompleteDataIn"
      @dialogDataOut="(dialogIncompleteDataOut = $event), setIncompleteTender()"
    ></DialogIncomplete>
    <DialogDone
      :dialogDataIn="dialogDoneDataIn"
      @dialogDataOut="(dialogDoneDataOut = $event), setDoneTender()"
      @click:outside="dialogDoneDataIn.modal = false"
    ></DialogDone>
  </v-card>
</template>

<script>
import DialogIncomplete from './DialogIncomplete.vue';
import DialogDone from './DialogDone.vue';
import mixinDialog from '../mixin/js/mixinDialog';
import mixinTender from '../mixin/js/mixinTender';
import Lib from '../mixin/js/mixinLib';

export default {
  components: { DialogIncomplete, DialogDone },
  mixins: [mixinDialog, mixinTender, Lib],
  data() {
    return {
      reworkReason: '',
      unPublishReason: '',
      lotIdForDelete: '',
      uploadUrl: process.env.VUE_APP_UPLOADS_TENDER_URL,
      tenderId: this.$route.query.id,
      menuType: this.$store.getters.menuType,
      tenderInfo: '',
      attachments: [],
      attachmentsDone: [],
      lots: [],
      proposalsCount: '0',
      proposalsCountNew: '0',
      createDate: '',
      comment: '',
      restrict: this.$store.getters.restrict,
      whiteList: 'Виден всем',
      dialogIncompleteDataIn: { modal: false },
      dialogIncompleteDataOut: { comment: '', execute: false },
      dialogDoneDataIn: { modal: false, amount: 0, totalCost: 0 },
      dialogDoneDataOut: {
        comment: '',
        files: [],
        overRunCostFiles: [],
        totalCost: '',
        validForm: false,
        validFormOverRunCost: false,
        execute: false,
        isOverRun: false
      },
      chat: []
    };
  },
  computed: {
    headers() {
      return this.$store.getters.getHeaders;
    }
  },
  methods: {
    setIncompleteTender() {
      if (!this.dialogIncompleteDataOut.execute) return;
      return new Promise(resolve => {
        const action = 'setIncompleteTender';
        const dataParams = {
          tenderId: this.$route.query.id,
          comment: this.dialogIncompleteDataOut.comment
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'success') {
              this.openDialogInfo('Объявление переведено в статус Не выполнено', 'back');
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    setDoneTender() {
      if (!this.dialogDoneDataOut.execute) return;
      return new Promise(resolve => {
        const attach = [];

        Object.values(this.dialogDoneDataOut.files).forEach(file => {
          attach.push({ file, name: file.name });
        });

        Object.values(this.dialogDoneDataOut.overRunCostFiles).forEach(file => {
          attach.push({ file, name: file.name });
        });

        let comment = '';

        if (this.dialogDoneDataOut.comment && this.dialogDoneDataOut.isOverRun) {
          comment = this.dialogDoneDataOut.comment;
        }

        const action = 'setDoneTender';
        const dataParams = {
          tenderId: this.$route.query.id,
          totalCost: this.dialogDoneDataOut.totalCost,
          comment,
          attach
        };
        this.$store
          .dispatch('SendForm', {
            action,
            dataParams,
            attach
          })
          .then(resp => {
            if (resp.result === 'success') {
              this.openDialogInfo('Объявление переведено в статус Подтвердить выполнение', 'back');
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    unPublishTender() {
      return new Promise(resolve => {
        const action = 'unPublishTender';
        const dataParams = {
          tenderId: this.$route.query.id,
          unPublishReason: this.unPublishReason
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'success') {
              this.openDialogInfo(
                'Объявление будет снято с публикации после подтверждения администратором',
                'back'
              );
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getUrgency(urgency) {
      switch (urgency) {
        case 'non-urgent':
          return 'Не срочно';
        case 'urgent':
          return 'Срочно';
      }
    },
    back() {
      this.$router.push('/organizer/newtenders');
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    //получаем
    getTenderOrganizer() {
      return new Promise(resolve => {
        const action = 'getTenderOrganizer';
        const dataParams = {
          tenderId: this.$route.query.id
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else {
              this.tenderInfo = resp.tender;
              this.attachments = resp.attachments;
              this.attachmentsDone = resp.attachmentsDone;
              this.lots = resp.lots;
              this.comment = resp.tender.comment;
              this.dialogDoneDataIn.amount = +resp.tender.amount;
              this.dialogDoneDataIn.totalCost = +resp.tender.totalcost;
              this.proposalsCount = resp.proposals ? resp.proposals.length : 0;
              this.proposalsCountNew = resp.proposalsNew ? resp.proposalsNew.length : 0;
              this.createDate = resp.createDate;
              this.reworkReason = resp.reworkReason ? resp.reworkReason : '';
              this.chat = resp.chat;

              if (resp.whitelist && Object.values(resp.whitelist).length) {
                this.whiteList = resp.whitelist.map(row => row.name).join(', ');
              }
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    readyTender() {
      return new Promise(resolve => {
        const action = 'readyTender';
        const dataParams = {
          tenderId: this.$route.query.id,
          status: this.tenderInfo.status
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.statusReady) {
              this.openDialogInfo(
                'Объявление будет опубликовано после подтверждения администратором',
                'back'
              );
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    closeTender() {
      return new Promise(resolve => {
        const action = 'closeTender';
        const dataParams = {
          tenderId: this.$route.query.id,
          status: this.tenderInfo.status
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.statusClosed) {
              this.openDialogInfo('Объявление закрыто', 'back');
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    confirmDeleteLot(id) {
      if (this.lots.length === 1) {
        this.openDialogInfo('Нельзя удалить единственный объект!');
      } else {
        this.openDialogConfirm('Удалить объект без возможности восстановления?', 'removeLot');
        this.lotIdForDelete = id;
      }
    },
    removeLot() {
      this.dialog = false;
      return new Promise(resolve => {
        const action = 'removeLot';
        const dataParams = {
          lotId: this.lotIdForDelete,
          tenderId: this.tenderId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.getTenderOrganizer();
            } else if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  watch: {
    dialogIncompleteDataOut(v) {
      this.dialogIncompleteDataIn.modal = v.modal;
      this.dialogIncompleteDataOut.comment = v.comment;
      this.dialogIncompleteDataOut.execute = v.execute;
    },
    dialogDoneDataOut(v) {
      this.dialogDoneDataIn.modal = v.modal;
      this.dialogDoneDataOut.comment = v.comment;
      this.dialogDoneDataOut.execute = v.execute;
      this.dialogDoneDataOut.files = v.files;
      this.dialogDoneDataOut.overRunCostFiles = v.overRunCostFiles;
      this.dialogDoneDataOut.totalCost = v.totalCost;
      this.dialogDoneDataOut.validForm = v.validForm;
      this.dialogDoneDataOut.validFormOverRunCost = v.validFormOverRunCost;
      this.dialogDoneDataOut.isOverRun = v.isOverRun;
    }
  },
  mounted() {
    this.getTenderOrganizer();
  }
};
</script>

<style scoped>
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
.chat_list_item {
  max-height: 500px;
}
</style>
