<template>
  <v-card outlined>
    <v-bottom-navigation min-height="50px" flat height="auto" grow color="primary">
      <v-layout wrap>
        <v-flex xs12 sm2>
          <v-btn block value="reg" min-height="50px" height="100%" to="/admin/request/registration">
            <span color="primary" class="title">Регистрация</span>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm2>
          <v-btn block value="access" min-height="50px" height="100%" to="/admin/request/access">
            <span class="title">Права доступа</span>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm2>
          <v-btn block value="publish" min-height="50px" height="100%" to="/admin/request/publish">
            <span color="primary" class="title">Публикация</span>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm2>
          <v-btn
            block
            value="profile"
            min-height="50px"
            height="100%"
            to="/admin/request/editProfile"
          >
            <span class="title">Профиль</span>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm2>
          <v-btn block value="profile" min-height="50px" height="100%" to="/admin/request/revoke">
            <span class="title">Отзыв</span>
          </v-btn>
        </v-flex>
         <v-flex xs12 sm2>
          <v-btn block value="done" min-height="50px" height="100%" to="/admin/request/done">
            <span class="title">Выполнение</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-bottom-navigation>
    <router-view></router-view>
  </v-card>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {}
};
</script>

<style scoped></style>
