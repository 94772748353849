import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "@/router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueCookies from "vue-cookies";
import SearchTender from "./components/shared/search/SearchTender";
import SearchProposals from "./components/shared/search/SearchProposals";
import SearchMyProposals from "./components/shared/search/SearchMyProposals";
import SearchRestriction from "./components/shared/search/SearchRestriction";
import TableTenders from "./components/shared/table/TableTenders";
import TableProposals from "./components/shared/table/TableProposals";
import TableMyProposals from "./components/shared/table/TableMyProposals";
import TableRestriction from "./components/shared/table/TableRestriction";
import Dialog from "./components/shared/Dialog";
import "@mdi/font/css/materialdesignicons.css";
import JsonExcel from 'vue-json-excel'

Vue.config.productionTip = false;
Vue.component("SearchTender", SearchTender);
Vue.component("SearchProposals", SearchProposals);
Vue.component("SearchMyProposals", SearchMyProposals);
Vue.component("SearchRestriction", SearchRestriction);
Vue.component("TableTenders", TableTenders);
Vue.component("TableProposals", TableProposals);
Vue.component("TableMyProposals", TableMyProposals);
Vue.component("TableRestriction", TableRestriction);
Vue.component("Dialog", Dialog);
Vue.component("DownloadExcel", JsonExcel);

Vue.use(VueCookies);

new Vue({
  store,
  vuetify,
  router,
  axios,
  render: (h) => h(App),
}).$mount("#app");
