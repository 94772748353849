<template>
  <v-card outlined>
    <v-card-title>
      Зачем необходимо страховаться?
    </v-card-title>
    <v-card-text>
      Требования по страхованию в договоре подряда В соответствии счастью 1
      статьи 741 Гражданского кодекса Российской Федерации, риск случайной
      гибели или случайного повреждения объекта строительства, составляющего
      предмет договора строительного подряда, до приемки этого объекта
      заказчиком несет подрядчик. Согласно части 1 статьи 742 Гражданского
      кодекса Российской Федерации договором строительного подряда может быть
      предусмотрена обязанность стороны, на которой лежит риск случайной гибели
      или случайного повреждения объекта строительства, материала, оборудования
      и другого имущества, используемых при строительстве, либо ответственность
      за причинение при осуществлении строительства вреда другим лицам,
      застраховать соответствующие риски. Таким образом, допускается включение в
      договоры подряда, требований об обязательном страховании (за счет
      собственных средств подрядчика) работ, связанных с капитальным ремонтом
      общего имущества МКД.
    </v-card-text>
    <v-card-title>
      Какие риски можно страховать? «Страхование гражданской ответственности
      Подрядчика при производстве строительно-монтажных работ» (ГО при СМР).
    </v-card-title>
    <v-card-text>
      Страхованию подлежит ответственность Подрядчика, которая выражается в его
      обязанности возместить, причиненный вред жизни, здоровью или имуществу
      третьих лиц в результате небрежности, ошибки, допущенных при производстве
      СМР или при проведении работ по гарантийному обслуживанию.
    </v-card-text>
    <v-card-title>
      «Страхование послепусковых гарантийные обязательств» (ППГО).
    </v-card-title>
    <v-card-text>
      Страхованию подлежат непредвиденные расходы Подрядчика, обусловленные
      повреждением или гибелью сданных в эксплуатацию объектов, произошедших в
      результате или явившихся следствием: • недостатков, ошибок или упущений,
      допущенных Подрядчиком в период ППГО при проведении работ по гарантийному
      обслуживанию сданного в эксплуатацию объекта; • недостатков, ошибок или
      упущений, допущенных Подрядчиком в период строительства, монтажа или
      испытаний до подписания акта приемки законченного строительством объекта,
      но выявленных в период ППГО.
    </v-card-text>
    <v-card-title>
      Страховые суммы и лимиты ответственности. Срок страхования
    </v-card-title>
    <v-card-subtitle class="subtitle-1 font-weight-medium">
      По страхованию гражданской ответственности:
    </v-card-subtitle>
    <v-card-text>
      - в % от цены договора -на весь срок производства работ в соответствии с
      календарным графиком
    </v-card-text>
    <v-card-subtitle class="subtitle-1 font-weight-medium">
      По страхованию гарантийных обязательств:
    </v-card-subtitle>
    <v-card-text>
      - в размере полной сметной стоимости (цены договора) -на срок гарантийных
      обязательств в соответствии с требованиями заказчика (36мес.)
    </v-card-text>
    <v-card-title>
      Выгодоприобретатели (получатели возмещения)
    </v-card-title>
    <v-card-subtitle class="subtitle-1 font-weight-medium">
      По страхованию гражданской ответственности:
    </v-card-subtitle>
    <v-card-text> - пострадавшие третьи лица</v-card-text>
    <v-card-subtitle class="subtitle-1 font-weight-medium">
      По страхованию гарантийных обязательств:
    </v-card-subtitle>
    <v-card-text>
      - подрядчик
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
