<template>
  <v-card outlined>
    <v-card-title>Белый лист подрядчиков</v-card-title>
    <v-container class="pt-4 container_custom">
      <v-card outlined>
        <v-row align="center" justify="start" class="row_hight">
          <v-col v-for="(selection, i) in selections" :key="selection.text" class="shrink">
            <v-chip :disabled="loading" close @click:close="selected.splice(i, 1)">
              {{ selection.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="12">
          <v-text-field ref="search" v-model="search" full-width hide-details label="Поиск подрядчика" single-line>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-divider v-if="!allSelected"></v-divider> -->
    <v-card elevation="0">
      <v-card-text>
        <v-list dense height="300" class="overflow-y-auto">
          <template v-for="item in list">
            <!-- {{selected}}
            {{item}} -->
            <v-list-item v-if="!selected.includes(item)" :key="item.id" :disabled="loading"
              @click="selected.push(item)">
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn outlined class="btn_action" color="primary" @click="back()">назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined class="btn_action" color="primary" @click="updateWhiteList()">сохранить</v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinDialog";

export default {
  mixins: [Mixin],
  data() {
    return {
      tenderId: this.$route.query.tenderId,
      contractors: [],
      loading: false,
      search: '',
      selected: [],
    }
  },
  methods: {
    updateWhiteList() {
      return new Promise((resolve) => {
        const action = "updateWhiteList";
        const dataParams = {
          tenderId: this.tenderId,
          selected: this.selected,
        };
        const attach = this.attach;
        this.$store
          .dispatch("Api", { action, dataParams, attach })
          .then((resp) => {
            if (resp.result === "success") {
              this.openDialogInfo("Список сохранен");
              this.getContractorsWhiteFilter()
              this.getWhiteList()

            } else if (resp.result === "error") {
              this.openDialogInfo(
                "Вы не являетесь владельцем данного объявления! Доступ запрещен!",
                "redirectToMainPage"
              );
            } else {
              this.openDialogInfo("Список сохранен", "back");
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getWhiteList() {
      return new Promise((resolve) => {
        const action = "getWhiteList";
        const dataParams = {
          tenderId: this.tenderId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            if (resp.result === "success") {
              this.selected = resp.whitelist;
            } else if (resp.result === "error") {
              this.openDialogInfo(
                "Вы не являетесь владельцем данного объявления! Доступ запрещен!",
                "redirectToMainPage"
              );
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getContractorsWhiteFilter() {
      return new Promise(resolve => {
        const action = "getContractorsWhiteFilter";
        const dataParams = {
          tenderId: this.tenderId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.contractors = resp.contractors;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  computed: {
    allSelected() {
      return this.selected.length === this.contractors.length
    },
    list() {
      const search = this.search.toLowerCase()

      if (!search) return this.contractors

      return this.contractors
        .filter(item => {
          const text = item.name.toLowerCase()
          return text.indexOf(search) > -1
        })
    },
    selections() {
      const selections = []

      for (const selection of this.selected) {
        selections.push(selection)
      }

      return selections
    },
  },
  watch: {
    selected() {
      this.search = ''
    },
  },
  mounted() {
    this.getContractorsWhiteFilter()
    this.getWhiteList()
  }
}
</script>

<style scoped>
.row_hight {
  min-height: 120px;
}

.container_custom {
  max-width: 'inherit';
}
</style>
