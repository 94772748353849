<template>
  <v-card outlined>
    <v-list two-line>
      <v-list-item class="mb-5">
        <v-layout class="item_wrap">
          <v-flex d-flex class="data_wrap align-center mr-5">
            <div>Логин</div>
          </v-flex>
          <v-flex d-flex class="data_wrap align-center">
            <v-text-field v-model="login" type="text" outlined dense readonly hide-details disabled></v-text-field>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout class="item_wrap">
          <v-flex d-flex class="data_wrap align-center mr-5">
            <div>Организация</div>
          </v-flex>
          <v-flex d-flex class="data_wrap align-center">
            <v-text-field v-model="orgname" type="text" outlined dense readonly hide-details disabled></v-text-field>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout class="item_wrap">
          <v-flex d-flex class="data_wrap align-center mr-5">
            <div>ИНН</div>
          </v-flex>
          <v-flex d-flex class="data_wrap align-center">
            <v-text-field v-model="inn" type="text" outlined dense readonly hide-details disabled></v-text-field>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout class="item_wrap">
          <v-flex d-flex class="data_wrap align-center mr-5">
            <div>Email</div>
          </v-flex>
          <v-flex d-flex class="data_wrap align-center">
            <v-text-field v-model="email" type="text" outlined dense readonly hide-details disabled></v-text-field>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout class="item_wrap">
          <v-flex d-flex class="data_wrap align-center mr-5">
            <div>ФИО</div>
          </v-flex>
          <v-flex d-flex class="data_wrap align-center">
            <v-text-field v-model="fio" type="text" outlined dense readonly hide-details disabled></v-text-field>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout class="item_wrap">
            <v-flex d-flex class="data_wrap mr-5">
              <div>Смена пароля:</div>
            </v-flex>
            <v-flex class="data_wrap mr-5">
              <v-text-field id="password" name="password" label="Пароль" type="password" v-model="password"
                :rules="passwordRules" outlined required dense clearable></v-text-field>
            </v-flex>
            <v-flex class="data_wrap mr-5">
              <v-text-field id="passwordConfirm" label="Подтвержение пароля" type="password" v-model="passwordConfirm"
                :rules="passwordConfirmRules" outlined required dense clearable></v-text-field>
            </v-flex>

            <v-flex class="align-self-start">
              <v-btn @click="openDialogConfirm('Сменить пароль?', 'changePass')" :disabled="changeBtnStatus" outlined
                color="red darken-3" height="40px">Сменить пароль</v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout column class="item_wrap">
          <v-flex d-flex class="data_wrap align-center">
            <v-btn @click="openDialogConfirm('Обновить?', 'updatePermissions')" outlined color="red darken-3"
              height="40px">Обновить список допусков</v-btn>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout column class="item_wrap">
          <v-flex d-flex class="data_wrap align-center">
            <v-btn color="primary" :href="uploadUrl + 'administrator.zip'" outlined download>Скачать инструкции</v-btn>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout wrap class="item_wrap mb-5">         
          <v-flex xs12 md12 lg3 class="data_wrap">
            <v-btn width="200px" height="40px" outlined color="primary"
              @click="openDialogConfirm('Загрузить документ?', 'uploadListTenders')">загрузить</v-btn>
          </v-flex>
        </v-layout>
      </v-list-item>
    </v-list>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinDialog";
export default {
  mixins: [Mixin],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      changePassDialog: false,
      orgname: "",
      inn: "",
      email: "",
      fio: "",
      password: "",
      passwordConfirm: "",
      minPassLen: 6,
      valid: false,
      login: "",
      passwordRules: [
        v => !!v || "Необходимо ввести пароль",
        v =>
          (v && v.length >= this.minPassLen) ||
          `Пароль должен быть не менее ${this.minPassLen} символов`
      ],
      passwordConfirmRules: [
        v => !!v || "Введите пароль",
        v => v === this.password || "Пароли не соответствует"
      ],
      attach: [
        // { 'file': '' },
        // { 'file': '' },
        // { 'file': '' },
      ],
    };
  },
  methods: {
    uploadListTenders() {
      return new Promise((resolve) => {
        const action = "uploadListTenders";
        const dataParams = {
          attach: this.attach,
        };
        const attach = this.attach;
        this.$store
          .dispatch("SendForm", { action, dataParams, attach })
          .then((resp) => {
            if (resp.result === "success") {
              this.attach = [];
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    updatePermissions() {
      return new Promise(resolve => {
        const action = "updatePermissions";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            if (resp.countInsertPerm) {
              this.openDialogInfo(
                "Создано новых допусков - " + resp.countInsertPerm
              );
            } else {
              this.openDialogInfo("Новых допусков не создано");
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getAdminInfo() {
      return new Promise(resolve => {
        const action = "getAdminInfo";
        const dataParams = {
          id: this.$store.getters.id
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            const user = resp.user;
            this.login = user.login;
            this.orgname = user.orgname;
            this.inn = user.inn;
            this.email = user.email;
            this.fio =
              user.firstname + " " + user.secondname + " " + user.lastname;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    changePass() {
      if (this.$refs.form.validate()) {
        return new Promise(resolve => {
          const action = "reset";
          const dataParams = {
            email: this.email,
            password: this.password
          };
          this.$store
            .dispatch("login", { action, dataParams })
            .then(resp => {
              if (resp.result == "success") {
                this.openDialogInfo('Пароль успешно сохранен');
                this.changePassDialog = true;
                this.password = "";
                this.passwordConfirm = "";
              } else {
                this.openDialogInfo('Ошибка изменения пароля');
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    ok() {
      this.changePassDialog = false;
    }
  },
  mounted() {
    this.getAdminInfo();
  },
  computed: {
    changeBtnStatus() {
      if (this.password != "" && this.passwordConfirm != "" && this.valid) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}

.data_wrap {
  max-width: max-content;
}

.title_data {
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
}

.inputFieldLeft {
  /* display: flex; */
  justify-content: right;
  width: 300px;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .item_wrap {
    flex-direction: column;
  }

  .inputFieldLeft {
    margin-bottom: 15px;
    width: 200px;
  }
}
</style>
