<template>
  <v-layout wrap class="justify-start">
    <v-flex sx12 sm4 class=" wrap_column">
      <v-layout column class="px-5">
        <v-flex class="mt-5 px-0">Организация</v-flex>
        <v-flex class="text_field">
          <v-text-field
            v-model="searchData.contractor"
            append-icon="search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex sx12 sm4 class="wrap_column">
      <v-layout column class="px-5">
        <v-flex class="mt-5 px-0">Город</v-flex>
        <v-flex class="text_field">
          <v-select
            :items="dataSearch.citiesListSearch"
            item-value="name"
            item-text="name"
            v-model="searchData.city"
            single-line
            hide-details
          ></v-select>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex sx12 sm4 class="wrap_column">
      <v-layout column class="px-5">
        <v-flex class="mt-5 px-0">Тип работ</v-flex>
        <v-flex class="text_field">
          <v-select
            :items="dataSearch.worktypesSearch"
            item-value="type"
            item-text="type"
            v-model="searchData.type"
            single-line
            hide-details
          ></v-select>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Mixin from "../../mixin/js/mixinRestriction";

export default {
  mixins: [Mixin],
  props: ["dataSearch"],
  data() {
    return {};
  },
  // watch: {
  //   //ставим глубокий обработчик для объекта
  //   searchData: {
  //     handler(v) {
  //       this.$emit("data", v);
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style>
.wrap_column {
  max-width: 280px !important;
}
.v-slider--horizontal {
  margin: 0 !important;
}
.slider_field {
  width: 100% !important;
}
.slider_wrap {
  flex-wrap: wrap;
}
.text_field {
  max-width: 240px !important;
}
.date-picker {
  height: 48px;
}
.subheader_sum {
  height: auto;
  margin-top: 10px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
