<template>
  <v-dialog width="400px" v-model="modal" @click:outside="cancel">
    <v-card>
      <v-card-title class="justify-center title_text">Отменить объявление?</v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex>
            <v-textarea
              v-model="comment"
              label="Комментарий"
              outlined
              dense
              hide-details
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <v-btn width="100px" outlined color="primary" @click="cancel">отмена</v-btn>
        <v-btn width="100px" outlined color="primary" @click="ok">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialogDataIn'],
  data() {
    return {
      modal: false,
      comment: ''
    };
  },
  methods: {
    cancel() {
      this.modal = false;
      this.$emit('dialogDataOut', { 
        modal: this.modal
        });
    },
    ok() {
      this.modal = false;
      this.$emit('dialogDataOut', {
        comment: this.comment,
        execute: true,
        file: this.file,
        modal: this.modal
      });
    }
  },
  watch: {
    //ставим глубокий обработчик для объекта
    dialogDataIn: {
      handler(v) {
        this.modal = v.modal;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.title_text {
  word-break: break-word;
}
</style>
