<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},

  computed: {},
  mounted() { }
};
</script>

<style scoped>

</style>
