<template>
  <v-card outlined>
    <v-card-title class="pl-9">
      Пользователи
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout wrap class="justify-start">
            <v-flex sx12 sm6 class="px-5">
              <v-text-field v-model="searchLogin" append-icon="search" label="Логин" single-line hide-details
                clearable></v-text-field>
            </v-flex>
            <v-flex sx12 sm6 class="px-5">
              <v-text-field v-model="searchOrg" append-icon="search" label="Организация" single-line hide-details
                clearable>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="mt-10">
          <v-data-table :headers="headers" :items="filteredItems" @click:row="goTo" locale="ru">
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      noDataText: "Нет данных",
      users: [],
      searchLogin: null,
      searchOrg: null,
      headers: [
        {
          text: "Логин",
          align: "left",
          sortable: false,
          value: "login"
        },
        {
          text: "Организация",
          align: "left",
          sortable: false,
          value: "orgname"
        }
      ]
    };
  },
  methods: {
    goTo(item) {
      this.$router.push({ path: "/admin/usercard", query: { id: item.id } });
    },
    getAllUsers() {
      return new Promise(resolve => {
        const action = "getAllUsers";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.users = resp.users;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    filteredItems: {
      get() {
        return this.users.filter(item => {
          return (
            this.searchLogin === null ||
            item.login.toLowerCase().indexOf(this.searchLogin.toLowerCase()) != -1
          );
        })
          .filter(item => {
            return (
              this.searchOrg === null ||
              item.orgname.toLowerCase().indexOf(this.searchOrg.toLowerCase()) != -1
            );
          })
          ;
      },
      set(v) {
        return v;
      }
    }
  },
  mounted() {
    this.getAllUsers();
  }
};
</script>

<style scoped>

</style>
