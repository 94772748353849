<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Пользовательское соглашение</v-card-title>
      <v-card-text
        ><v-flex>
        <iframe
          :src="uploadUrl + 'Инструкция_по_регистрации.pdf'"
          width="100%"
          height="100%"
          frameborder="0"
          >
         </iframe
        ></v-flex></v-card-text
      >
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
       uploadUrl: process.env.VUE_APP_UPLOADS_URL,
    };
  }
};
</script>
