<template>
  <v-dialog v-model="modal" @click:outside="onClose">
    <v-card>
      <v-card-title class="justify-center title_text">Подтверждение объявления</v-card-title>
      <v-card-text>
        <v-form ref="form1" v-model="validForm" validation>
          <v-layout column>
            <v-flex>
              <v-card outlined class="mb-3">
                <v-card-subtitle>
                  Прикрепите скан АВР или КС-2, подписанный обеими сторонами (не менее 1, но не
                  более 5 файлов) по объявлению
                </v-card-subtitle>
                <v-file-input
                  outlined
                  label="Загрузить документы"
                  v-model="files"
                  :rules="uploadRules"
                  dense
                  multiple
                  show-size
                  class="mx-2"
                ></v-file-input>
              </v-card>
            </v-flex>
            <v-flex>
              <v-text-field
                type="number"
                label="Итоговая стоимость работ"
                v-model="totalCost"
                step="0.01"
                min="0"
                placeholder="0,00"
                :rules="totalCostRules"
                required
                dense
                outlined
                color="primary"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
        <v-expand-transition>
          <v-form v-show="expand" ref="form2" v-model="validFormOverRunCost" validation>
            <v-layout column>
              <v-flex>
                <v-divider class="mb-5" />
                <v-card elevation="2" class="pa-2">
                  <v-alert dense type="error" color="red lighten-2" dark>
                    Итоговая стоимость работ превышает согласованную стоимость работ
                  </v-alert>
                  <v-card-title>
                    Причины превышения согласованной стоимости работ
                  </v-card-title>
                  <v-card-text>
                    Добавьте документ, подтверждающий необходимость увеличения начальной стоимости
                    (не менее 1, но не более 5 файлов)
                  </v-card-text>
                  <v-file-input
                    outlined
                    label="Загрузить документы"
                    v-model="overRunCostFiles"
                    :rules="uploadRules"
                    dense
                    multiple
                    show-size
                    class="mx-2"
                  ></v-file-input>
                  <v-textarea
                    v-model="comment"
                    :rules="commentRules"
                    label="Комментарий"
                    outlined
                    dense
                    counter
                    class="mx-2"
                  ></v-textarea>
                </v-card>
              </v-flex>
            </v-layout>
          </v-form>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <v-btn outlined color="primary" @click="onClose">
          выход
        </v-btn>
        <v-btn outlined color="primary" :disabled="disabledConfirm" @click="onConfirm">
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialogDataIn'],
  data() {
    return {
      modal: false,
      comment: '',
      files: [],
      overRunCostFiles: [],
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.length >= 1 && v.length <= 5) || 'Не менее 1 и не более 5 документов',
        v => {
          const sizeCount = Object.values(v).reduce((acc, obj) => acc + obj.size, 0);
          return (v && sizeCount < 10000000) || 'Не более 10 MB!';
        }
      ],
      totalCostRules: [v => !!v || 'Необходимо ввести сумму'],
      commentRules: [
        v => (v && v.length > 10 && v.length < 500) || 'Не менее 10 и не более 500 символов'
      ],
      amount: 0,
      totalCost: '',
      validForm: false,
      validFormOverRunCost: false
    };
  },
  methods: {
    onConfirm() {
      this.modal = false;

      this.$emit('dialogDataOut', {
        modal: this.modal,
        comment: this.comment,
        files: this.files,
        overRunCostFiles: this.overRunCostFiles,
        totalCost: this.totalCost,
        validForm: this.validForm,
        validFormOverRunCost: this.validFormOverRunCost,
        execute: true,
        isOverRun: this.amount < this.totalCost
      });
    },
    onClose() {
      this.modal = false;

      this.$emit('dialogDataOut', {
        modal: this.modal
      });
    }
  },
  computed: {
    disabledConfirm() {
      if (
        (!this.expand && this.validForm) ||
        (this.expand && this.validForm && this.validFormOverRunCost)
      ) {
        return false;
      } else {
        return true;
      }
    },
    expand() {
      if (this.amount < this.totalCost) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    //ставим глубокий обработчик для объекта
    dialogDataIn: {
      handler(v) {
        this.modal = v.modal;
        this.amount = v.amount;
        this.totalCost = v.totalCost;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.title_text {
  word-break: break-word;
}
</style>
