<template>
  <v-card outlined>
    <v-card-title class="pl-8">Редактирование объекта</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" validation>
        <v-list>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12>
                <v-text-field
                  :maxlength="$store.getters.titleAdMaxLength"
                  v-model="name"
                  type="text"
                  label="Название"
                  :rules="[v => !!v || 'Необходимо ввести название объявления']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="street"
                  type="text"
                  label="Улица"
                  :rules="[
                    v => !!v || 'Необходимо ввести улицу',
                    v =>
                      (v && v.length <= this.maxStreetLength) ||
                      `Наименование улицы должно быть не более ${this.maxStreetLength} символов`
                  ]"
                  clearable
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="homenumber"
                  type="text"
                  label="Дом"
                  :rules="[
                    v => !!v || 'Необходимо ввести дом',
                    v =>
                      (v && v.length <= this.maxHouseNumberLength) ||
                      `Номер дома должен быть не более ${this.maxHouseNumberLength} символов`
                  ]"
                  clearable
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="currentAttach.length < 1" class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Нет прикрепленных файлов</v-card-subtitle>
            </v-card>
          </v-list-item>
          <v-list-item v-else class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Прикрепленные файлы</v-card-subtitle>
              <v-card-text v-for="(item, i) in currentAttach" :key="i">
                <v-layout wrap class="justify-space-between">
                  <v-flex xs12 sm5 class="mb-4">
                    <v-text-field
                      v-model="item.filename"
                      type="text"
                      label="Имя файла"
                      outlined
                      dense
                      disabled
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 class="mb-4">
                    <v-btn outlined block color="primary" @click="confirmDelete(item.attachment)">
                      <div class="title">Удалить</div>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-list-item>
          <v-list-item class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Прикрепить</v-card-subtitle>
              <v-card-text v-for="(item, i) in newAttach" :key="i">
                <v-layout wrap class="justify-space-between">
                  <v-flex xs12 sm5 class="mb-4">
                    <v-file-input
                      type="file"
                      outlined
                      label="Загрузить документ"
                      v-model="item.file"
                      :rules="uploadRules"
                      dense
                    ></v-file-input>
                  </v-flex>
                  <v-flex xs12 sm5 class="mb-4">
                    <v-text-field
                      :maxlength="$store.getters.fileNameMaxLength"
                      v-model="item.name"
                      type="text"
                      label="Имя файла"
                      :rules="[v => !!v || 'Необходимо ввести имя файла']"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-btn
                    x-large
                    icon
                    color="primary"
                    class="ml-3 mt-n2"
                    @click="removeItemsAttach(i)"
                  >
                    <v-icon>delete_outline</v-icon>
                  </v-btn>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions class="pl-4">
                <v-btn outlined color="primary" @click="addItemsAttach">
                  <div>Добавить приложение</div>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        :to="{
          path: '/organizer/tender',
          query: { id: tenderId }
        }"
      >
        отмена
      </v-btn>
      <v-btn outlined color="primary" @click="editLot" :disabled="!valid">Сохранить</v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_TENDER_URL,
      lotId: this.$route.query.id,
      tenderId: this.$route.query.tenderId,
      lotInfo: '',
      name: '',
      street: '',
      homenumber: '',
      attachments: [],
      currentAttach: [],
      newAttach: [],
      valid: false,
      attachmentForDelete: '',
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.size < 10000000) || 'Не более 10 MB!'
      ],
      maxHouseNumberLength: 50,
      maxStreetLength: 50
    };
  },
  methods: {
    editLot() {
      return new Promise(resolve => {
        const action = 'editLot';
        const dataParams = {
          tenderId: this.tenderId,
          lotId: this.lotId,
          name: this.name,
          street: this.street,
          homenumber: this.homenumber,
          comment: this.comment,
          attach: this.newAttach
        };
        const attach = this.newAttach;
        this.$store
          .dispatch('SendForm', { action, dataParams, attach })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else if (resp.id) {
              this.openDialogInfo('Изменения успешно сохранены', 'back');
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getLot() {
      return new Promise(resolve => {
        const action = 'getLot';
        const dataParams = {
          lotId: this.lotId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else {
              this.name = resp.lot.name;
              this.street = resp.lot.street;
              this.homenumber = resp.lot.homenumber;
              this.attachments = resp.attachments;
              this.currentAttach = resp.attachments ? resp.attachments : [];
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.go(-1);
    },
    exit() {
      this.modal = false;
    },
    ok() {
      this.$router.push({
        path: '/organizer/tender',
        query: { id: this.tenderId }
      });
    },
    getTenderInfo() {
      if (this.$refs.form.validate()) {
        alert('заявка принята');
      }
    },
    addItemsAttach() {
      if (this.currentAttach.length + this.newAttach.length < 5) {
        this.newAttach.push({});
      }
    },
    removeItemsAttach(i) {
      if (this.currentAttach.length + this.newAttach.length > 1) {
        this.newAttach = [...this.newAttach.slice(0, i), ...this.newAttach.slice(i + 1)];
      }
    },
    confirmDelete(attachment) {
      if (this.currentAttach.length === 1) {
        this.openDialogInfo('Нельзя удалить единственное приложение!');
      } else {
        this.openDialogConfirm(
          'Удалить файлы без возможности восстановления?',
          'removeCurrentLotAttach'
        );
        this.attachmentForDelete = attachment;
      }
    },
    cancelDelete() {
      this.dialog = false;
      this.attachmentForDelete = '';
    },
    removeCurrentLotAttach() {
      this.dialog = false;
      return new Promise(resolve => {
        const action = 'removeCurrentLotAttach';
        const dataParams = {
          lotId: this.lotId,
          tenderId: this.tenderId,
          attachment: this.attachmentForDelete
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else if (resp.attachments !== null) {
              this.currentAttach = resp.attachments;
            } else {
              this.currentAttach = [];
              if (this.newAttach.length < 1) this.addItemsAttach();
            }
            this.attachmentForDelete = '';
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  mounted() {
    this.getLot();
  }
};
</script>

<style scoped>
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .item_wrap {
    flex-direction: column;
  }
}
</style>
