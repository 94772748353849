var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"px-0"},[_vm._v(" Объект «"+_vm._s(_vm.lotInfo.name)+"» к объявлению «"+_vm._s(_vm.lotInfo.tendername)+"» ")]),_c('v-card-text',{staticClass:"px-0"},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("г. "+_vm._s(_vm.lotInfo.city)+", ул. "+_vm._s(_vm.lotInfo.street)+", д. "+_vm._s(_vm.lotInfo.homenumber)+" ")]),_c('v-list-item-subtitle',[_vm._v("Полный адрес")])],1)],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.lotInfo.type))]),_c('v-list-item-subtitle',[_vm._v("Тип работ")])],1)],1),_c('v-divider',{staticClass:"px-0"}),_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-title',[_vm._v("Документация:")])],1),(!_vm.attachments)?_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-subtitle',[_vm._v("Документации нет")])],1):_vm._l((_vm.attachments),function(attach,i){return _c('v-list-item',{key:i,staticClass:"px-0"},[_c('v-layout',{staticClass:"justify-space-between",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"data_wrap align-center",attrs:{"sx12":"","sm10":"","d-flex":""}},[_c('div',[_vm._v(_vm._s(attach.filename))])]),_c('v-flex',{staticClass:"data_wrap align-center",attrs:{"sx12":"","sm2":"","d-flex":""}},[_c('v-btn',{staticClass:"btn_action",attrs:{"outlined":"","color":"primary","href":_vm.uploadUrl +
                  _vm.lotInfo.tenderid +
                  '/lots/' +
                  _vm.lotId +
                  '/' +
                  attach.attachment,"download":""}},[_vm._v("скачать")])],1)],1)],1)})],2)],1),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{staticClass:"btn_action",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.back()}}},[_vm._v("назад")]),(_vm.lotInfo.tenderstatus == 'new' || _vm.lotInfo.tenderstatus == 'rework')?_c('v-btn',{attrs:{"outlined":"","color":"primary","to":{
        path: '/organizer/editlot',
        query: {
          id: _vm.lotId,
          tenderId: _vm.lotInfo.tenderid,
        },
      }}},[_vm._v("Редактировать")]):_vm._e()],1),_c('Dialog',{attrs:{"dialogDataIn":_vm.dialogDataIn},on:{"dialogDataOut":function($event){_vm.dialogDataOut = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }