<template>
  <v-card outlined>
    <v-card-title class="pl-8">Редактирование объявления</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" validation>
        <v-list>
          <v-list-item>
            <v-layout wrap class="-space-betweejustifyn">
              <v-flex xs12>
                <v-text-field
                  :maxlength="$store.getters.titleAdMaxLength"
                  v-model="name"
                  type="text"
                  label="Название вида работ"
                  :rules="[v => !!v || 'Необходимо ввести название вида работ']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  :label="getTitle"
                  v-model="startAmount"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder="0,00"
                  :rules="[v => !!v || 'Необходимо ввести сумму']"
                  required
                  dense
                  clearable
                  outlined
                  color="primary"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-select
                  v-model="city"
                  :items="citiesList"
                  item-value="id"
                  item-text="name"
                  :rules="[v => !!v || 'Необходимо ввести город']"
                  label="Город"
                  required
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 sm5></v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="volume"
                  type="text"
                  label="Объем работ"
                  :rules="[v => !!v || 'Необходимо ввести объем работ']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-select
                  v-model="type"
                  :items="worktypes"
                  item-value="id"
                  item-text="type"
                  label="Тип работ"
                  :rules="[v => !!v || 'Выберите тип работ']"
                  clearable
                  required
                  outlined
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-text-field
                  v-model="orderid"
                  type="text"
                  label="Номер заказа на работы"
                  :rules="[v => !!v || 'Необходимо ввести номер заказа']"
                  required
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5>
                <v-select
                  v-model="urgencyId"
                  :items="urgencyList"
                  item-value="id"
                  item-text="name"
                  label="Признак срочности"
                  :rules="[v => !!v || 'Выберите признак срочности']"
                  clearable
                  required
                  outlined
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5>
                <v-menu
                  v-model="menuStartTender"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="start_TenderDate"
                      label="Начало объявления"
                      :rules="startTenderDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="start_TenderDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_TenderDateISO"
                    no-title
                    locale="rus"
                    @input="menuStartTender = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm5>
                <v-menu
                  v-model="menuEndTender"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="end_TenderDate"
                      label="Окончание объявления"
                      :rules="endTenderDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="end_TenderDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_TenderDateISO"
                    no-title
                    locale="rus"
                    @input="menuEndTender = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-layout wrap class="justify-space-between">
              <v-flex xs12 sm5 class="">
                <v-menu
                  v-model="menuStartWork"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="start_WorkDate"
                      label="Начало работ"
                      :rules="startWorkDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="start_WorkDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_WorkDateISO"
                    no-title
                    locale="rus"
                    @input="menuStartWork = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm5 class="">
                <v-menu
                  v-model="menuEndWork"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="end_WorkDate"
                      label="Окончание работ"
                      :rules="endWorkDateRules"
                      v-on="on"
                      required
                      clearable
                      outlined
                      dense
                      @click:clear="end_WorkDateISO = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_WorkDateISO"
                    no-title
                    locale="rus"
                    @input="menuEndWork = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="currentAttach.length < 1" class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Нет прикрепленных файлов</v-card-subtitle>
            </v-card>
          </v-list-item>
          <v-list-item v-else class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Прикрепленные файлы</v-card-subtitle>
              <v-card-text v-for="(item, i) in currentAttach" :key="i">
                <v-layout wrap class="justify-space-between">
                  <v-flex xs12 sm5 class="mb-4">
                    <v-text-field
                      v-model="item.filename"
                      type="text"
                      label="Имя файла"
                      outlined
                      dense
                      disabled
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 class="mb-4">
                    <v-btn outlined block color="primary" @click="confirmDelete(item.attachment)">
                      <div class="title">Удалить</div>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-list-item>
          <v-list-item class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Прикрепить</v-card-subtitle>
              <v-card-text v-for="(item, i) in newAttach" :key="i">
                <v-layout wrap class="justify-space-between">
                  <v-flex xs12 sm5 class="mb-4">
                    <v-file-input
                      type="file"
                      outlined
                      label="Загрузить документ"
                      v-model="item.file"
                      :rules="uploadRules"
                      dense
                    ></v-file-input>
                  </v-flex>
                  <v-flex xs12 sm5 class="mb-4">
                    <v-text-field
                      :maxlength="$store.getters.fileNameMaxLength"
                      v-model="item.name"
                      type="text"
                      label="Имя файла"
                      :rules="[v => !!v || 'Необходимо ввести имя файла']"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-btn
                    x-large
                    icon
                    color="primary"
                    class="ml-3 mt-n2"
                    @click="removeItemsAttach(i)"
                  >
                    <v-icon>delete_outline</v-icon>
                  </v-btn>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions class="pl-4">
                <v-btn outlined color="primary" @click="addItemsAttach">
                  <div>Добавить приложение</div>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item>
          <v-list-item class="mb-5">
            <v-layout>
              <v-flex>
                <v-textarea
                  v-model="comment"
                  label="Комментарий"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        :to="{
          path: '/organizer/tender',
          query: { id: tenderId }
        }"
      >
        отмена
      </v-btn>
      <v-btn
        outlined
        color="primary"
        @click="$refs.form.validate() && openDialogConfirm('Сохранить объявление?', 'editTender')"
        :disabled="!valid"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      urgencyId: '',
      urgencyList: [],
      permissions: [],
      orderid: '',
      startAmount: '',
      start_TenderDate: '',
      end_TenderDate: '',
      start_WorkDate: '',
      end_WorkDate: '',
      start_TenderDateISO: null,
      end_TenderDateISO: null,
      start_WorkDateISO: null,
      end_WorkDateISO: null,
      menuStartTender: false,
      menuEndTender: false,
      menuStartWork: false,
      menuEndWork: false,
      currentAttach: [],
      newAttach: [],
      tender: '',
      comment: '',
      volume: '',
      name: '',
      city: '',
      citiesList: [],
      type: '',
      worktypes: [],
      date: '',
      valid: false,
      attachmentForDelete: '',
      startTenderDateRules: [v => !!v || 'Выберите дату'],
      endTenderDateRules: [
        v => !!v || 'Выберите дату',
        () => this.valid_EndTenderDate || 'Выбранная дата меньше либо равна дате начала объявления'
      ],
      startWorkDateRules: [
        v => !!v || 'Выберите дату',
        () =>
          this.valid_StartWorkDate || 'Выбранная дата меньше или равна дате окончания объявления'
      ],
      endWorkDateRules: [
        v => !!v || 'Выберите дату',
        () => this.valid_EndWorkDate || 'Выбранная дата меньше или равна дате начала работ'
      ],
      uploadRules: [
        v => !!v || 'Необходимо загрузить документ',
        v => (v && v.size < 10000000) || 'Не более 10 MB!'
      ],
      tenderId: this.$route.query.tenderId
    };
  },
  methods: {
    getTenderOrganizer() {
      return new Promise(resolve => {
        const action = 'getTenderOrganizer';
        const dataParams = {
          tenderId: this.tenderId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else {
              const tender = resp.tender;
              this.tender = tender;
              this.start_TenderDate = this.formatDate(tender.startauctiondate);
              this.end_TenderDate = this.formatDate(tender.endauctiondate);
              this.start_WorkDate = this.formatDate(tender.startworkdate);
              this.end_WorkDate = this.formatDate(tender.endworkdate);
              this.start_TenderDateISO = tender.startauctiondate.slice(0, 10);
              this.end_TenderDateISO = tender.endauctiondate.slice(0, 10);
              this.start_WorkDateISO = tender.startworkdate.slice(0, 10);
              this.end_WorkDateISO = tender.endworkdate.slice(0, 10);
              this.name = tender.name;
              this.comment = tender.comment;
              this.startAmount = tender.startamount;
              this.volume = tender.volume;
              this.orderid = tender.orderid;
              this.city = tender.cityid;
              this.type = tender.typeid;
              this.urgencyId = tender.urgencyid;
              this.currentAttach = resp.attachments ? resp.attachments : [];
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    editTender() {
      return new Promise(resolve => {
        const action = 'editTender';
        const dataParams = {
          tenderId: this.$route.query.tenderId,
          name: this.name,
          startAmount: this.startAmount,
          cityId: this.city,
          worktypeId: this.type,
          volume: this.volume,
          orderid: this.orderid,
          startauctiondate: this.start_TenderDateISO,
          endauctiondate: this.end_TenderDateISO,
          startworkdate: this.start_WorkDateISO,
          endworkdate: this.end_WorkDateISO,
          comment: this.comment,
          urgencyId: this.urgencyId,
          attach: this.newAttach
        };
        const attach = this.newAttach;
        this.$store
          .dispatch('SendForm', { action, dataParams, attach })
          .then(resp => {
            if (resp.id) {
              this.ok();
            } else if (resp.result === 'error' && resp.error_msg === 'error_owner') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else if (resp.result === 'error' && resp.error_msg === 'error_dates') {
              this.openDialogInfo('Указаны некорректные даты в объявлении.');
            }

            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    confirmDelete(attachment) {
      if (this.currentAttach.length === 1) {
        this.openDialogInfo('Нельзя удалить единственное приложение!');
      } else {
        this.openDialogConfirm(
          'Удалить файлы без возможности восстановления?',
          'removeCurrentAttach'
        );
        this.attachmentForDelete = attachment;
      }
    },
    removeCurrentAttach() {
      return new Promise(resolve => {
        const action = 'removeCurrentAttach';
        const dataParams = {
          tenderId: this.$route.query.tenderId,
          attachment: this.attachmentForDelete
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result === 'error') {
              this.openDialogInfo(
                'Вы не являетесь владельцем данного объявления! Доступ запрещен!',
                'redirectToMainPage'
              );
            } else if (resp.attachments !== null) {
              this.currentAttach = resp.attachments;
            } else {
              this.currentAttach = [];
              if (this.newAttach.length < 1) this.addItemsAttach();
            }
            this.attachmentForDelete = '';
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getCitiesList() {
      return new Promise(resolve => {
        const action = 'getCitiesList';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.citiesList = resp.citiesList;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getWorkTypes() {
      return new Promise(resolve => {
        const action = 'getWorkTypes';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.worktypes = resp.worktypes;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getUrgencyList() {
      return new Promise(resolve => {
        const action = 'getUrgencyList';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.urgencyList = resp.urgencyList;
            this.urgencyList.map(i => {
              i.name = this.getUrgency(i.name);
            });
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    ok() {
      this.$router.go(-1);
    },
    addItemsAttach() {
      if (this.currentAttach.length + this.newAttach.length < 5) {
        this.newAttach.push({});
      }
    },
    removeItemsAttach(i) {
      if (this.currentAttach.length + this.newAttach.length > 1) {
        this.newAttach = [...this.newAttach.slice(0, i), ...this.newAttach.slice(i + 1)];
      }
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    parseDateToUnix(string) {
      if (!string) return null;

      const [day, month, year] = string.split('.');
      return Date.parse(`${year}-${month}-${day}`);
    },
    getUrgency(urgency) {
      switch (urgency) {
        case 'non-urgent':
          return 'Не срочно';
        case 'urgent':
          return 'Срочно';
      }
    },
    getPermissions() {
      return new Promise(resolve => {
        const action = 'getPermissions';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.permissions = resp.data;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    getTitle() {
      const insurance = this.permissions.find(
        item => item.cityid === this.city && item.worktypeid === this.type
      );
      if (typeof insurance == 'object' && insurance.insurance) {
        return 'Начальная стоимость работ с учетом страхования';
      } else {
        return 'Начальная стоимость работ';
      }
    },
    valid_EndTenderDate() {     
      return this.end_TenderDateISO > this.start_TenderDateISO;
    },
    valid_StartWorkDate() {
      return this.start_WorkDateISO > this.end_TenderDateISO;
    },
    valid_EndWorkDate() {
      return this.end_WorkDateISO > this.start_WorkDateISO;
    }
  },
  watch: {
    start_TenderDateISO() {
      this.start_TenderDate = this.formatDate(this.start_TenderDateISO);
    },
    end_TenderDateISO() {
      this.end_TenderDate = this.formatDate(this.end_TenderDateISO);
    },
    start_WorkDateISO() {
      this.start_WorkDate = this.formatDate(this.start_WorkDateISO);
    },
    end_WorkDateISO() {
      this.end_WorkDate = this.formatDate(this.end_WorkDateISO);
    },
    type(v) {
      if (typeof v == 'object') this.type = v.id;
    }
  },
  mounted() {
    this.getTenderOrganizer();
    this.getCitiesList();
    this.getWorkTypes();
    this.getUrgencyList();
    this.getPermissions();
  }
};
</script>

<style scoped>
.date-picker {
  height: 48px;
}
.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}
.data_wrap {
  max-width: max-content;
}
</style>
