<template>
  <v-card outlined>
    <v-card-title class="pl-8"
      >Редактирование заявки к объявлению "{{ proposal.name }}"</v-card-title
    >
    <v-card-text>
      <v-form ref="form" v-model="valid" validation>
        <v-list>
          <v-list-item>
            <v-layout wrap class="item_wrap">
              <v-flex d-flex class="data_wrap align-start mr-5">
                <div class="subtitle-1">
                  Для участия в объявлении введите сумму:
                </div>
              </v-flex>
              <v-flex d-flex class="data_wrap align-center">
                <v-form ref="form" v-model="valid" validation>
                  <v-text-field
                    v-model="proposal.betamount"
                    type="number"
                    step="1"
                    min="0"
                    placeholder="0"
                    :rules="rateRules"
                    required
                    dense
                    outlined
                    color="primary"
                    @input="formatNumber"
                  ></v-text-field>
                </v-form>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="proposal.insurance">
            <v-layout wrap class="item_wrap">
              <v-flex d-flex class="data_wrap align-start mr-5">
                <div class="subtitle-1">
                  Стоимость страхового полиса:
                </div>
              </v-flex>
              <v-flex d-flex class="data_wrap align-center">
                <v-text-field
                  v-model="costpolicy"
                  placeholder="0,00"
                  required
                  dense
                  outlined
                  color="primary"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="proposal.insurance">
            <v-layout wrap class="item_wrap">
              <v-flex d-flex class="data_wrap align-start mr-5">
                <div class="subtitle-1">
                  Стоимость работ без учета страхования*:
                </div>
              </v-flex>
              <v-flex d-flex class="data_wrap align-center">
                <v-text-field
                  v-model="betamountInsurLess"
                  placeholder="0,00"
                  dense
                  outlined
                  color="primary"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="proposal.insurance">
            <div class="subtitle-2 font-italic">
              *Указана сумма, которая будет перечислена за выполненные работы
            </div>
          </v-list-item>
          <v-list-item v-if="currentAttach.length < 1" class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Нет прикрепленных файлов</v-card-subtitle>
            </v-card>
          </v-list-item>

          <v-list-item v-else class="mb-5">
            <v-card width="100%" outlined>
              <v-card-subtitle>Прикрепленные файлы</v-card-subtitle>
              <v-card-text v-for="(item, i) in currentAttach" :key="i">
                <v-layout wrap class="justify-space-between">
                  <v-flex xs12 sm2 class="mb-4">
                    <div>{{ item.filename }}</div>
                  </v-flex>
                  <v-flex xs12 sm10>
                    <v-layout wrap class="justify-sm-end justify-xs-center">
                      <v-btn
                        class="btn_action ma-2"
                        outlined
                        color="primary"
                        :href="
                          uploadUrl + item.proposalid + '/' + item.attachment
                        "
                        download
                        >скачать</v-btn
                      >
                      <v-btn
                        outlined
                        color="primary"
                        class="btn_action ma-2"
                        @click="confirmDelete(item.attachment)"
                        >Удалить</v-btn
                      >
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-list-item>
          <v-card-subtitle class="px-0">Приложения</v-card-subtitle>
          <v-card-text>
            <v-layout
              v-for="(item, i) in attach"
              :key="i"
              wrap
              class="justify-space-between"
            >
              <v-flex xs12 sm5 class="mb-4">
                <v-file-input
                  type="file"
                  label="Загрузить документ"
                  v-model="item.file"
                  :rules="uploadRules"
                  outlined
                  dense
                ></v-file-input>
              </v-flex>
              <v-flex xs12 sm5 class="mb-4">
                <v-text-field
                  :maxlength="$store.getters.fileNameMaxLength"
                  type="text"
                  label="Имя файла"
                  v-model="item.name"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-btn
                x-large
                icon
                color="primary"
                class="ml-3 mt-n2"
                @click="removeItemsAttach(i)"
                ><v-icon>delete_outline</v-icon></v-btn
              >
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pl-4">
            <v-btn outlined color="primary" @click="addItemsAttach"
              ><div class="title">Добавить приложение</div></v-btn
            >
          </v-card-actions>
          <v-list-item class="mb-5">
            <v-layout>
              <v-flex>
                <v-textarea
                  v-model="proposal.comment"
                  label="Комментарий"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn class="btn_action" outlined color="primary" @click="back"
        >отмена</v-btn
      >
      <v-btn
        class="btn_action"
        outlined
        color="primary"
        @click="editProposal"
        :disabled="createBtnStatus"
        >Сохранить</v-btn
      >
      <v-btn
        color="primary"
        @click="
          openDialogConfirm(
            'Вы уверены, что хотите отменить заявку?',
            'closeProposal'
          )
        "
        :disabled="createBtnStatus"
        >Отменить заявку</v-btn
      >
    </v-card-actions>
    <Dialog
      @dialogDataOut="dialogDataOut = $event"
      :dialogDataIn="dialogDataIn"
    ></Dialog>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinDialog";
export default {
  mixins: [Mixin],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_PROPOSAL_URL,
      proposalId: this.$route.query.proposalId,
      files: [],
      currentAttach: [],
      attach: [{}],
      rateRules: [
        (v) => !!v || "Необходимо ввести сумму",
        (v) => (v && v <= this.proposal.startamount - 1) || "Превышена максимальная сумма заявки!"
      ],
      uploadRules: [
        (v) => !!v || "Необходимо загрузить документ",
        (v) => (v && v.size < 10000000) || "Не более 10 MB!",
      ],
      proposal: "",
      valid: false,
      attachmentForDelete: "",
    };
  },
  methods: {
    getUserProposalForEdit() {
      return new Promise((resolve) => {
        const action = "getUserProposalForEdit";
        const dataParams = {
          proposalId: this.proposalId,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.proposal = resp.proposal;
              this.proposal.betamount = Math.trunc(+resp.proposal.betamount)
              this.currentAttach = resp.attachments ? resp.attachments : [];
            } else if (resp.result == "error") {
              this.$router.go(-1);
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    editProposal() {
      return new Promise((resolve) => {
        const action = "editProposal";
        const dataParams = {
          proposalId: this.proposalId,
          betamount: this.proposal.betamount,
          comment: this.proposal.comment,
          fileName: this.fileName,
          tenderId: this.proposal.tenderid,
          attach: this.attach,
        };
        const attach = this.attach;
        this.$store
          .dispatch("SendForm", { action, dataParams, attach })
          .then((resp) => {
            if (resp.id) {
              this.openDialogInfo("Изменения успешно сохранены", "back");
            } else if (resp.result == "error" && resp.error_msg == "time") {
              this.openDialogInfo("Срок действия объявления истек!", "back");
            }else {
              this.openDialogInfo("Редактирование невозможно");
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    closeProposal() {
      return new Promise((resolve) => {
        const action = "closeProposal";
        const dataParams = {
          proposalId: this.proposalId,
          tenderId: this.proposal.tenderid,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.$router.go(-1);
            } else {
              this.openDialogInfo("Редактирование невозможно");
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    confirmDelete(attachment) {
      this.openDialogConfirm(
        "Удалить файлы без возможности восстановления?",
        "removeCurrentAttach"
      );
      this.attachmentForDelete = attachment;
    },
    removeCurrentAttach() {
      this.dialog = false;
      return new Promise((resolve) => {
        const action = "removeCurrentAttach";
        const dataParams = {
          proposalId: this.proposalId,
          attachment: this.attachmentForDelete,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.attachments !== null) {
              this.currentAttach = resp.attachments;
            } else {
              this.currentAttach = [];
              if (this.newAttach.length < 1) this.addItemsAttach();
            }
            this.attachmentForDelete = "";
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    addItemsAttach() {
      if (this.currentAttach.length + this.attach.length < 5) {
        this.attach.push({});
      }
    },
    removeItemsAttach(i) {
      this.attach = [...this.attach.slice(0, i), ...this.attach.slice(i + 1)];
      if (!this.attach.length) {
        this.attach = [{}];
      }
    },
    back() {
      this.$router.go(-1);
    },
    formatNumber() {
      this.proposal.betamount = this.proposal.betamount? + this.proposal.betamount.replace(/[^0-9]/g, '') : this.proposal.betamount;
    },
  },
  mounted() {
    this.getUserProposalForEdit();
  },
  computed: {
    createBtnStatus() {
      if (
        (this.attach[0].file && !this.attach[0].name) ||
        (!this.attach[0].file && this.attach[0].name) ||
        (this.proposal.startamount - 1 < this.proposal.betamount)
      ) {
        return true;
      } else if (this.proposal.betamount) {
        return false;
      } else {
        return true;
      }
    },
    costpolicy() {
      return (this.proposal.betamount * 20) / 100;
    },
    betamountInsurLess() {
      return (this.proposal.betamount * 80) / 100;
    },
  },
};
</script>

<style scoped>
.date-picker {
  height: 48px;
}
.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}
.data_wrap {
  max-width: max-content;
}
</style>
