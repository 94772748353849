<template>
  <v-card outlined>
    <v-card-title class="px-9">
      <v-flex>Ограничения</v-flex>
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout wrap class="item_wrap">
            <v-flex xs12 sm3 class="mr-5">
              <v-autocomplete
                clearable
                label="Подрядчик"
                autocomplete
                v-model="contractorId"
                :items="contractors"
                item-text="name"
                item-value="id"
                :no-data-text="noDataText"
                outlined
                dense
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm3 class="mr-5">
              <v-autocomplete
                clearable
                label="Город"
                autocomplete
                v-model="cityId"
                :items="citiesList"
                item-text="name"
                item-value="id"
                :no-data-text="noDataText"
                outlined
                dense
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm3 class="mr-5">
              <v-autocomplete
                clearable
                label="Тип работ"
                autocomplete
                v-model="worktypeId"
                :items="worktypes"
                item-text="type"
                item-value="id"
                :no-data-text="noDataText"
                outlined
                dense
              ></v-autocomplete>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm2>
              <v-btn
                height="40px"
                class="btn_action"
                outlined
                color="primary"
                @click="addRestriction"
                >Создать</v-btn
              >
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex>
          <SearchRestriction
            :dataSearch="dataSearch"
            @data="searchData = $event"
          ></SearchRestriction>
        </v-flex>
        <v-flex class="mt-10">
          <TableRestriction
            :dataTable="dataTable"
            @updateTable="updateTable = $event"
          ></TableRestriction>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinRestriction";

export default {
  mixins: [Mixin],
  data() {
    return {
      contractors: [],
      menuType: "newtenders",
      cityId: "",
      worktypeId: "",
      contractorId: "",
      noDataText: "Нет данных",
      updateTable: false
    };
  },
  methods: {
    getContractors() {
      return new Promise(resolve => {
        const action = "getContractors";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.contractors = resp.contractors;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    addRestriction() {
      return new Promise(resolve => {
        const action = "addRestriction";
        const dataParams = {
          worktypeId: this.worktypeId,
          cityId: this.cityId,
          contractorId: this.contractorId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.getRestrictions();
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    dataTable() {
      return {
        restrictions: this.filteredItems,
        menuType: this.menuType
      };
    },
    dataSearch() {
      return {
        citiesListSearch: this.citiesListSearch,
        worktypesSearch: this.worktypesSearch
      };
    }
  },
  watch: {
    updateTable(v) {
      if (v) {
        this.getRestrictions();
        this.updateTable = false
      }
    }
  },
  mounted() {
    this.getRestrictions();
    this.getContractors();
    this.getCitiesList();
    this.getWorkTypes();
  }
};
</script>

<style scoped></style>
