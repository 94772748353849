var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":!_vm.dataTable.tenders,"headers":_vm.headersTableTenders,"sort-by":['id'],"sort-desc":[true],"items-per-page":_vm.searchData.pagination,"items":_vm.dataTable.tenders ? _vm.dataTable.tenders : [],"locale":"ru"},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.searchData, "pagination", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.searchData, "pagination", $event)},"click:row":_vm.goTo},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_id"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.orderid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_orderid"},[_vm._v(" "+_vm._s(item.orderid)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_name"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"chips_tender tender_status chip_text",attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.translateTenderStatus(item.status))+" ")])]}},{key:"item.startamount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right tender_startamount"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.startamount))+" ")])]}},{key:"item.proposals",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_proposals"},[_vm._v(" "+_vm._s(item.proposals)+" "),(item.proposalsnew)?_c('sup',{staticStyle:{"color":"mediumseagreen"}},[_c('b',[_vm._v(_vm._s(item.proposalsnew))])]):_vm._e()])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right tender_amount"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount))+" ")])]}},{key:"item.costpolicy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right tender_costpolicy"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.costpolicy))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_type"},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.startauctiondate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_startauctiondate"},[_vm._v(" "+_vm._s(_vm.formatDate(item.startauctiondate))+" ")])]}},{key:"item.endauctiondate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_endauctiondate"},[_vm._v(" "+_vm._s(_vm.formatDate(item.endauctiondate))+" ")])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_city"},[_vm._v(" "+_vm._s(item.city)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tender_action"},[((item.status == 'new' || item.status == 'rework') && _vm.role != 'admin')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_vm._v(" edit ")]):_vm._e(),(!item.workingproposals && _vm.$router.currentRoute.name == 'ClosedTendersOrganizer')?_c('v-icon',{staticClass:"mr-5",attrs:{"large":"","outlined":"","color":"green"},on:{"click":function($event){$event.stopPropagation();_vm.openDialogConfirm(
              'Присвоить объявлению статус \'доработать\'?',
              ("reopenTender(" + (item.id) + ")")
            )}}},[_vm._v(" arrow_circle_up ")]):_vm._e()],1)]}}])}),_c('Dialog',{attrs:{"dialogDataIn":_vm.dialogDataIn},on:{"dialogDataOut":function($event){_vm.dialogDataOut = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }