<template>
  <v-card outlined>
    <v-card-title>
      <v-layout column class="px-5">
        <v-flex class="align-self-end mb-5"></v-flex>
        <v-flex class="align-self-center mb-5">Мои заявки</v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <SearchMyProposals @data="searchData = $event"></SearchMyProposals>
        </v-flex>
        <v-flex class="mt-10">
          <TableMyProposals :dataTable="dataTable"></TableMyProposals>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinProposal";

export default {
  mixins: [Mixin],
  data() {
    return {
      tenderId: this.$route.query.tenderId,
      type: this.$route.query.type,
      tenderName: this.$route.query.name,
      tenderAddress: this.$route.query.address      
    };
  },
  methods: {
    getMyProposals() {
      return new Promise(resolve => {
        const action = "getMyProposals";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            //Сортируем массив по id в обратном порядке
            this.proposals = resp.proposals.reverse((a, b) =>
              a.proposalid > b.proposalid ? 1 : -1
            );
            this.filteredItems = resp.proposals;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    dataTable() {
      return {
        proposals: this.filteredItems,
        type: this.type
      };
    }    
  },
  mounted() {
    this.getMyProposals();
  }
};
</script>

<style scoped></style>
