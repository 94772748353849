<template>
  <v-card outlined>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout
            wrap
            class="justify-start"
          >
            <v-flex
              sx12
              sm4
              class=" wrap_column"
            >
              <v-layout
                column
                class="px-5"
              >
                <v-flex class="mt-5 px-0">Пользователь</v-flex>
                <v-flex class="text_field">
                  <v-text-field
                    v-model="searchData.users"
                    append-icon="search"
                    single-line
                    clearable
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="mt-10">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            @click:row="goTo"
            locale="ru"
          >           
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
    <Dialog
      @dialogDataOut="dialogDataOut = $event"
      :dialogDataIn="dialogDataIn"
    ></Dialog>
  </v-card>
</template>

<script>
import mixinDialog from '../../mixin/js/mixinDialog';

export default {
  mixins: [mixinDialog],
  data() {
    return {
      loader: null,
      loading: false,
      noDataText: 'Нет данных',
      users: [],
      searchData: {
        users: null
      },
      selectedUser: {},
      headers: [
        {
          text: 'Пользователи',
          align: 'start',
          sortable: false,
          value: 'login'
        },
        {
          text: 'Дата запроса',
          align: 'start',
          sortable: false,
          value: 'createddate'
        }       
      ]
    };
  },
  methods: {
    goTo(item) {
      this.$router.push({ path: '/admin/revokecard', query: { id: item.id } });
    },   
    getRevokeUserList() {
      this.loading = true;
      this.loader = true;
      return new Promise(resolve => {
        const action = 'getRevokeUserList';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.users = resp.users;          
            this.filteredItems = resp.users;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    filteredItems: {
      get() {
        return this.users.filter(i => {
          return (
            this.searchData.users === null ||
            i.login.toLowerCase().indexOf(this.searchData.users.toLowerCase()) != -1
          );
        });
      },
      set(v) {
        return v;
      }
    }
  },

  mounted() {
    this.getRevokeUserList();
  }
};
</script>

<style scoped>
.wrap_column {
  max-width: 280px !important;
}
</style>
