<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0">Заявка на регистрацию</v-card-title>
    <v-subheader v-if="!orgInfo && !usersInfo">Заявок нет.</v-subheader>
    <v-card-text class="px-0">
      <v-card
        outlined
        v-if="orgInfo && !orgInfo.orgstatus"
        class="px-5 mb-5"
        style="border-color: crimson"
      >
        <v-card-title class="px-0"
          ><v-flex>Организация "{{ orgInfo.name }}"</v-flex
          ><v-btn
            outlined
            color="primary"
            class="btn_action"
            @click="openDialogConfirm('Подтвердить регистрацию?', 'confirmOrg')"
            >Подтвердить</v-btn
          ></v-card-title
        >
        <v-list two-line>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{ orgInfo.inn }}</v-list-item-title>
              <v-list-item-subtitle>ИНН</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{ role }}</v-list-item-title>
              <v-list-item-subtitle>Роль</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{ orgStatus }}</v-list-item-title>
              <v-list-item-subtitle>Статус</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="orgInfo.role !== 'organizer' && orgInfo.role !== 'insurance'" class="px-0">
            <v-layout wrap class="justify-space-between">
              <v-flex sx12 sm7 d-flex class="align-center">
                <v-list-item-content>
                  <v-list-item-title>{{
                    uploadFilesInfo.filename
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Анкета</v-list-item-subtitle>
                </v-list-item-content>
              </v-flex>
              <v-flex sx12 sm3 d-flex class="align-center">
                <v-file-input
                  prepend-icon
                  type="file"
                  ref="doc"
                  v-model="attach"
                  label="Загрузить новые документы"
                  :rules="uploadRules"
                  accept=".rar, .zip, 7z"
                  hide-details
                  outlined
                  dense
                ></v-file-input>
              </v-flex>
              <v-flex sx12 sm2 d-flex class="btn_wrap align-center">
                <v-btn
                  :href="
                    uploadUrl + orgInfo.orgid + '/' + uploadFilesInfo.attachment
                  "
                  outlined
                  class="btn_action"
                  color="primary"
                  >скачать</v-btn
                >
              </v-flex>
            </v-layout>
          </v-list-item>
          <v-list-item v-if="orgInfo.role !== 'insurance'" class="px-0">
            <v-list-item-content>
              <v-flex v-if="permInfo.length">
                <v-list-item-subtitle>Допуск</v-list-item-subtitle>
                <v-treeview
                  v-model="permSelect"
                  selectable
                  return-object
                  selection-type="leaf"
                  selected-color="primary"
                  :items="permInfo"
                ></v-treeview>
              </v-flex>
              <v-flex v-else>
                <v-list-item-subtitle
                  >Нет запрошенных допусков</v-list-item-subtitle
                >
              </v-flex>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap class="justify-space-between">
              <v-flex sx12 sm7 d-flex class="align-center">
                <v-textarea
                  v-model="refuseOrgReason"
                  outlined
                  label="Причина отказа"
                  rows="2"
                  clearable
                  dense
                  no-resize
                  :rules="[v => !!v || 'Необходимо ввести причину']"
                ></v-textarea>
              </v-flex>
              <v-flex sx12 sm2 d-flex class="btn_wrap">
                <v-btn
                  outlined
                  class="btn_action"
                  color="primary"
                  @click="refuseOrg"
                  :disabled="!refuseOrgReason"
                  >Отказать</v-btn
                >
              </v-flex>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card
        outlined
        v-if="usersInfo"
        class="px-5"
        style="border-color: crimson"
      >
        <v-card-title class="px-0"
          ><v-flex>Пользователь "{{ usersInfo.login }}"</v-flex
          ><v-btn
            outlined
            color="primary"
            class="btn_action"
            @click="
              openDialogConfirm('Подтвердить регистрацию?', 'confirmUser')
            "
            :disabled="confirmBtnStatus"
            >Подтвердить</v-btn
          ></v-card-title
        >
        <v-list two-line>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title
                >{{ usersInfo.firstname }} {{ usersInfo.secondname }}
                {{ usersInfo.lastname }}</v-list-item-title
              >
              <v-list-item-subtitle>ФИО</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{ usersInfo.email }}</v-list-item-title>
              <v-list-item-subtitle>email</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{ userStatus }}</v-list-item-title>
              <v-list-item-subtitle>Статус</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-layout wrap class="justify-space-between">
              <v-flex sx12 sm7 d-flex class="align-center">
                <v-textarea
                  v-model="refuseUserReason"
                  outlined
                  label="Причина отказа"
                  rows="2"
                  clearable
                  dense
                  no-resize
                  :disabled="!this.orgInfo.orgstatus"
                  :rules="[v => !!v || 'Необходимо ввести причину']"
                ></v-textarea>
              </v-flex>
              <v-flex sx12 sm2 d-flex class="btn_wrap">
                <v-btn
                  outlined
                  class="btn_action"
                  color="primary"
                  @click="refuseUser"
                  :disabled="!this.orgInfo.orgstatus || !refuseUserReason"
                  >Отказать</v-btn
                >
              </v-flex>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-card>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        class="btn_action"
        :to="{
          path: '/admin/request/registration'
        }"
        >назад</v-btn
      >
    </v-card-actions>
    <Dialog
      @dialogDataOut="dialogDataOut = $event"
      :dialogDataIn="dialogDataIn"
    ></Dialog>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinDialog";
export default {
  mixins: [Mixin],
  data() {
    return {
      uploadFilesInfo: "",
      attach: "",
      uploadUrl: process.env.VUE_APP_UPLOADS_ORG_URL,
      refuseOrgReason: "",
      refuseUserReason: "",
      regId: this.$route.query.id,
      orgInfo: "",
      usersInfo: "",
      permInfo: [],
      permSelect: [],
      uploadRules: [
        v => !!v || "Необходимо загрузить документ",
        v => (v && v.size < 10000000) || "Не более 10 MB!"
      ]
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    //получаем данные
    getRegData() {
      return new Promise(resolve => {
        const action = "getRegData";
        const dataParams = {
          regId: this.regId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.orgInfo = resp.org;
            this.usersInfo = resp.users;
            //модификация массива разрешений для использования в checkbox
            this.permInfo = this.transformForCheckBox(resp.perm);
            this.uploadFilesInfo = resp.attachment;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    confirmOrg() {
      return new Promise(resolve => {
        const action = "confirmOrg";
        const dataParams = {
          orgId: this.orgInfo.orgid,
          regId: this.regId,
          permissions: this.permSelect,
          attachName: this.attach.name
        };
        const attach = this.attach;
        this.$store
          .dispatch("SendForm", { action, dataParams, attach })
          .then(resp => {
            this.getRegData();
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    confirmUser() {
      return new Promise(resolve => {
        const action = "confirmUser";
        const dataParams = {
          regId: this.regId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.getRegData();
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    refuseOrg() {
      return new Promise(resolve => {
        const action = "refuseOrg";
        const dataParams = {
          reason: this.refuseOrgReason,
          regId: this.regId,
          orgId: this.orgInfo.orgid,
          userId: this.usersInfo.userid
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.getRegData();
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    refuseUser() {
      return new Promise(resolve => {
        const action = "refuseUser";
        const dataParams = {
          reason: this.refuseUserReason,
          regId: this.regId,
          orgId: this.orgInfo.orgid,
          userId: this.usersInfo.userid
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.getRegData();
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    transformForCheckBox(array) {
      const newArray = [];
      let id = 0;
      array.forEach(itemCitylevel => {
        const city = { id: "", name: "", children: [] };
        city.id = ++id;
        city.name = itemCitylevel.cityname;
        array.forEach(itemPermLevel => {
          if (itemCitylevel.cityid == itemPermLevel.cityid) {
            //'-1' -  чтобы не пропускал индекс 0, worktypeid начинаются с 1
            city.children[itemPermLevel.worktypeid - 1] = {
              id: ++id,
              name: itemPermLevel.typename,
              typeId: itemPermLevel.worktypeid,
              cityId: itemCitylevel.cityid,
              cityName: itemCitylevel.cityname
            };
          }
          this.$set(newArray, []);
          newArray[itemCitylevel.cityid - 1] = city;
        });
      });
      return newArray;
    }
  },
  computed: {
    confirmBtnStatus() {
      let status = true;
      if (this.orgInfo && this.orgInfo.orgstatus) {
        status = false;
      } else if (!this.orgInfo) {
        status = false;
      }
      return status;
    },
    //     orgCardShow() {
    // if (this.orgInfo &&)
    //     },
    orgStatus() {
      let status = "";
      if (this.orgInfo.orgstatus) {
        status = "Активна";
      } else if (!this.orgInfo.orgstatus) {
        status = "Ожидает подтверждения";
      }
      return status;
    },
    userStatus() {
      let status = "";
      if (this.usersInfo.userstatus) {
        status = "Активен";
      } else if (!this.usersInfo.userstatus) {
        status = "Ожидает подтверждения";
      }
      return status;
    },
    role() {
      let role = "";
      if (this.orgInfo.role == "contractor") {
        role = "Подрядчик";
      } else if (this.orgInfo.role == "organizer") {
        role = "Заказчик работ";
      }else if (this.orgInfo.role == "insurance") {
        role = "Страховая компания";
      }
      return role;
    }
  },
  mounted() {
    this.getRegData();
  }
};
</script>

<style scoped>
.btn_wrap {
  justify-content: flex-end;
}
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
</style>
