<template>
  <v-card outlined>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout wrap class="justify-start">
            <v-flex sx12 sm4 class=" wrap_column">
              <v-layout column class="px-5">
                <v-flex class="mt-5 px-0">Организация</v-flex>
                <v-flex class="text_field">
                  <v-select
                    :items="organizations"
                    item-value="name"
                    item-text="name"
                    v-model="searchOrg"
                    single-line
                    hide-details
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex d-flex class="justify-end">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="ma-2 white--text"
                outlined
                fab
                small
                @click="getRequestPerm"
              >
                <v-icon dark>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="mt-10">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            @click:row="goTo"
            locale="ru"
          >
            <template v-slot:item.type="{ item }">
              <v-chip class="chips_tender" :color="getColor(item)" outlined>{{
                getRegType(item)
              }}</v-chip>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loader: null,
      loading: false,
      requestPermOrgs: [],
      noDataText: "Нет данных",
      requestReg: [],
      organizations: [],
      searchOrg: "все",
      headers: [
        {
          text: "Организация",
          align: "left",
          sortable: false,
          value: "name"
        }
      ]
    };
  },
  methods: {
    goTo(item) {
      this.$router.push({ path: "/admin/permcard", query: { id: item.id } });
    },
    getRequestPerm() {
      this.loading = true;
      this.loader = true;
      return new Promise(resolve => {
        const action = "getRequestPerm";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.requestPermOrgs = resp.requestPermOrgs;
            this.filteredItems = resp.requestPermOrgs;
            this.loading = false;
            this.loader = null;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getOrgWithoutAdmin() {
      return new Promise(resolve => {
        const action = "getOrgWithoutAdmin";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            // this.organizations = resp.organizations;
            //Для поиска добавляем элемент "все"
            this.organizations = [
              { id: 0, name: "все" },
              ...resp.organizations
            ];
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    filteredItems: {
      get() {
        return this.requestPermOrgs.filter(i => {
          return (
            this.searchOrg === "все" ||
            i.name.toLowerCase().indexOf(this.searchOrg.toLowerCase()) != -1
          );
        });
      },
      set(v) {
        return v;
      }
    }
  },

  mounted() {
    this.getOrgWithoutAdmin();
    this.getRequestPerm();
  }
};
</script>

<style scoped></style>
