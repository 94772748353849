<template>
  <v-card outlined class="px-5" height="100%">
    <v-card-title class="px-0">Подтверждение изменений</v-card-title>
    <v-subheader v-if="!data">Изменений нет.</v-subheader>
    <v-card-text class="px-0">
      <v-data-table :headers="headers" :items="data" class="elevation-1">
        <template v-slot:item.title="{ item }">
          {{ translateName(item.title) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            color="green"
            large
            outlined
            class="mr-2"
            @click="confirmChange(item.title)"
          >
            check_circle
          </v-icon>
          <v-icon
            class="mr-5"
            large
            outlined
            color="primary"
            @click="(dialog = true), (currentField = item.title)"
          >
            cancel
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        class="btn_action"
        :to="{
          path: '/admin/request/editProfile',
        }"
        >назад</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          Укажите причину отказа.
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="refuseReason"
            outlined
            rows="2"
            clearable
            dense
            no-resize
            :rules="[(v) => !!v || 'Необходимо ввести причину']"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            отмена
          </v-btn>
          <v-btn
            :disabled="!refuseReason"
            color="primary"
            text
            @click="refuseChange()"
          >
            подтвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import Mixin from "../mixin/js/mixinDialog";
export default {
  // mixins: [Mixin],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_ORG_URL,
      currentField: "",
      refuseReason: "",
      orgId: this.$route.query.id,
      data: [],
      headers: [
        {
          text: "Наименование поля",
          align: "left",
          sortable: false,
          value: "title",
          width: "250px",
        },
        {
          text: "Текущее значение",
          align: "center",
          sortable: false,
          value: "current",
        },
        {
          text: "Новое значение",
          align: "center",
          sortable: false,
          value: "edit",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      dialog: false,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    //получаем данные
    getEditData() {
      return new Promise((resolve) => {
        const action = "getEditData";
        const dataParams = {
          orgId: this.orgId,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            const edit = resp.edit;
            const current = resp.current;
            this.data = [];
            for (let key in edit) {
              if (key != "orgid" && edit[key]) {
                this.data.push({
                  title: key,
                  current: current[key],
                  edit: edit[key],
                });
              }
            }

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    confirmChange(field) {
      return new Promise((resolve) => {
        const action = "confirmChange";
        const dataParams = {
          orgId: this.orgId,
          field,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.getEditData();
            }

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    refuseChange() {
      this.dialog = !this.dialog;
      return new Promise((resolve) => {
        const action = "refuseChange";
        const dataParams = {
          orgId: this.orgId,
          field: this.currentField,
          reason: this.refuseReason,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.getEditData();
              this.refuseReason = "";
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    translateName(name) {
      switch (name) {
        case "kpp":
          return "КПП";
        case "ogrn":
          return "ОГРН/ОГРНИП";
        case "account":
          return "Расчетный счет";
        case "corr":
          return "Корреспондентский счет";
        case "bankname":
          return "Наименование банка";
        case "bic":
          return "БИК";
        case "manager":
          return "ФИО руководителя";
        case "web":
          return "Web-сайт";
        case "phone":
          return "Телефон";
        case "address":
          return "Адрес";
        case "name":
          return "Наименование";
        case "inn":
          return "ИНН";
      }
    },
  },
  computed: {},
  mounted() {
    this.getEditData();
  },
};
</script>

<style scoped>
.btn_wrap {
  justify-content: flex-end;
}
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.btn-return {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
}
</style>
