<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12>
        <v-card max-width="500" class="ma-auto" outlined>
          <v-card-text>
          <v-layout column v-if="userId">
            <v-flex b class="text">Вы успешно подтвердили свою почту.</v-flex>
            <v-flex class="text">Ваша заявка на регистрацию на рассмотрении администратора.</v-flex>
            <v-flex class="text">Ожидайте письмо с результатом рассмотрения заявки.</v-flex>
          </v-layout> 
          <v-layout v-else>
            <v-flex class="text">Ошибка подтверждения почты</v-flex>            
          </v-layout> 
          </v-card-text>          
        </v-card>
      </v-flex>
    </v-layout>   
  </v-container>
</template>
<script>
export default {
  data() {
    return {  
       userId: this.$route.query.id,   
    };
  },
  methods: { 
  },
  mounted() {
    setTimeout(()=>{this.$router.push('/')}, 5000);
  }
};
</script>
<style scoped>
.text {
  text-align: center;
}
</style>
