<template>
  <v-card outlined>
    <v-card-text>
      <v-layout wrap class="justify-between">
        <v-flex xs12 sm6>
          <v-autocomplete
            class="filter_style"
            clearable
            label="Заказчик"
            autocomplete
            v-model="searchData.organizer"
            :items="organizers"
            item-text="name"
            item-value="name"
            :no-data-text="noDataText"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 sm6 class="text-end mt-4">
          <v-btn outlined color="primary">
            <DownloadExcel
              :data="filtredTenders"
              :fields="json_headers"
              worksheet="My Worksheet"
              name="report.xls"
              >Сохранить</DownloadExcel
            ></v-btn
          >
        </v-flex>
      </v-layout>
      <v-flex class="mt-10">
        <v-data-table
          :headers="headers"
          :items="filtredTenders"
          :items-per-page.sync="searchData.pagination"
          locale="ru"
        >
         <template v-slot:item.startamount="{ item }">
            {{ formatCurrency(item.startamount) }}
          </template>
           <template v-slot:item.winamount="{ item }">
            {{ formatCurrency(item.winamount) }}
          </template>
        </v-data-table>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import Lib from "../../mixin/js/mixinLib";

export default {
  mixins: [Lib],
  data() {
    return {
      noDataText: "Нет данных",
      searchData: this.$store.getters[`${this.$router.currentRoute.name}`],
      tenders: [],
      organizers: [],
      json_headers: {
        "№ п/п": "id",
        "Номер объявления": "tenderid",
        Заказчик: "organizer",
        Город: "city",
        Объем: "volume",
        "Статус срока выполнения": "urgency",
        "Стоимость работ": "startamount",
        "Дата размещения на ДКП": "startauctiondate",
        "Дата окончания публикации": "endauctiondate",
        "Дата начала работ": "startworkdate",
        "Дата завершения работ": "endworkdate",
        "Сумма предложенная ПО": "winamount",
        "Дата повторной публикации": "publishdatetwo",
        "Дата окончания повторной публикации": "unpublishdatetwo",
        "Дата третьей публикации": "publishdatetree",
      },
      headers: [
        {
          text: "№ п/п",
          align: "left",
          value: "id",
          sortable: false,
        },
        {
          text: "Номер объявления",
          align: "right",
          value: "tenderid",
          sortable: false,
        },
        { text: "Заказчик", value: "organizer", sortable: false },
        { text: "Город", value: "city", sortable: false },
        { text: "Тип работ", value: "type", sortable: false },
        { text: "Объем", value: "volume", sortable: false },
        { text: "Статус срока выполнения", value: "urgency", sortable: false },
        { text: "Стоимость работ", value: "startamount", sortable: false },
        {
          text: "Дата размещения на ДКП",
          value: "startauctiondate",
          sortable: false,
        },
        {
          text: "Дата окончания публикации",
          value: "endauctiondate",
          sortable: false,
        },
        { text: "Дата начала работ", value: "startworkdate", sortable: false },
        {
          text: "Дата завершения работ",
          value: "endworkdate",
          sortable: false,
        },
        { text: "Сумма предложенная ПО", value: "winamount", sortable: false },
        {
          text: "Дата повторной публикации",
          value: "publishdatetwo",
          sortable: false,
        },
        {
          text: "Дата окончания повторной публикации",
          value: "unpublishdatetwo",
          sortable: false,
        },
        {
          text: "Дата третьей публикации",
          value: "publishdatetree",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getAnalyticalReport() {
      return new Promise((resolve) => {
        const action = "getAnalyticalReport";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.tenders = resp.tenders;
            this.tenders.map((item) => {
              item.urgency = this.getUrgency(item.urgency);
              item.startauctiondate = this.formatDate(item.startauctiondate);
              item.endauctiondate = this.formatDate(item.endauctiondate);
              item.startworkdate = this.formatDate(item.startworkdate);
              item.endworkdate = this.formatDate(item.endworkdate);
              item.publishdatetwo = this.formatDate(item.publishdatetwo);
              item.unpublishdatetwo = this.formatDate(item.unpublishdatetwo);
              item.publishdatetree = this.formatDate(item.publishdatetree);
            });
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getOrganizersAdmin() {
      return new Promise((resolve) => {
        const action = "getOrganizersAdmin";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.organizers = resp.organizers;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    formatDate(data) {
      if (!data) return null;
      const date = data.slice(0, 10);
      // const time = data.slice(11, 19);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getUrgency(urgency) {
      switch (urgency) {
        case "non-urgent":
          return "Не срочно";
        case "urgent":
          return "Срочно";
      }
    },
  },
  computed: {
    filtredTenders: {
      get() {
        return this.tenders.filter((i) => {
          return (
            !this.searchData.organizer ||
            i.organizer
              .toLowerCase()
              .indexOf(this.searchData.organizer.toLowerCase()) != -1
          );
        });
      },
      set(v) {
        return v;
      },
    },
  },
  mounted() {
    this.getOrganizersAdmin();
    this.getAnalyticalReport();
  },
};
</script>
<style scoped>
.filter_style {
  max-width: 280px !important;
}
</style>
