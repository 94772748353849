<template>
  <v-card outlined>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout wrap class="justify-start">
            <v-flex sx12 sm4 class=" wrap_column">
              <v-layout column class="px-5">
                <v-flex class="mt-5 px-0">Организация</v-flex>
                <v-flex class="text_field">
                  <v-text-field
                    v-model="searchData.organizations"
                    append-icon="search"
                    single-line
                    clearable
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sx12 sm4 class="wrap_column">
              <v-layout column class="px-5">
                <v-flex class="mt-5 px-0">Тип заявки</v-flex>
                <v-flex class="text_field">
                  <v-select
                    :items="requestTypeSearch"
                    item-value="type"
                    item-text="type"
                    v-model="searchData.requestType"
                    single-line
                    hide-details
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex d-flex class="justify-end">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="ma-2 white--text"
                outlined
                fab
                small
                @click="getRequestReg"
              >
                <v-icon dark>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="mt-10">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            @click:row="goTo"
            locale="ru"
          >
            <template v-slot:item.type="{ item }">
              <v-chip class="chips_tender" :color="getColor(item)" outlined>{{
                getRegType(item)
              }}</v-chip>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import "../../mixin/css/tender.css";
import "../../mixin/css/style.css";

export default {
  data() {
    return {
      loader: null,
      loading: false,
      noDataText: "Нет данных",
      requestReg: [],
      requestTypeSearch: ["все", "пользователь", "организация"],
      organizations: [],
      requestType: [],
      searchData: {
        organizations: null,
        requestType: "все"
      },
      headers: [
        {
          text: "Организация",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Тип",
          sortable: false,
          value: "type"
        }
      ]
    };
  },
  methods: {
    goTo(item) {
      this.$router.push({ path: "/admin/regcard", query: { id: item.regid } });
    },
    getRequestReg() {
      this.loading = true;
      this.loader = true;
      return new Promise(resolve => {
        const action = "getRequestReg";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.requestReg = resp.requestreg;
            this.filteredItems = resp.requestreg;
            this.loading = false;
            this.loader = null;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },    
    getColor(item) {
      if (!item.userstatus && !item.orgstatus) {
        return "blue";
      } else if (!item.userstatus && item.orgstatus) {
        return "green";
      }
    },
    getRegType(item) {
      if (!item.userstatus && !item.orgstatus) {
        return "Организация";
      } else if (!item.userstatus && item.orgstatus) {
        return "Пользователь";
      } else {
        return "Ошибка типа регистрации";
      }
    }
  },
  computed: {
    filteredItems: {
      get() {
        return this.requestReg
          .filter(i => {
            return (
              this.searchData.organizations === null ||
              i.name
                .toLowerCase()
                .indexOf(this.searchData.organizations.toLowerCase()) != -1
            );
          })
          .filter(i => {
            return (
              this.searchData.requestType === "все" ||
              this.getRegType(i)
                .toLowerCase()
                .indexOf(this.searchData.requestType.toLowerCase()) != -1
            );
          });
      },
      set(v) {
        return v;
      }
    }
  },

  mounted() {
    this.getRequestReg();
  }
};
</script>

<style scoped>
.wrap_column {
  max-width: 280px !important;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
