<template>
  <v-card outlined>
    <v-card-title>
      Зачем необходимо страхование?
    </v-card-title>
    <v-card-text class="py-1">
      Согласно части 1 статьи 742 Гражданского кодекса РФ договором
      строительного подряда может быть предусмотрена обязанность стороны, на
      которой лежит риск случайной гибели или случайного повреждения объекта
      строительства, материала, оборудования и другого имущества, используемых
      при строительстве, либо ответственность за причинение при осуществлении
      строительства вреда другим лицам, застраховать соответствующие риски.
    </v-card-text>
    <v-card-text class="py-1">
      Для обеспечения финансовой защищенности всех участников площадки, во все
      заключаемые на площадке договоры подряда включается условие о страховании
      рисков.
    </v-card-text>
    <v-card-title>
      Какие риски подлежат страхованию?
    </v-card-title>
    <v-card-subtitle class="subtitle-1 font-weight-medium font-italic">
      • страхование ответственности подрядчика при производстве
      строительно-монтажных работ
    </v-card-subtitle>
    <v-card-text class="py-1">
      Страхованию подлежит ответственность Подрядчика, которая выражается в его
      обязанности возместить, причиненный вред жизни, здоровью или имуществу
      третьих лиц в результате небрежности, ошибки, допущенных при производстве
      строительно-монтажных работ или при проведении работ по гарантийному
      обслуживанию.
    </v-card-text>
    <v-card-subtitle class="subtitle-1 font-weight-medium font-italic">
      • страхование послепусковых гарантийные обязательств
    </v-card-subtitle>
    <v-card-text class="py-1">
      Страхованию подлежат непредвиденные расходы Подрядчика, обусловленные
      повреждением или гибелью сданных в эксплуатацию объектов, произошедших в
      результате или явившихся следствием:
    </v-card-text>
    <v-card-text class="py-1">
      - недостатков, ошибок или упущений, допущенных Подрядчиком в период
      послепусковых гарантийные обязательств при выполнении работ по
      гарантийному обслуживанию сданного в эксплуатацию объекта;
    </v-card-text>
    <v-card-text class="py-1">
      - недостатков, ошибок или упущений, допущенных Подрядчиком в период
      строительства, монтажа или испытаний до подписания акта приемки
      законченного строительством объекта, но выявленных в период послепусковых
      гарантийные обязательств.
    </v-card-text>
    <v-card-text class="py-1">
      Страхование рисков осуществляется строго в соответствии с Правилами
      страхования.
    </v-card-text>
    <v-card-title>
      Страховые суммы и лимиты ответственности, срок страхования
    </v-card-title>
    <v-card-text class="py-1">
      По страхованию гражданской ответственности страховая сумма устанавливается
      в размере полной сметной стоимости, а период действия страхового полиса
      равен сроку производства работ в соответствии с календарным графиком.
    </v-card-text>
    <v-card-text class="py-1">
      По страхованию гарантийных обязательств страховая сумма также равна полной
      сметной стоимости, а период действия страхового полиса устанавливается на
      срок гарантийных обязательств в соответствии с требованиями заказчика (36
      месяцев).
    </v-card-text>
    <v-card-title>
      Кто вправе получить возмещение при возникновении страхового случая?
    </v-card-title>
    <v-card-text class="py-1"
      >Выгодоприобретателями по страхованию гражданской ответственности являются
      пострадавшие третьи лица, а по страхованию гарантийных обязательств
      выгодоприобретателем является подрядчик. По всем видам рисков
      устанавливается обязательная франшиза (неоплачиваемая часть
      убытка).</v-card-text
    >
    <v-card-title>
      Сложно ли оформить страховой полис?
    </v-card-title>
    <v-card-text class="py-1">
      При заключении договора подряда оформляется полис страхования
      ответственности. Оплата полиса производится организатором торгов от имени
      победителя торгов.
    </v-card-text>
    <v-card-text class="py-1">
      Мы работаем только с проверенными и надежными страховыми компаниями, все
      компании, представленные на нашей площадке, проходят проверку и
      аккредитацию. Для удобства и экономии времени, мы автоматизировали
      процессы оформления договора подряда и страхового полиса. Формирование
      договора страхования (страхового полиса) на площадке происходит
      автоматически для участника, признанного победителем, и подписывается
      последним одновременно с заключением договора подряда
    </v-card-text>
    <v-card-title>
      «Партнеры»
    </v-card-title>
    <v-card-text>
      Партнер площадки "Престиж-Полис"
    </v-card-text>
    <v-layout class="logo_wrap">
      <a :href="'https://vip-polis.ru/'">
        <v-img
          class="white--text align-end policy_logo"
          src="/assets/logo-V-gold.png"
        ></v-img>
      </a>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.policy_logo {
  /* padding: 10px; */
  max-width: 200px;
  /* margin: auto; */
}
.logo_wrap {
  justify-content: center;
  padding: 10px;
}
</style>
