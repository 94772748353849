<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Фильтр
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-layout wrap class="justify-start">
          <v-flex sx12 sm6 md3 class="wrap_column">
            <v-layout column class="px-5">
              <v-flex class="mt-5 px-0">Адрес</v-flex>
              <v-flex class="text_field">
                <v-text-field
                  v-model="searchData.city"
                  append-icon="search"
                  label="Город"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
              <!-- <v-flex class="text_field">
          <v-text-field
            v-model="searchData.street"
            append-icon="search"
            label="Улица"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
        <v-flex class="text_field">
          <v-text-field
            v-model="searchData.homenumber"
            append-icon="search"
            label="Дом"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex> -->
              <v-flex class="text_field">
                <v-text-field
                  v-model="searchData.orderId"
                  hide-details
                  clearable
                  label="Номер заказа на работы"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-flex class="text_field">
                  <v-text-field
                    v-model="searchData.number"
                    hide-details
                    clearable
                    type="number"
                    label="Номер объявления"
                  ></v-text-field>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sx12 sm6 md3 class="wrap_column">
            <v-layout column class="px-5">
              <v-flex class="mt-5 px-0">Срок объявления</v-flex>
              <v-flex>
                <v-layout wrap>
                  <v-flex xs12 class="date-picker text_field">
                    <v-menu
                      v-model="menuStart"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          v-model="start_date"
                          label="Начало"
                          prepend-inner-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="searchData.start_dateISO"
                        locale="rus"
                        no-title
                        @input="menuStart = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 class="date-picker text_field">
                    <v-menu
                      v-model="menuEnd"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          v-model="end_date"
                          label="Окончание"
                          prepend-inner-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="searchData.end_dateISO"
                        locale="rus"
                        no-title
                        @input="menuEnd = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 class="text_field">
                    <v-text-field
                      v-model="searchData.name"
                      append-icon="search"
                      label="Название объявления"
                      single-line
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sx12 sm6 md3 class="wrap_column">
            <v-layout column class="px-5">
              <v-flex class="mt-5 px-0">Тип работ</v-flex>
              <v-flex>
                <v-layout wrap>
                  <v-flex class="text_field">
                    <v-select
                      :items="worktypesSearch"
                      item-value="type"
                      item-text="type"
                      v-model="searchData.type"
                      single-line
                      hide-details
                    ></v-select>
                  </v-flex>
                  <v-flex class="text_field">
                    <v-text-field
                      v-model="searchData.amount"
                      hide-details
                      clearable
                      type="number"
                      label="Стоимость работ"
                    ></v-text-field>
                    <v-slider
                      v-model="searchData.amount"
                      class="align-center slider_wrap mt-8"
                      :max="max"
                      :min="min"
                      hide-details
                    ></v-slider>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sx12 sm6 md3 class="wrap_column">
            <v-layout column class="px-5">
              <v-flex class="mt-5 px-0">Начальная стоимость работ</v-flex>
              <v-flex>
                <v-layout wrap>
                  <v-flex class="text_field">
                    <v-text-field
                      v-model="searchData.startamount"
                      hide-details
                      clearable
                      type="number"
                    ></v-text-field>
                    <v-slider
                      v-model="searchData.startamount"
                      class="align-center slider_wrap mt-8"
                      :max="max"
                      :min="min"
                      hide-details
                    ></v-slider>
                  </v-flex>
                  <v-flex xs12 class="text_field" v-if="showSwitchNewProposal">
                    <v-switch v-model="searchData.newProposal" label="Новые заявки"></v-switch>
                  </v-flex>
                  <v-flex class="text_field">
                    <v-select
                      :items="statusSearch"
                      item-value="status"
                      item-text="title"
                      v-model="searchData.status"
                      single-line
                      hide-details
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Mixin from '../../mixin/js/mixinTender';

export default {
  mixins: [Mixin],
  data() {
    return {
      worktypesSearch: [],
      statusSearch: [],
      // start_date: null,
      // end_date: null,
      menuStart: false,
      menuEnd: false,
      min: 0,
      max: 200000,
      number: ''
    };
  },
  methods: {
    getWorkTypes() {
      return new Promise(resolve => {
        const action = 'getWorkTypes';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.worktypes = resp.worktypes;
            //Для поиска добавляем элемент "все"
            this.worktypesSearch = [{ id: 0, type: 'все' }, ...resp.worktypes];
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getTenderStatuses() {
      return new Promise(resolve => {
        const action = 'getTenderStatuses';
        const dataParams = {};
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.statuses = resp.statuses;
            this.statusSearch = resp.statuses.map(item => {
              return { status: item.status, title: this.translateTenderStatus(item.status) };
            });

            //Для поиска добавляем элемент "все"
            this.statusSearch.unshift({ status: 'all', title: 'все статусы' });
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    showSwitchNewProposal() {
      if (
        this.$router.currentRoute.name == 'newtenders' ||
        this.$router.currentRoute.name == 'newtenders'
      ) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.getWorkTypes();
    this.getTenderStatuses();
  }
};
</script>
<style>
.wrap_column {
  max-width: 280px !important;
}

.v-slider--horizontal {
  margin: 0 !important;
}

.slider_field {
  width: 100% !important;
}

.slider_wrap {
  flex-wrap: wrap;
}

.text_field {
  max-width: 240px !important;
}

.date-picker {
  height: 48px;
}

.subheader_sum {
  height: auto;
  margin-top: 10px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: number-input;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
