import "../css/proposal.css";
import "../css/style.css";

export default {
  data() {
    return {
      searchData: this.$store.getters[`${this.$router.currentRoute.name}`],
      proposals: [],
      statusproposal: [
        { type: "all", name: "все" },
        { type: "new", name: "новая" },
        { type: "work", name: "в работе" },
        { type: "won", name: "выигрыш" },
        { type: "loss", name: "проигрыш" },
        { type: "closed", name: "закрыта" },
        { type: "rejected", name: "отказано" },
      ],
      headersTableProposals: [
        {
          text: "Подрядчик",
          align: "left",
          sortable: false,
          value: "orgname",
        },
        { text: "Стоимость работ", value: "betamount", align: "center", sortable: false },
        { text: "Статус", value: "status", align: "center" },
        { text: "Действие", align: "right", value: "action", sortable: false },
      ],
      headersTableMyProposals: [
        {
          text: "Название объявления",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Стоимость работ", value: "betamount", sortable: false, align: "right" },
        { text: "Статус", value: "status", align: "center" },
        { text: "Действие", align: "right", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    start_date: {
      get() {
        return this.formatDate(this.searchData.start_dateISO);
      },
      set(v) {
        this.searchData.start_dateISO = v;
        return v;
      },
    },
    end_date: {
      get() {
        return this.formatDate(this.searchData.end_dateISO);
      },
      set(v) {
        this.searchData.end_dateISO = v;
        return v;
      },
    },
    filteredItems: {
      get() {
        if (this.proposals) {
          return this.proposals
            .filter((i) => {
              return (
                this.searchData.name === null ||
                i.name
                  .toLowerCase()
                  .indexOf(this.searchData.name.toLowerCase()) != -1
              );
            })
            .filter((i) => {
              return (
                this.searchData.status === "all" ||
                i.status
                  .toLowerCase()
                  .indexOf(this.searchData.status.toLowerCase()) != -1
              );
            })
            .filter((i) => {
              return (
                this.searchData.betamount === 0 ||
                i.betamount >= this.searchData.betamount
              );
            })
            .filter((i) => {
              return (
                this.searchData.organisationname === null ||
                i.orgname
                  .toLowerCase()
                  .indexOf(this.searchData.organisationname.toLowerCase()) != -1
              );
            });
        }
      },
      set(v) {
        return v;
      },
    },
  },
  methods: {
    getColor(status) {
      switch (status) {
        case "new":
          return "blue";
        case "work":
          return "orange";
        case "won":
          return "green";
        case "loss":
          return "cadetblue";
        case "closed":
          return "grey";
        case "rejected":
          return "red";
      }
    },
    translateProposalStatus(status) {
      switch (status) {
        case "new":
          return "новая";
        case "work":
          return "в работе";
        case "won":
          return "выигрыш";
        case "loss":
          return "проигрыш";
        case "closed":
          return "закрыта";
        case "rejected":
          return "отказано";
      }
    },
  },
};
