"use strict";
import Axios from "axios";
import store from "@/store";
export default {
  actions: {
    login(context, params) {
      return new Promise(resolve => {
        const pr = new FormData();
        pr.append("action", params.action);
        pr.append("params", JSON.stringify(params.dataParams));
        pr.append("register", true);
        pr.append("files[]", params.dataParams.attach);
        Axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL,
          data: pr,
          headers: {
            "content-type": "multipart/form-data"
          }
        })
          .then(resp => {
            resolve(resp.data);
          })
          .catch(err => {
            store.commit("kickoff");
            console.log(err);
            alert("Ошибка входа");
          });
      });
    }
  }
};
