import '../css/tender.css';
import '../css/style.css';

export default {
  data() {
    return {
      //Определяем содержание searchData
      //В зависимости от текущего роута загружаем соответвующий массив из store
      //так как это getters, то возвращается computed реактивное свойство со своими геттерами и сеттерами
      searchData: this.$store.getters[`${this.$router.currentRoute.name}`],
      tenders: null,
      headersTableTenders: [
        {
          text: 'Номер',
          sortable: true,
          align: 'left',
          value: 'id'
        },
        {
          text: 'Название',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Статус',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Начальная стоимость',
          sortable: true,
          value: 'startamount',
          align: 'right'
        },
        {
          text: 'Стоимость работ',
          sortable: true,
          value: 'amount',
          align: 'right'
        },
        { text: 'Тип работ', sortable: true, value: 'type' },
        {
          text: 'Дата начала',
          sortable: true,
          value: 'startauctiondate'
        },
        {
          text: 'Дата окончания',
          sortable: true,
          value: 'endauctiondate'
        },
        {
          text: 'Город',
          sortable: true,
          value: 'city'
        },
        { text: 'Действие', align: 'right', value: 'action', sortable: false }
      ],
      statustender: [
        { type: 'new', name: 'новый' },
        { type: 'ready', name: 'готов' },
        { type: 'rework', name: 'доработать' },
        { type: 'publish', name: 'опубликован' },
        { type: 'closed', name: 'закрыт' },
        { type: 'cancel', name: 'отмена' }
      ]
    };
  },
  computed: {
    start_date: {
      get() {
        return this.formatDate(this.searchData.start_dateISO);
      },
      set(v) {
        this.searchData.start_dateISO = v;
        return v;
      }
    },
    end_date: {
      get() {
        return this.formatDate(this.searchData.end_dateISO);
      },
      set(v) {
        this.searchData.end_dateISO = v;
        return v;
      }
    },
    filteredItems: {
      get() {
        if (!this.tenders) return null;

        return (
          this.tenders
            //Фильтр по наименованию объявления
            .filter(i => {
              return (
                this.searchData.name === null ||
                i.name.toLowerCase().indexOf(this.searchData.name.toLowerCase()) != -1
              );
            })
            //Фильтр по городу
            .filter(i => {
              return (
                this.searchData.city === null ||
                i.city.toLowerCase().indexOf(this.searchData.city.toLowerCase()) != -1
              );
            })
            //Фильтр по улице
            .filter(i => {
              return (
                this.searchData.street === null ||
                i.street.toLowerCase().indexOf(this.searchData.street.toLowerCase()) != -1
              );
            })
            //Фильтр по номеру дома
            .filter(i => {
              return (
                this.searchData.homenumber === null ||
                i.homenumber.toLowerCase().indexOf(this.searchData.homenumber.toLowerCase()) != -1
              );
            })
            //Фильтр по типу работ
            .filter(i => {
              return (
                this.searchData.type === 'все' ||
                i.type.toLowerCase().indexOf(this.searchData.type.toLowerCase()) != -1
              );
            })
            //Фильтр по сумме
            .filter(i => {
              return this.searchData.amount === 0 || i.amount >= this.searchData.amount;
            })
            //Фильтр по начальной стоимости работ
            .filter(i => {
              return (
                this.searchData.startamount === 0 || i.startamount >= this.searchData.startamount
              );
            })
            //Фильтр по номеру объявления
            .filter(i => {
              return !this.searchData.number || i.id == this.searchData.number;
            })
            //Фильтр по номеру заказа на работы
            .filter(i => {
              return (
                this.searchData.orderId === null ||
                i.orderid.toLowerCase().indexOf(this.searchData.orderId.toLowerCase()) != -1
              );
            })
            //Фильтр по дате
            .filter(i => {
              return (
                (this.searchData.start_dateISO === null && this.searchData.end_dateISO === null) ||
                (this.parseDateToUnix(i.startauctiondate) >=
                  this.parseDateToUnix(this.searchData.start_dateISO) &&
                  this.searchData.end_dateISO === null) ||
                (this.searchData.start_dateISO === null &&
                  this.parseDateToUnix(i.endauctiondate) <=
                    this.parseDateToUnix(this.searchData.end_dateISO)) ||
                (this.parseDateToUnix(i.startauctiondate) >=
                  this.parseDateToUnix(this.searchData.start_dateISO) &&
                  this.parseDateToUnix(i.endauctiondate) <=
                    this.parseDateToUnix(this.searchData.end_dateISO))
              );
            })
            //Фильтр по новым заявкам
            .filter(i => {
              return (
                !this.searchData.newProposal || (this.searchData.newProposal && i.proposalsnew)
              );
            })
            //Фильтр по статусу
            .filter(i => {
              return (
                this.searchData.status === 'all' ||
                i.status.toLowerCase().indexOf(this.searchData.status.toLowerCase()) != -1
              );
            })
        );
      },
      set(v) {
        return v;
      }
    }
  },
  methods: {
    getColor(status) {
      switch (status) {
        case 'new':
          return 'blue';
        case 'ready':
          return 'red';
        case 'rework':
          return 'yellow';
        case 'publish':
          return 'green';
        case 'closed':
          return 'grey';
        case 'cancel':
          return 'brown';
        case 'await':
          return 'purple';
        case 'done':
          return 'orange';
        case 'incomplete':
          return 'pink';
        case 'confirmDone':
          return 'lightpink';
      }
    },
    translateTenderStatus(status) {
      switch (status) {
        case 'new':
          return 'новый';
        case 'ready':
          return 'готов';
        case 'rework':
          return 'доработать';
        case 'publish':
          return 'опубликован';
        case 'closed':
          return 'закрыт';
        case 'cancel':
          return 'отмена';
        case 'await':
          return 'ожидает выполнения';
        case 'done':
          return 'выполнено';
        case 'incomplete':
          return 'не выполнено';
        case 'confirmDone':
          return 'подтвердить выполнение';
      }
    },
    translateTenderRole(role) {
      switch (role) {
        case 'admin':
          return 'Администратор ДКП';
        case 'organizer':
          return 'Заказчик';
        case 'contractor':
          return 'Подрядчик';
        case 'insurance':
          return 'Страховщик';        
      }
    },
    parseDateToUnix(string) {
      //Преобразовываем дату unix для сравнения в фильтрах
      if (!string) return null;
      string = string.slice(0, 10);
      const [day, month, year] = string.split('.');
      return Date.parse(`${year}-${month}-${day}`);
    },
    formatDate(date) {
      //Преобразовываем дату для рендера на странице в более привычном виде
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    generateLinkTender() {
      const domain = window.location.origin;
      const link = `${domain}/#/tender?id=${this.tenderId}`;
      let oInput = document.createElement('input');
      oInput.value = link;
      document.body.appendChild(oInput);
      oInput.select(); // выбираем объект
      document.execCommand('Copy'); // выполняем команду копирования браузера
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.openDialogInfo('Ссылка на тендер сгенерирована и скопирована в буфер обмена.');
    }
  }
};
