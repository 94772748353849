<template>
  <v-card outlined>
    <v-card-title>
      <v-layout column class="px-5">
        <v-flex class="align-self-end mb-5"></v-flex>
        <v-flex class="align-self-center mb-5">Журнал посещений</v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-layout wrap class="justify-start">
        <v-flex sx12 md4 class=" wrap_column">
          <v-layout column class="px-5">
            <v-flex class="text_field">
              <v-autocomplete
                class="input_custom"
                clearable
                label="Наименование ПО"
                autocomplete
                v-model="searchData.orgname"
                :items="organizations"
                item-text="name"
                item-value="name"
                :no-data-text="noDataText"
              ></v-autocomplete>
            </v-flex>
            <v-flex class="text_field">
              <v-select
                :items="worktypes"
                item-value="type"
                item-text="type"
                label="Тип работ"
                v-model="searchData.type"
                dense
              ></v-select>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex sx12 md4 class=" wrap_column">
          <v-layout column class="px-5">
            <v-flex class="text_field">
              <v-select
                :items="citiesList"
                item-value="name"
                item-text="name"
                label="Город"
                v-model="searchData.city"
              ></v-select>
            </v-flex>
            <v-flex class="text_field">
              <v-text-field
                v-model="searchData.number"
                hide-details
                clearable
                type="number"
                label="Номер объявления"
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex sx12 md4 class=" wrap_column">
          <v-layout column class="px-5">
            <v-flex xs12 class="date-picker text_field">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    readonly
                    v-model="start_date"
                    label="Дата от"
                    prepend-inner-icon="event"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchData.start_dateISO"
                  locale="rus"
                  no-title
                  @input="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 class="date-picker text_field">
              <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    readonly
                    v-model="end_date"
                    label="Дата до"
                    prepend-inner-icon="event"
                    v-on="on"
                    clearable
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchData.end_dateISO"
                  locale="rus"
                  no-title
                  @input="menuEnd = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-flex class="mt-10">
        <v-data-table
          :headers="headersLogContractor"
          :items="filtredActions"
          :items-per-page.sync="searchData.pagination"
          locale="ru"
        >
          <template v-slot:item.id="{ item }">
            {{ actions.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>
        </v-data-table>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      start_dateISO: "",
      end_dateISO: "",
      noDataText: "Нет данных",
      searchData: this.$store.getters[`${this.$router.currentRoute.name}`],
      actions: [],
      organizations: [],
      citiesList: [],
      worktypes: [],
      headersLogContractor: [
        {
          text: "№ п/п",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "Пользователь (логин)", value: "login" },
        { text: "Наименование ПО", value: "orgname" },
        { text: "Дата", value: "date", sortable: false },
        { text: "Тип работ", value: "type", sortable: false },
        { text: "Действие", value: "action", sortable: false },
        { text: "Город", value: "city", sortable: false },
        {
          text: "Номер объявления",
          align: "right",
          value: "tenderid",
          sortable: false,
        },
      ],
      menuStart: false,
      menuEnd: false,
    };
  },
  methods: {
    getLogsContractor() {
      return new Promise((resolve) => {
        const action = "getLogsContractor";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.actions = resp.actions;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getOrgRegister() {
      return new Promise((resolve) => {
        const action = "getOrgRegister";
        const dataParams = {};
        this.$store
          .dispatch("login", { action, dataParams })
          .then((resp) => {
            this.organizations = resp.organizations;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    getWorkTypes() {
      return new Promise((resolve) => {
        const action = "getWorkTypes";
        const dataParams = {};
        this.$store
          .dispatch("login", { action, dataParams })
          .then((resp) => {
            this.worktypes = [{ id: 0, type: "все" }, ...resp.worktypes];
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getCitiesList() {
      return new Promise((resolve) => {
        const action = "getCitiesList";
        const dataParams = {};
        this.$store
          .dispatch("login", { action, dataParams })
          .then((resp) => {
            this.citiesList = [{ id: 0, name: "все" }, ...resp.citiesList];
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    parseDateToUnix(string) {
      //Преобразовываем дату unix для сравнения в фильтрах
      if (!string) return null;
      string = string.slice(0, 10);
      const [day, month, year] = string.split(".");
      return Date.parse(`${year}-${month}-${day}`);
    },
    formatDate(data) {
      if (!data) return null;
      const date = data.slice(0, 10);
      // const time = data.slice(11, 19);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
  },
  computed: {
    start_date: {
      get() {
        return this.formatDate(this.searchData.start_dateISO);
      },
      set(v) {
        this.searchData.start_dateISO = v;
        return v;
      },
    },
    end_date: {
      get() {
        return this.formatDate(this.searchData.end_dateISO);
      },
      set(v) {
        this.searchData.end_dateISO = v;
        return v;
      },
    },
    filtredActions: {
      get() {
        return (
          this.actions
            .filter((i) => {
              return (
                !this.searchData.orgname ||
                i.orgname
                  .toLowerCase()
                  .indexOf(this.searchData.orgname.toLowerCase()) != -1
              );
            })
            //Фильтр по городу
            .filter((i) => {
              return (
                this.searchData.city === "все" ||
                i.city
                  .toLowerCase()
                  .indexOf(this.searchData.city.toLowerCase()) != -1
              );
            })
            //Фильтр по типу работ
            .filter((i) => {
              return (
                this.searchData.type === "все" ||
                i.type
                  .toLowerCase()
                  .indexOf(this.searchData.type.toLowerCase()) != -1
              );
            })
            //Фильтр по номеру объявления
            .filter((i) => {
              return (
                !this.searchData.number || i.tenderid == this.searchData.number
              );
            })
            //Фильтр по дате
            .filter((i) => {
              return (
                (this.searchData.start_dateISO === null &&
                  this.searchData.end_dateISO === null) ||
                (this.parseDateToUnix(i.date) >=
                  this.parseDateToUnix(this.searchData.start_dateISO) &&
                  this.searchData.end_dateISO === null) ||
                (this.searchData.start_dateISO === null &&
                  this.parseDateToUnix(i.date) <=
                    this.parseDateToUnix(this.searchData.end_dateISO)) ||
                (this.parseDateToUnix(i.date) >=
                  this.parseDateToUnix(this.searchData.start_dateISO) &&
                  this.parseDateToUnix(i.date) <=
                    this.parseDateToUnix(this.searchData.end_dateISO))
              );
            })
        );
      },
      set(v) {
        return v;
      },
    },
  },
  mounted() {
    this.getOrgRegister()
    this.getLogsContractor();
    this.getWorkTypes();
    this.getCitiesList();
  },
};
</script>
<style scoped>
.wrap_column {
  max-width: 280px !important;
}
.v-slider--horizontal {
  margin: 0 !important;
}
.slider_field {
  width: 100% !important;
}
.slider_wrap {
  flex-wrap: wrap;
}
.text_field {
  max-width: 240px !important;
}
.date-picker {
  height: 48px;
}
.subheader_sum {
  height: auto;
  margin-top: 10px;
}
</style>
