<template>
  <v-card outlined>
    <v-card-title style="justify-content: center">Карточка организации</v-card-title>
    <v-list two-line>
      <v-list-item v-for="(item, i) in fields" :key="i">
        <v-layout wrap class="item_wrap">
          <v-flex xs12 sm6 d-flex class="title_data">
            <div>{{ item.title }}</div>
          </v-flex>
          <v-flex xs12 sm6 class="data_wrap">
            <v-text-field class="data" v-model="item.value" type="text" outlined dense readonly
              hide-details></v-text-field>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-card-title v-if="roleId === 3" class="justify-center">Документы</v-card-title>
      <v-list-item v-if="roleId === 3">
        <v-card outlined width="100%">
          <v-subheader v-if="!uploadFilesInfo" class="justify-center my-5">Нет прикрепленных документов</v-subheader>
          <v-layout v-else wrap class="item_wrap my-5">
            <v-flex xs12 sm6 d-flex class="title_data title_upload">
              <v-btn width="200px" height="40px" :href="uploadUrl + orgId + '/' + uploadFilesInfo.attachment" outlined
                color="primary">скачать анкету</v-btn>
            </v-flex>
            <v-flex xs12 sm6 class="data_wrap">
              <v-btn width="200px" height="40px" outlined color="primary"
                @click="openDialogConfirm('Удалить?', 'removeAttachAdmin')">удалить</v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap class="item_wrap mb-5">
            <v-flex sx12 sm6 d-flex class="title_data">
              <div>
                <v-file-input class="inputFieldLeft" prepend-icon type="file" v-model="attach" label="Выбрать документ"
                  :rules="uploadRules" accept=".rar, .zip, 7z" clearable hide-details outlined dense></v-file-input>
              </div>
            </v-flex>
            <v-flex xs12 sm6 class="data_wrap">
              <v-btn width="200px" height="40px" :disabled="uploadDisabled" outlined color="primary" @click="
                uploadFilesInfo
                  ? openDialogConfirm(
                    'Уже есть ранее загруженный документ, все равно заменить?',
                    'uploadAttachAdmin'
                  )
                  : openDialogConfirm(
                    'Загрузить документ?',
                    'uploadAttachAdmin'
                  )
              ">загрузить</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-list-item>
      <v-card-title v-if="roleId === 3" class="justify-center">Допуск</v-card-title>
      <v-list-item v-if="roleId === 3">
        <v-card outlined width="100%">
          <v-layout wrap>
            <v-flex sx12 sm6>
              <v-layout class="item_wrap">
                <v-flex class="data_wrap align-center mr-5">
                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-title>Действующий допуск</v-list-item-title>
                      </template>
                      <v-flex v-if="permInfoActive.length">
                        <v-treeview selected-color="primary" :items="permInfoActive"></v-treeview>
                      </v-flex>
                      <v-flex v-else>
                        <v-subheader>Нет действующего допуска</v-subheader>
                      </v-flex>
                    </v-list-group>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sx12 sm6>
              <v-layout class="item_wrap">
                <v-flex class="data_wrap align-center mr-5">
                  <v-list>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-title>Добавить допуск</v-list-item-title>
                      </template>
                      <v-flex v-if="permInfoRequest.length">
                        <v-treeview class="mb-5" v-model="permSelect" selectable return-object selection-type="leaf"
                          selected-color="primary" :items="permInfoRequest"></v-treeview>
                        <v-btn @click="addPermissionsAdmin" outlined color="red darken-3" height="40px">Добавить</v-btn>
                      </v-flex>
                      <v-flex v-else>
                        <v-subheader>Нет допуска для запроса</v-subheader>
                      </v-flex>
                    </v-list-group>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-list-item>
      <v-card-title class="justify-center">Пользователи</v-card-title>
      <v-list-item>
        <v-card outlined width="100%">
          <v-data-table :headers="usersHeaders" :items="users" hide-default-footer @click:row="goToUserCard">
          </v-data-table>
        </v-card>
      </v-list-item>
      <v-list-item>
        <v-layout>
          <v-flex d-flex class="justify-center">
            <v-btn outlined color="red darken-3" height="40px">Сохранить</v-btn>
          </v-flex>
        </v-layout>
      </v-list-item>
    </v-list>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinDialog";
export default {
  mixins: [Mixin],
  data() {
    return {
      usersHeaders: [
        {
          text: 'ФИО',
          align: 'start',
          sortable: false,
          value: 'fio',
        },
        {
          text: 'Логин',
          align: 'start',
          sortable: false,
          value: 'login',
        },
      ],
      users: [],
      fields: [
        {
          name: "orgname",
          title: "Организация",
          value: "",
        },
        {
          name: "role",
          title: "Роль",
          value: "",
        },
        {
          name: "isactive",
          title: "Статус",
          value: "",
        },
        {
          name: "orgstatus",
          title: "Тип аккаунта",
          value: "",
        },
        {
          name: "inn",
          title: "ИНН",
          value: "",
        },
        {
          name: "kpp",
          title: "КПП",
          value: "",
        },
        {
          name: "ogrn",
          title: "ОГРН/ОГРНИП",
          value: "",
        },
        {
          name: "account",
          title: "Расчетный счет",
          value: "",
        },
        {
          name: "corr",
          title: "Корреспондентский счет",
          value: "",
        },
        {
          name: "bankname",
          title: "Наименование банка",
          value: "",
        },
        {
          name: "bic",
          title: "БИК",
          value: "",
        },
        {
          name: "manager",
          title: "ФИО руководителя",
          value: "",
        },
        {
          name: "address",
          title: "Адрес местонахождения",
          value: "",
        },
      ],
      uploadUrl: process.env.VUE_APP_UPLOADS_ORG_URL,
      attach: [],
      permSelect: [],
      citiesList: [],
      worktypes: [],
      orgId: this.$route.query.id,
      permInfoActive: [],
      permInfoRequest: [],
      orgname: "",
      inn: "",
      valid: false,
      orgstatus: "",
      isactive: "",
      roleName: "",
      roleId: "",
      uploadFilesInfo: "",
      uploadRules: [(v) => v.size < 10000000 || "Не более 10 MB!"],
    };
  },
  methods: {
    goToUserCard(item) {
      this.$router.push({ path: "/admin/usercard", query: { id: item.id } });
    },
    removeAttachAdmin() {
      return new Promise((resolve) => {
        const action = "removeAttachAdmin";
        const dataParams = {
          orgId: this.orgId,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result === "success") {
              this.getOrgInfoAdmin();
              this.attach = [];
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    uploadAttachAdmin() {
      return new Promise((resolve) => {
        const action = "uploadAttachAdmin";
        const dataParams = {
          orgId: this.orgId,
          attachName: this.attach.name,
        };
        const attach = this.attach;
        this.$store
          .dispatch("SendForm", { action, dataParams, attach })
          .then((resp) => {
            if (resp.result === "success") {
              this.getOrgInfoAdmin();
              this.attach = [];
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getOrgInfoAdmin() {
      return new Promise((resolve) => {
        const action = "getOrgInfoAdmin";
        const dataParams = { orgId: this.orgId };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            const org = resp.org;
            this.fields.forEach((el) => {
              const value = org[el.name] === null ? "" : org[el.name];
              el.value = value;
              if (el.name == "role") el.value = this.transformRole(el.value);
              if (el.name == "orgstatus")
                el.value = this.transformOrgStatus(el.value);
              if (el.name == "isactive" && el.value) {
                el.value = "Активный";
              } else if (el.name == "isactive" && !el.value) {
                el.value = "Неактивный";
              }
            });

            this.roleId = org.role;
            this.permInfoActive = this.transformForCheckBox(resp.permActive);
            this.permInfoRequest = this.transformForCheckBox(resp.permRequest);
            this.permSelect = [];
            this.uploadFilesInfo = resp.attachment;
            this.users = resp.users;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    addPermissionsAdmin() {
      return new Promise((resolve) => {
        const action = "addPermissionsAdmin";
        const dataParams = {
          orgId: this.orgId,
          permissions: this.permSelect,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result === "success") this.getOrgInfoAdmin();
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    transformForCheckBox(array) {
      const newArray = [];
      let id = 0;
      array.forEach((itemCitylevel) => {
        const city = { id: "", name: "", children: [] };
        city.id = ++id;
        city.name = itemCitylevel.cityname;
        array.forEach((itemPermLevel) => {
          if (itemCitylevel.cityid == itemPermLevel.cityid) {
            //'-1' -  чтобы не пропускал индекс 0, worktypeid начинаются с 1
            city.children[itemPermLevel.worktypeid - 1] = {
              id: ++id,
              name: itemPermLevel.typename,
              typeId: itemPermLevel.worktypeid,
              cityId: itemCitylevel.cityid,
              cityName: itemCitylevel.cityname,
            };
          }
          this.$set(newArray, []);
          newArray[itemCitylevel.cityid - 1] = city;
        });
      });
      return newArray;
    },
    transformRole(role) {
      switch (role) {
        case 1:
          return "Администратор";
        case 2:
          return "Заказчик";
        case 3:
          return "Подрядчик";
        case 4:
          return "Страховая компания";
        default:
          return "неизвестная роль";
      }
    },
    transformOrgStatus(orgstatus) {
      switch (orgstatus) {
        case "simple":
          return "Простой";
        case "premium":
          return "Привилегированный";
        default:
          return "Неизвестный тип";
      }
    },
  },
  mounted() {
    this.getOrgInfoAdmin();
  },
  computed: {
    uploadDisabled() {
      if ((this.attach && this.attach.length === 0) || !this.attach) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.data {
  max-width: 300px;
}

.inputFieldLeft {
  /* display: flex; */
  justify-content: right;
  width: 300px;
}

.data_wrap_uploads {
  display: flex;
  justify-content: flex-end;
}

.title_data {
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.data_wrap {
  display: flex;
  justify-content: left;
}

.item_wrap {
  justify-content: center;
}

.table_title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px
}

@media screen and (min-width: 1px) and (max-width: 599px) {
  .data {
    justify-content: center;
  }

  .title_data {
    justify-content: center;
    padding-right: 0px;
    margin-bottom: 5px;
  }

  .title_upload {
    margin-bottom: 20px;
  }

  .data_wrap,
  .data_wrap_uploads {
    justify-content: center;
  }

  .btn_wrap {
    justify-content: center;
    margin-top: 15px;
  }

  .inputFieldLeft {
    margin-bottom: 15px;
    width: 200px;
  }
}
</style>
