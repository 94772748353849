"use strict";
export default {
  state: {
    searchDataNewTendersAdmin: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDataClosedTendersAdmin: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDataPublish: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 5,
      status: "all"
    },
    searchDataDone: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 5,
      status: "all"
    },
    searchDatamytenders: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDatanewtenders: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDatawintenders: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDataClosedTendersOrganizer: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDataNewTendersOrganizer: {
      start_dateISO: null,
      end_dateISO: null,
      name: null,
      city: null,
      street: null,
      homenumber: null,
      type: "все",
      amount: 0,
      startamount: 0,
      number: 0,
      orderId: null,
      newProposal: false,
      pagination: 15,
      status: "all"
    },
    searchDatarestriction: {
      contractor: null,
      city: "все",
      type: "все",
      pagination: 5,
    },
    searchDataProposalsListAdmin: {
      name: null,
      status: "all",
      betamount: "0",
      organisationname: null,
      pagination: 5,
    },
    searchDataproposalsList: {
      name: null,
      status: "all",
      betamount: "0",
      organisationname: null,
      pagination: 5,
    },
    searchDatamyProposalsList: {
      name: null,
      status: "all",
      betamount: "0",
      organisationname: null,
      pagination: 15,
    },
    searchDataLogContractor: {
      number: null,
      orgname: null,
      type: "все",
      city: "все",
      start_dateISO: null,
      end_dateISO: null,
      pagination: 5,
    },
    searchDataWinTendersReport: {
      number: null,
      orgname: null,
      type: "все",
      start_dateISO: null,
      end_dateISO: null,
      pagination: 15,
      price: null,
    },
    searchDataAnalyticalReport: {
      organizer: null,
      pagination: 5,
    },
  },
  getters: {
    ClosedTendersAdmin: (state) => state.searchDataClosedTendersAdmin,
    NewTendersAdmin: (state) => state.searchDataNewTendersAdmin,
    ProposalsListAdmin: (state) => state.searchDataProposalsListAdmin,
    Publish: (state) => state.searchDataPublish,
    Done: (state) => state.searchDataDone,
    ClosedTendersOrganizer: (state) => state.searchDataClosedTendersOrganizer,
    NewTendersOrganizer: (state) => state.searchDataNewTendersOrganizer,
    restriction: (state) => state.searchDatarestriction,
    proposalsList: (state) => state.searchDataproposalsList,
    mytenders: (state) => state.searchDatamytenders,
    newtenders: (state) => state.searchDatanewtenders,
    wintenders: (state) => state.searchDatawintenders,
    myProposalsList: (state) => state.searchDatamyProposalsList,
    LogContractor: (state) => state.searchDataLogContractor,
    WinTendersReport: (state) => state.searchDataWinTendersReport,
    AnalyticalReport: (state) => state.searchDataAnalyticalReport,
  },
};
