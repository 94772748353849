<template>
  <v-app>
    <div>
      <v-app-bar dark class="hidden-lg-and-up">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="burger"
      /></v-app-bar>
      <v-layout class="hidden-md-and-down justify-end">
        <v-flex
          v-for="(item, i) in itemsHeader"
          :key="i"
          class="item_menu_wrap"
        >
          <a height="100%" :href="item.url" class="item_menu">{{
            item.text
          }}</a>
        </v-flex>
      </v-layout>
    </div>
    <v-card flat>
      <v-layout class="header_logo_wrap">
        <v-img src="/assets/logo.png"></v-img>
      </v-layout>
    </v-card>
    <v-navigation-drawer
      disable-resize-watcher
      v-model="drawer"
      app
      clipped
      dark
      color="grey lighten-4"
    >
      <v-list dense class="grey lighten-4">
        <v-flex d-flex class="justify-end mr-3">
          <v-btn color="black" icon @click="drawer = !drawer"
            ><v-icon>close</v-icon></v-btn
          >
        </v-flex>
        <template v-for="(item, i) in itemsDrawer">
          <v-divider v-if="item.divider" :key="i" dark class="my-4" />
          <v-list-item v-else :key="i" :href="item.url" link>
            <!-- <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action> -->
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer>
      <v-col class="pa-0" cols="12">
        <v-row>
          <v-col class="text-center footer_row" cols="12">
            Использование данного сайта означает согласие с
            <a
              :href="uploadUrl + 'Пользовательское_соглашение.pdf'"
              target="blank"
              >пользовательским соглашением</a
            >.
            <a
              :href="uploadUrl + 'Политика_защиты_информации_v2.pdf'"
              target="blank"
              >Политика защиты информации.</a
            ></v-col
          >
          <v-col class="text-center footer_row" cols="12">
            <strong>Техподдержка:</strong> тел. +7 812 418-22-36, e-mail: info-board@trest37.ru
          </v-col>
          <v-col class="text-center footer_row" cols="12">
            <strong>Все права защищены</strong> &copy;
            {{ new Date().getFullYear() }} г.</v-col
          ></v-row
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      drawer: false,
      itemsDrawer: [
        {
          url: "http://trest37.ru/#rec134280937",
          text: "Главная"
        },
        {
          url: "http://trest37.ru/#rec134381059",
          text: "Каталог"
        },
        {
          url: "http://trest37.ru/#rec133478411",
          text: "История"
        },
        {
          url: "http://trest37.ru/#rec134405414",
          text: "Партнеры"
        },
        {
          url: "http://trest37.ru/#rec133478418",
          text: "Контакты"
        },
        { url: "#/login", text: "Личный кабинет" }
      ],
      itemsHeader: [
        {
          url: "http://trest37.ru/#rec134280937",
          text: "Главная"
        },
        {
          url: "http://trest37.ru/#rec134381059",
          text: "Каталог"
        },
        {
          url: "http://trest37.ru/#rec133478411",
          text: "История"
        },
        {
          url: "http://trest37.ru/#rec134405414",
          text: "Партнеры"
        },
        {
          url: "http://trest37.ru/#rec133478418",
          text: "Контакты"
        },
        { url: "#/login", text: "Личный кабинет" }
      ]
    };
  }
};
</script>

<style scoped>
.footer_row {
  padding: 10px 0 0 0;
}
.header_menu {
  max-width: 1500px;
}
.burger {
  color: white !important;
  height: 24px;
}
.burger_wrap {
  height: 64px;
  background: black;
}
.header_logo_wrap {
  padding: 10px;
  max-width: 760px;
  margin: auto;
}
.item_menu {
  color: #383737;
  font-weight: 700;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  text-decoration: none;
}
.item_menu_wrap {
  margin: 20px 15px;
  align-self: center;
  max-width: max-content;
}
.company_name {
  margin-top: 35px;
  color: #b5452f;
  font: 1em "Oswald", sans-serif;
  font-size: 1.2em;
  font-weight: 700;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .header_right {
    background: rgba(227, 227, 227);
  }
}
/* @media screen and (min-width: 1px) and (max-width: 600px) {
  .v-image__image--contain {
    background-size: cover !important;
  }
} */
</style>
