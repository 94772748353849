<template>
  <v-card outlined>
    <v-card-title class="pl-9">
      Закрытые объявления
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <SearchTender @data="searchData = $event"></SearchTender>
        </v-flex>
        <v-flex class="mt-10">
          <TableTenders :dataTable="dataTable"></TableTenders>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinTender";

export default {
  mixins: [Mixin],
  data() {
    return {
      menuType: "closedtenders",
    };
  },
  methods: {
    getClosedTendersAdmin() {
      return new Promise((resolve) => {
        const action = "getClosedTendersAdmin";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.tenders = resp.tenders;
            this.filteredItems = resp.tenders;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  computed: {
    dataTable() {
      return {
        tenders: this.filteredItems,
        menuType: this.menuType,
      };
    },
  },
  mounted() {
    this.getClosedTendersAdmin();
  },
};
</script>

<style scoped></style>
