<template>
  <v-dialog width="400px" v-model="modal">
    <v-card v-if="confirm">
      <v-card-title class="justify-center title_text">{{ message }}</v-card-title>
      <v-card-actions class="justify-space-around">
        <v-btn width="100px" outlined color="primary" @click="cancelAction"
          >отмена</v-btn
        >
        <v-btn width="100px" outlined color="primary" @click="okAction">ok</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="justify-center title_text">{{ message }}</v-card-title>
      <v-card-actions class="justify-center">
        <v-btn outlined color="primary" text @click="okInfo">ок</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialogDataIn"],
  data() {
    return {
      modal: false,
      confirm: false,
      message: "",
      action: ""
    };
  },
  methods: {
    okInfo() {
      this.modal = false;
      this.$emit("dialogDataOut", { close: true, action: this.action });
    },
    cancelAction() {
      this.modal = false;
      this.$emit("dialogDataOut", { close: true });
    },
    okAction() {
      this.modal = false;
      this.$emit("dialogDataOut", { close: true, action: this.action });
    }
  },
  watch: {
    //ставим глубокий обработчик для объекта
    dialogDataIn: {
      handler(v) {
        this.modal = v.modal;
        this.message = v.message;
        this.confirm = v.confirm;
        this.action = v.action;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.title_text {
  word-break: break-word;
}
</style>
