<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12>
        <v-card max-width="400" class="ma-auto" outlined>
          <v-toolbar flat>
            <v-toolbar-title>Сброс пароля</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-flex class="body-2 mb-3"
              >Введите email указанный при регистрации</v-flex
            >
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                class="input_custom"
                name="email"
                ref="email"
                label="E-mail"
                type="text"
                :error-messages="emailMessage"
                v-model.lazy="email"
                :rules="emailRules"
                @change="advanceCheckEmail()"
                required
                outlined
                dense
                clearable
              >
              </v-text-field>
            </v-form>
            <v-layout>
              <v-flex class="reset_btn_wrap">
                <v-btn color="primary" text x-small to="/login"
                  >Авторизация</v-btn
                ></v-flex
              >
            </v-layout>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-layout column>
              <v-flex
                ><v-btn
                  class="mb-6"
                  color="primary"
                  @click="reset"
                  :disabled="resetBtnStatus"
                  block
                  >Сбросить пароль</v-btn
                ></v-flex
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="resetDialog" width="300">
      <v-flex>
        <v-card :color="colorResetDialog">
          <v-card-text class="white--text">
            {{ textResetDialog }}
            <v-progress-linear
              indeterminate
              v-if="resetLinear"
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      resetDialog: false,
      textResetDialog: "",
      colorResetDialog: "",
      resetLinear: false,
      email: "",
      emailMessage: "",
      emailCheckStatus: false,
      valid: false,
      emailRules: [v => !!v || "Введите E-mail"]
    };
  },
  methods: {
    alertRegStatus(
      colorResetDialog,
      textResetDialog,
      resetDialog,
      resetLinear
    ) {
      (this.colorResetDialog = colorResetDialog),
        (this.textResetDialog = textResetDialog),
        (this.resetDialog = resetDialog),
        (this.resetLinear = resetLinear);
    },
    advanceCheckEmail() {
      if (this.$refs.email.validate()) {
        return new Promise(resolve => {
          const action = "advanceCheckEmail";
          const dataParams = {
            email: this.email
          };
          this.$store
            .dispatch("login", { action, dataParams })
            .then(resp => {
              if (resp.result == "success") {
                this.emailMessage =
                  "Пользователя с таким E-mail не найдено в базе";
              } else {
                this.emailMessage = "";
                this.emailCheckStatus = true;
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    reset() {
      if (this.$refs.email.validate()) {
        return new Promise(resolve => {
          const action = "reset";
          const dataParams = {
            email: this.email
          };
          this.$store
            .dispatch("login", { action, dataParams })
            .then(resp => {
              if (resp.result == "success") {
                this.alertRegStatus(
                  "success",
                  "Новый пароль отправлен на ваш Email",
                  true,
                  false
                );
                setTimeout(() => {
                  this.$router.push("/login");
                }, 3000);
              } else {
                this.emailMessage = "";
                this.emailCheckStatus = true;
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    }
  },
  computed: {
    resetBtnStatus() {
      if (this.valid && this.emailCheckStatus) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style scoped>
.reset_btn_wrap {
  text-align: end;
}
</style>
