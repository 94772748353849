<template>
  <v-card outlined class="">
    <v-list two-line>
      <v-list-item v-for="(item, i) in fields" :key="i" class="mb-5">
        <v-layout class="item_wrap">
          <v-flex d-flex class="data_wrap align-start mr-5 pt-2">
            <div>{{ item.title }}</div>
          </v-flex>
          <v-flex d-flex class="data_wrap align-start">
            <v-text-field
              v-model="item.valueNew"
              :ref="item.name"
              type="text"
              outlined
              dense
              :rules="item.rules"
              :readonly="!item.isActive"
              :disabled="!item.isActive"
              :hide-details="!item.isActive"
              @input="
                item.message.pop(),
                  item.advanceCheck
                    ? item.advanceCheck(item.name, item.valueNew, item.message, item.isActive)
                    : null
              "
              :error-messages="item.message ? item.message : null"
            ></v-text-field>
            <v-flex v-if="item.loading && item.edit" class="ml-2">
              <v-progress-circular size="34" color="info" indeterminate></v-progress-circular>
            </v-flex>
            <v-flex v-else-if="item.isActive && item.edit">
              <v-btn
                icon
                color="green"
                outlined
                rounded
                class="ml-2"
                :disabled="!$refs[item.name][0].validate()"
                @click="save(item.name)"
              >
                <v-icon large>
                  check
                </v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                outlined
                rounded
                class="ml-2"
                @click="(item.isActive = !item.isActive), (item.valueNew = item.value)"
              >
                <v-icon large>
                  close
                </v-icon>
              </v-btn>
            </v-flex>
            <v-btn
              v-else-if="item.edit"
              icon
              color="primary"
              outlined
              rounded
              class="ml-2"
              @click="edit(item.name)"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout class="item_wrap">
            <v-flex d-flex class="data_wrap mr-5">
              <div>Смена пароля:</div>
            </v-flex>
            <v-flex class="data_wrap mr-5">
              <v-text-field
                id="password"
                name="password"
                label="Пароль"
                type="password"
                v-model="password"
                :rules="passwordRules"
                outlined
                required
                dense
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex class="data_wrap mr-5">
              <v-text-field
                id="passwordConfirm"
                label="Подтвержение пароля"
                type="password"
                v-model="passwordConfirm"
                :rules="passwordConfirmRules"
                outlined
                required
                dense
                clearable
              ></v-text-field>
            </v-flex>

            <v-flex class="align-self-start">
              <v-btn
                @click="openDialogConfirm('Сменить пароль?', 'changePass')"
                :disabled="changeBtnStatus"
                outlined
                color="red darken-3"
                height="40px"
              >
                Сменить пароль
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-list-item>
      <v-list-item>
        <v-layout class="item_wrap">
          <v-flex class="data_wrap align-center mr-5">
            <v-list>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title>Согласия</v-list-item-title>
                </template>
                <v-layout column justify-space-around class="py-4">
                  <v-flex>
                    <v-alert outlined dense :type="approveData ? 'success' : 'error'">
                      Согласие на обработку персональных данных -
                      <strong>{{ approveData ? 'получено' : 'не получено' }}</strong>
                    </v-alert>
                  </v-flex>
                  <v-flex>
                    <v-alert outlined dense :type="approveMail ? 'success' : 'error'">
                      Согласие на получение рассылки -
                      <strong>{{ approveMail ? 'получено' : 'не получено' }}</strong>
                    </v-alert>
                  </v-flex>
                  <v-flex v-if="!requestRevoke">
                    <v-btn @click="revokeDialog = true" outlined color="primary">
                      Отозвать согласие
                    </v-btn>
                  </v-flex>
                  <v-flex v-else>
                    <v-alert dense rounded color="red" type="warning">
                      Запрос на отзыв отправлен и рассматривается администратором
                    </v-alert>
                  </v-flex>
                </v-layout>
              </v-list-group>
            </v-list>
          </v-flex>
        </v-layout>
      </v-list-item>
      <v-list-item class="mb-5">
        <v-layout column class="item_wrap">
          <v-flex d-flex class="data_wrap align-center">
            <v-btn color="primary" :href="uploadUrl + 'organizer.zip'" outlined download>
              Скачать инструкции
            </v-btn>
          </v-flex>
        </v-layout>
      </v-list-item>
    </v-list>
    <Dialog @dialogDataOut="dialogDataOut = $event" :dialogDataIn="dialogDataIn"></Dialog>
    <v-dialog v-model="revokeDialog" width="500">
      <v-card>
        <v-card-title class="title_text">
          Для отзыва согласия на хранение и обработку персональных данных просьба описать причину, а
          также указать контактный номер руководителя организации
        </v-card-title>
        <v-card-text>
          <v-textarea
            label="Причина отзыва"
            v-model="revokeReason"
            outlined
            rows="2"
            clearable
            dense
            no-resize
            :rules="[v => !!v || 'Необходимо ввести причину']"
          ></v-textarea>
          <v-text-field
            label="Контактный номер руководителя"
            v-model="revokePhone"
            type="text"
            outlined
            clearable
            dense
            :rules="[
              v => !!v || 'Необходимо ввести номер',
              v => v.length <= 50 || 'не более 50 символов'
            ]"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="revokeDialog = false">
            отмена
          </v-btn>
          <v-btn
            @click="revokeApproval"
            outlined
            color="primary"
            :disabled="!revokeReason || !revokePhone"
          >
            отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      revokeReason: '',
      revokePhone: '',
      revokeDialog: false,
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      approveData: this.$store.getters.approveData,
      approveMail: this.$store.getters.approveMail,
      orgId: '',
      fio: '',
      email: '',
      // checkInn: false,
      fields: [
        {
          name: 'login',
          title: 'Логин',
          value: '',
          isActive: false,
          loading: false
        },
        {
          name: 'email',
          title: 'Email',
          value: '',
          isActive: false,
          loading: false
        },
        {
          name: 'fio',
          title: 'ФИО',
          value: this.fio,
          isActive: false,
          loading: false
        },
        {
          name: 'name',
          title: 'Организация',
          value: '',
          valueNew: '',
          isActive: false,
          loading: false,
          edit: true,
          rules: [v => !!v || 'Необходимо ввести наименование']
        },
        {
          name: 'inn',
          title: 'ИНН',
          value: '',
          valueNew: '',
          isActive: false,
          loading: false,
          edit: false,
          // advanceCheck: this.advanceCheckInn,
          message: [],
          rules: [
            v => !!v || 'Необходимо ввести ИНН',
            v =>
              (v.length >= this.innMinLength && v.length <= this.innMaxLength) ||
              `От ${this.innMinLength} до ${this.innMaxLength} цифр`
          ]
        }
      ],
      password: '',
      passwordConfirm: '',
      minPassLen: 6,
      valid: false,
      login: '',
      innMinLength: this.$store.getters.innMinLength,
      innMaxLength: this.$store.getters.innMaxLength,
      kppLength: this.$store.getters.kppLength,
      ogrnMinLength: this.$store.getters.ogrnMinLength,
      ogrnMaxLength: this.$store.getters.ogrnMaxLength,
      accountLength: this.$store.getters.accountLength,
      corrLength: this.$store.getters.corrLength,
      bicLength: this.$store.getters.bicLength,
      passwordRules: [
        v => !!v || 'Необходимо ввести пароль',
        v =>
          (v && v.length >= this.minPassLen) ||
          `Пароль должен быть не менее ${this.minPassLen} символов`
      ],
      passwordConfirmRules: [
        v => !!v || 'Введите пароль',
        v => v === this.password || 'Пароли не соответствует'
      ],
      requestRevoke: this.$store.getters.requestRevoke
    };
  },
  methods: {
    getOrganizerInfo() {
      return new Promise(resolve => {
        const action = 'getOrganizerInfo';
        const dataParams = {
          id: this.$store.getters.id
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            const user = resp.user;
            this.fields.forEach(el => {
              const value = user[el.name] === null ? '' : user[el.name];
              el.value = el.valueNew = value;
              if (el.name == 'fio')
                el.value = el.valueNew =
                  user.firstname + ' ' + user.secondname + ' ' + user.lastname;
            });
            this.orgId = user.orgid;
            this.email = user.email;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    changePass() {
      if (this.$refs.form.validate()) {
        return new Promise(resolve => {
          const action = 'reset';
          const dataParams = {
            email: this.email,
            password: this.password
          };
          this.$store
            .dispatch('login', { action, dataParams })
            .then(resp => {
              if (resp.result == 'success') {
                this.openDialogInfo('Изменения успешно сохранены');
                this.password = '';
                this.passwordConfirm = '';
              } else {
                this.openDialogInfo('Ошибка изменения пароля');
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    },
    ok() {
      this.changePassDialog = false;
    },
    edit(item) {
      //Составляем наименование свойства, которое отвечает за активность кнопки редактирования поля
      this.fields.forEach(el => {
        if (el.name == item) el.isActive = !el.isActive;
      });
    },
    save(item) {
      const data = {};
      this.fields.forEach(el => {
        if (el.name == item) {
          el.isActive = !el.isActive;
          el.loading = !el.loading;
          data.name = el.name;
          data.value = el.valueNew;
          data.title = el.title;
          return new Promise(resolve => {
            const action = 'saveOrganizerField';
            const dataParams = {
              orgId: this.orgId,
              name: data.name,
              value: data.value
            };
            this.$store
              .dispatch('Api', { action, dataParams })
              .then(resp => {
                if (resp.result == 'success') {
                  el.loading = !el.loading;
                  this.openDialogInfo(
                    `Поле "${data.title}" отправлено администратору для подтверждения`
                  );
                }
                resolve(resp);
              })
              .catch(err => {
                console.log(err);
              });
          });
        }
      });
    },
    // advanceCheckInn(name, value, message) {
    //   if (
    //     this.$refs[name][0].validate() &&
    //     value.length >= this.innMinLength &&
    //     value.length <= this.innMaxLength
    //   ) {
    //     return new Promise(resolve => {
    //       const action = 'advanceCheckInn';
    //       const dataParams = {
    //         inn: value
    //       };
    //       this.$store
    //         .dispatch('login', { action, dataParams })
    //         .then(resp => {
    //           if (resp.result == 'success') {
    //             this.checkInn = true;
    //             message.pop();
    //           } else {
    //             message.pop();
    //             message.push('Пользователь с таким ИНН уже существует');
    //             this.checkInn = false;
    //           }
    //           resolve(resp);
    //         })
    //         .catch(err => {
    //           console.log(err);
    //         });
    //     });
    //   }
    // },
    revokeApproval() {
      this.revokeDialog = false;
      return new Promise(resolve => {
        const action = 'revokeApproval';
        const dataParams = {
          reason: this.revokeReason,
          phone: this.revokePhone
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.requestRevoke = true;
              this.$store.commit('setRequestRevoke', { requestRevoke: true });
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  mounted() {
    this.getOrganizerInfo();
  },
  computed: {
    changeBtnStatus() {
      if (this.password != '' && this.passwordConfirm != '' && this.valid) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.subtitle {
  font-weight: normal;
  font-size: 12px;
  color: darkgray;
}
.data_wrap {
  max-width: max-content;
}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .item_wrap {
    flex-direction: column;
  }
}
.title_text {
  word-break: break-word;
}
</style>
