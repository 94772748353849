<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0">
      Объект «{{ lotInfo.name }}» к объявления «{{ lotInfo.tendername }}»
    </v-card-title>
    <v-card-text class="px-0">
      <v-list two-line>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title
              >г. {{ lotInfo.city }}, ул. {{ lotInfo.street }}, д.
              {{ lotInfo.homenumber }}
            </v-list-item-title>
            <v-list-item-subtitle>Полный адрес</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{ lotInfo.type }}</v-list-item-title>
            <v-list-item-subtitle>Тип работ</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item class="px-0">
          <v-list-item-title>Документация:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-if="!attachments">
          <v-list-item-subtitle>Документации нет</v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          class="px-0"
          v-else
          v-for="(attach, i) in attachments"
          :key="i"
        >
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="data_wrap align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="data_wrap align-center">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="
                  uploadUrl +
                    lotInfo.tenderid +
                    '/lots/' +
                    lotId +
                    '/' +
                    attach.attachment
                "
                download
                >скачать</v-btn
              >
            </v-flex>
          </v-layout>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn class="btn_action" outlined color="primary" @click="back()"
        >назад</v-btn
      >
      <v-btn
        v-if="lotInfo.tenderstatus == 'new'"
        outlined
        color="primary"
        :to="{
          path: '/organizer/editlot',
          query: {
            id: lotId,
            tenderId: lotInfo.tenderid
          }
        }"
        >Редактировать</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_TENDER_URL,
      lotId: this.$route.query.id,
      lotInfo: "",
      attachments: []
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getLotAdmin() {
      return new Promise(resolve => {
        const action = "getLotAdmin";
        const dataParams = {
          lotId: this.lotId
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.lotInfo = resp.lot;
            this.attachments = resp.attachments;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.go(-1);
    },
    exit() {
      this.modal = false;
    }    
  },
  mounted() {
    this.getLotAdmin();
  }
};
</script>

<style scoped>
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .item_wrap {
    flex-direction: column;
  }
}
</style>
