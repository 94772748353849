/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
import Page404 from "@/components/404";
import Home from "@/components/Home";
import Agreement from "@/components/Agreement";
import Login from "@/components/Login";
import Reset from "@/components/Reset";
import Congratulation from "@/components/Congratulation";
import Registration from "@/components/registration/Registration";
import Admin from "@/components/admin/Admin";
import AccessGroup from "@/components/admin/AccessGroup";
import ClosedTendersAdmin from "@/components/admin/ClosedTendersAdmin";
import NewTendersAdmin from "@/components/admin/NewTendersAdmin";
import ProfileAdmin from "@/components/admin/ProfileAdmin";
import TenderAdmin from "@/components/admin/TenderAdmin";
import ProposalsListAdmin from "@/components/admin/ProposalsListAdmin";
import ProposalAdmin from "@/components/admin/ProposalAdmin";
import RegCard from "@/components/admin/RegCard";
import ConfirmEdit from "@/components/admin/ConfirmEdit";
import PermCard from "@/components/admin/PermCard";
import OrgCard from "@/components/admin/OrgCard";
import UserCard from "@/components/admin/UserCard";
import RevokeCard from "@/components/admin/RevokeCard";
import Request from "@/components/admin/request/Request";
import RequestRegistration from "@/components/admin/request/Registration";
import Access from "@/components/admin/request/Access";
import EditProfile from "@/components/admin/request/EditProfile";
import Publish from "@/components/admin/request/Publish";
import Revoke from "@/components/admin/request/Revoke";
import Report from "@/components/admin/report/Report";
import Done from "@/components/admin/request/Done";
import AnalyticalReport from "@/components/admin/report/AnalyticalReport";
import CountTendersReport from "@/components/admin/report/CountTendersReport";
import WinTendersReport from "@/components/admin/report/WinTendersReport";
import Participants from "@/components/admin/participants/Participants";
import ParticipantOrgs from "@/components/admin/participants/Organizations";
import ParticipantUsers from "@/components/admin/participants/Users";
import LotAdmin from "@/components/admin/LotAdmin";
import LogContractor from "@/components/admin/LogContractor";
import Contractor from "@/components/contractor/Contractor";
import Profile from "@/components/contractor/Profile";
import MyTenders from "@/components/contractor/MyTenders";
import AddProposal from "@/components/contractor/AddProposal";
import EditProposal from "@/components/contractor/EditProposal";
import MyProposalsList from "@/components/contractor/MyProposalsList";
import MyProposal from "@/components/contractor/MyProposal";
import NewTenders from "@/components/contractor/NewTenders";
import WinTenders from "@/components/contractor/WinTenders";
import Tender from "@/components/contractor/Tender";
import Lot from "@/components/contractor/Lot";
import Insurance from "@/components/insurance/Insurance";
import InsuranceContractor from "@/components/contractor/Insurance";
import InsuranceInfo from "@/components/shared/Info";
import Organizer from "@/components/organizer/Organizer";
import ClosedTendersOrganizer from "@/components/organizer/ClosedTendersOrganizer";
import NewTendersOrganizer from "@/components/organizer/NewTendersOrganizer";
import ProfileOrganizer from "@/components/organizer/ProfileOrganizer";
import TenderOrganizer from "@/components/organizer/TenderOrganizer";
import LotOrganizer from "@/components/organizer/LotOrganizer";
import AddTender from "@/components/organizer/AddTender";
import AddLot from "@/components/organizer/AddLot";
import EditLot from "@/components/organizer/EditLot";
import EditTender from "@/components/organizer/EditTender";
import ProposalsList from "@/components/organizer/ProposalsList";
import Proposal from "@/components/organizer/Proposal";
import Restriction from "@/components/organizer/Restriction";
import Approve from "@/components/shared/Approve";
import WhiteList from "@/components/organizer/WhiteList"
import store from "@/store";

Vue.use(VueRouter);

const isApproval = (next) => {
  if (!store.getters.approveData || !store.getters.approveMail) {
    next("/approve");
    return;
  }
};

const authAdmin = (to, from, next) => {
  if (store.getters.isAuthorized && store.getters.role == "admin") {
    next();
    return;
  }
  next("/login");
};

const authOrganizer = (to, from, next) => {
  isApproval(next)
  if (store.getters.isAuthorized && store.getters.role == "organizer") {
    next();
    return;
  }
  next("/login");
};

const authContractor = (to, from, next) => {
  isApproval(next)
  if (store.getters.isAuthorized && store.getters.role == "contractor") {
    next();
    return;
  }

  next("/login");
};

const authInsurance = (to, from, next) => {
  if (store.getters.isAuthorized && store.getters.role == "insurance") {
    next();
    return;
  }
  next("/login");
};

const authTenderLink = (to, from, next) => {
  if (store.getters.isAuthorized && store.getters.role && to.name == "tenderLink" && to.query.hasOwnProperty('id')) {
    next(`/${store.getters.role}/tender?id=${to.query.id}`);
    return;
  }
  next("/login");
};

const auth = (to, from, next) => {
  if (store.getters.isAuthorized && store.getters.role == "admin") {
    next("/admin/newtenders");
    return;
  } else if (store.getters.isAuthorized && store.getters.role == "contractor") {
    next("/contractor/newtenders");
    return;
  } else if (store.getters.isAuthorized && store.getters.role == "organizer") {
    next("/organizer/newtenders");
    return;
  } else if (store.getters.isAuthorized && store.getters.role == "insurance") {
    next("/insurance/info");
    return;
  }
  next();
};

const router = new VueRouter({
  routes: [
    {
      path: "*",
      name: "404",
      component: Page404,
    },
    {
      path: "/",
      name: "home",
      component: Home,
      redirect: "/login",
    },
    {
      path: "/tender",
      name: "tenderLink",
      beforeEnter: authTenderLink,
    },
    {
      path: "/agreement",
      name: "agreement",
      component: Agreement,
    },
    {
      path: "/approve",
      name: "approve",
      component: Approve,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      beforeEnter: auth,
    },
    {
      path: "/reset",
      name: "reset",
      component: Reset,
      beforeEnter: auth,
    },
    {
      path: "/congratulation",
      name: "congratulation",
      component: Congratulation,
    },
    {
      path: "/registration",
      name: "registration",
      component: Registration,
      beforeEnter: auth,
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      beforeEnter: authAdmin,
      children: [
        {
          path: "closedtenders",
          name: "ClosedTendersAdmin",
          component: ClosedTendersAdmin,
        },
        {
          path: "newtenders",
          name: "NewTendersAdmin",
          component: NewTendersAdmin,
        },
        { path: "profileAdmin", name: "profileAdmin", component: ProfileAdmin },
        {
          path: "tender",
          name: "tenderAdmin",
          component: TenderAdmin,
          props: true,
        },
        {
          path: "lotAdmin",
          name: "lotAdmin",
          component: LotAdmin,
          props: true,
        },
        {
          path: "ProposalsListAdmin",
          name: "ProposalsListAdmin",
          component: ProposalsListAdmin,
          props: true,
        },
        {
          path: "ProposalAdmin",
          name: "ProposalAdmin",
          component: ProposalAdmin,
          props: true,
        },
        {
          path: "RegCard",
          name: "RegCard",
          component: RegCard,
          props: true,
        },
        {
          path: "ConfirmEdit",
          name: "ConfirmEdit",
          component: ConfirmEdit,
          props: true,
        },
        {
          path: "PermCard",
          name: "PermCard",
          component: PermCard,
          props: true,
        },
        {
          path: "OrgCard",
          name: "OrgCard",
          component: OrgCard,
          props: true,
        },
        {
          path: "UserCard",
          name: "UserCard",
          component: UserCard,
          props: true,
        },
        {
          path: "RevokeCard",
          name: "RevokeCard",
          component: RevokeCard,
          props: true,
        },
        {
          path: "AccessGroup",
          name: "AccessGroup",
          component: AccessGroup,
        },
        {
          path: "Request",
          name: "Request",
          component: Request,
          props: true,
          children: [
            {
              path: "Registration",
              name: "Registration",
              component: RequestRegistration,
              props: true,
            },
            {
              path: "Access",
              name: "Access",
              component: Access,
              props: true,
            },
            {
              path: "Publish",
              name: "Publish",
              component: Publish,
              props: true,
            },
            {
              path: "EditProfile",
              name: "EditProfile",
              component: EditProfile,
              props: true,
            },
            {
              path: "Revoke",
              name: "Revoke",
              component: Revoke,
              props: true,
            },
            {
              path: "Done",
              name: "Done",
              component: Done,
              props: true,
            },
          ],
        },
        {
          path: "Report",
          name: "Report",
          component: Report,
          children: [
            {
              path: "AnalyticalReport",
              name: "AnalyticalReport",
              component: AnalyticalReport,
            },
            {
              path: "CountTendersReport",
              name: "CountTendersReport",
              component: CountTendersReport,
            },
            {
              path: "WinTendersReport",
              name: "WinTendersReport",
              component: WinTendersReport,
            },
          ],
        },
        {
          path: "participants",
          name: "Participants",
          component: Participants,
          children: [
            {
              path: "organizations",
              name: "ParticipantOrgs",
              component: ParticipantOrgs,
            },
            {
              path: "users",
              name: "ParticipantUsers",
              component: ParticipantUsers,
            },
          ],
        },        
        {
          path: "LogContractor",
          name: "LogContractor",
          component: LogContractor,
        },
      ],
    },
    {
      path: "/organizer",
      name: "organizer",
      component: Organizer,
      beforeEnter: authOrganizer,
      children: [
        {
          path: "closedTenders",
          name: "ClosedTendersOrganizer",
          component: ClosedTendersOrganizer,
        },
        {
          path: "NewTenders",
          name: "NewTendersOrganizer",
          component: NewTendersOrganizer,
        },
        {
          path: "profileOrganizer",
          name: "profileOrganizer",
          component: ProfileOrganizer,
        },
        {
          path: "tender",
          name: "tenderOrganizer",
          component: TenderOrganizer,
          props: true,
        },
        {
          path: "lotOrganizer",
          name: "lotOrganizer",
          component: LotOrganizer,
          props: true,
        },
        {
          path: "addTender",
          name: "addTender",
          component: AddTender,
        },
        {
          path: "addLot",
          name: "addLot",
          component: AddLot,
        },
        {
          path: "editLot",
          name: "editLot",
          component: EditLot,
        },
        {
          path: "editTender",
          name: "editTender",
          component: EditTender,
        },
        {
          path: "proposalsList",
          name: "proposalsList",
          component: ProposalsList,
          props: true,
        },
        {
          path: "proposal",
          name: "proposal",
          component: Proposal,
          props: true,
        },
        {
          path: "restriction",
          name: "restriction",
          component: Restriction,
          props: true,
        },
        {
          path: "whiteList",
          name: "whiteList",
          component: WhiteList,
        }
      ],
    },
    {
      path: "/contractor",
      name: "contractor",
      component: Contractor,
      beforeEnter: authContractor,
      children: [
        { path: "profile", name: "profile", component: Profile },
        { path: "mytenders", name: "mytenders", component: MyTenders },
        {
          path: "addProposal",
          name: "addProposal",
          component: AddProposal,
          props: true,
        },
        {
          path: "editProposal",
          name: "editProposal",
          component: EditProposal,
          props: true,
        },
        {
          path: "myProposalsList",
          name: "myProposalsList",
          component: MyProposalsList,
        },
        {
          path: "myproposal",
          name: "myproposal",
          component: MyProposal,
          props: true,
        },
        { path: "newtenders", name: "newtenders", component: NewTenders },
        { path: "wintenders", name: "wintenders", component: WinTenders },
        {
          path: "tender",
          name: "tender",
          component: Tender,
          props: true,
        },
        {
          path: "lot",
          name: "lot",
          component: Lot,
          props: true,
        },
        {
          path: "insurance",
          name: "contractorInsurance",
          component: InsuranceContractor,
        },
      ],
    },
    {
      path: "/insurance",
      name: "insurance",
      component: Insurance,
      beforeEnter: authInsurance,
      children: [{ path: "info", name: "info", component: InsuranceInfo }],
    },
  ],
  // mode: 'history',
  // base: '/'
});

export default router;
