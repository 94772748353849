<template>
  <v-card outlined>
    <v-card-text>
      <v-flex class="mt-10">
        <v-data-table :headers="headers" :items="data" locale="ru">
          <template v-slot:item.pricetotal="{ item }">
            {{ formatCurrency(item.pricetotal) }}
          </template>
           <template v-slot:item.pricewin="{ item }">
            {{ formatCurrency(item.pricewin) }}
          </template>
        </v-data-table>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import Lib from "../../mixin/js/mixinLib";

export default {
  mixins: [Lib],
  data() {
    return {
      headers: [
        {
          text: "Количество всех объявлений, шт",
          align: "center",
          sortable: false,
          value: "counttotal",
        },
        {
          text: "Сумма всех объявления, руб",
          align: "center",
          value: "pricetotal",
          sortable: false,
        },
        {
          text: "Количество выигранных объявлений, шт",
          align: "center",
          sortable: false,
          value: "countwin",
        },
        {
          text: "Сумма выигранных объявлений, руб",
          align: "center",
          value: "pricewin",
          sortable: false,
        },
      ],
      data: [],
    };
  },
  methods: {
    getCountDataReport() {
      return new Promise((resolve) => {
        const action = "getCountDataReport";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.data = [{ ...resp.data }];
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  mounted() {
    this.getCountDataReport();
  },
};
</script>
<style scoped></style>
