<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12>
        <v-card max-width="400" class="ma-auto" outlined>
          <v-toolbar height="100px" flat>
            <v-layout column="">
              <v-flex>
                <v-toolbar-title style="text-align: center"
                  >Регистрация</v-toolbar-title
                >
              </v-flex>
              <v-flex
                ><v-tabs v-model="tab" color="primary" grow>
                  <v-tab>
                    Организация
                  </v-tab>
                  <v-tab>
                    Пользователь
                  </v-tab>
                </v-tabs></v-flex
              >
            </v-layout>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <Org />
            </v-tab-item>
            <v-tab-item>
              <User />
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions class="px-4">
            <v-layout column>
              <v-flex class="mb-3" sx12>
                Нажимая на кнопку, я принимаю условия
                <a
                  :href="uploadUrl + 'Политика_защиты_информации.pdf'"
                  target="blank"
                  >политики защиты информации.</a
                ></v-flex
              >
              <v-flex sx12>
                <v-btn color="primary" outlined block to="login"
                  >Авторизация</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card-actions>         
        </v-card>
      </v-flex>
    </v-layout>   
  </v-container>
</template>

<script>
import Org from "./Org.vue";
import User from "./User.vue";

export default {
  components: { Org, User },
  data() {
    return {      
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,     
      tab: null,
      regDialog: false,
      textRegDialog: "",
      colorRegDialog: "",
      regLinear: false,     
      uploadRules: [
        (v) => !!v || "Необходимо загрузить документ",
        (v) => (v && v.size < 10000000) || "Не более 10 MB!",
      ],
      noDataText: "Нет данных",
    };
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },    
  },  
};
</script>
<style scoped>
.reset_btn_wrap {
  text-align: end;
}
.input_custom > input {
  background-color: rgb(255, 255, 255) !important;
}
</style>
