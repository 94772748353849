<template>
  <v-container fluid class="content">
    <v-card>
      <v-toolbar color="elevation-0">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-lg-and-up" />
        <v-toolbar-title>Личный кабинет администратора</v-toolbar-title>
      </v-toolbar>
      <v-navigation-drawer v-model="drawer" bottom app disable-resize-watcher color="grey lighten-4">
        <v-list class="grey lighten-4">
          <v-flex d-flex class="justify-end mr-3">
            <v-btn icon @click="drawer = !drawer"><v-icon>close</v-icon></v-btn>
          </v-flex>
          <template v-for="(item, i) in itemsPersonalDrawer">
            <v-divider v-if="item.divider" :key="i" dark class="my-4" />
            <v-list-item v-else-if="!item.subItems" :key="i" :to="item.url" link>
              <div class="mr-4">
                <v-icon>{{ item.icon }}</v-icon>
              </div>
              <v-list-item-content>
                <v-list-item-title v-if="item.text == 'Заявки'" class="grey--text">
                  <v-badge inline color="primary" class="mt-0" :content="requestCount" :value="requestCount">
                    {{ item.text }}
                  </v-badge>
                </v-list-item-title>
                <v-list-item-title v-else class="grey--text">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group v-else :key="i" :value="false">
                <template v-slot:activator>
                  <div class="mr-4">
                    <v-icon>{{ item.icon }}</v-icon>
                  </div>
                  <v-list-item-content>
                    <v-list-item-title class="grey--text">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="(subItem, j) in item.subItems" :key="j" :to="subItem.url" link>                  
                  <v-list-item-content class="mr-4">
                    <v-list-item-title class="grey--text">
                      {{ subItem.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <div>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </div>
                </v-list-item>
              </v-list-group>
          </template>
          <template>
            <v-list-item link @click="logout()">
              <div class="mr-4">
                <v-icon>mdi-logout</v-icon>
              </div>
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  Выход
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-layout>
        <v-flex lg2 class="hidden-md-and-down">
          <v-list class="grey lighten-4 py-0">
            <template v-for="(item, i) in itemsPersonalMenu">
              <v-list-item v-if="!item.subItems" :key="i" :to="item.url" link>
                <div class="mr-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </div>
                <v-list-item-content>
                  <v-list-item-title v-if="item.text == 'Заявки'" class="grey--text">
                    <v-badge inline color="primary" class="mt-0" :content="requestCount" :value="requestCount">
                      {{ item.text }}
                    </v-badge>
                  </v-list-item-title>
                  <v-list-item-title v-else class="grey--text">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else :key="i" :value="false">
                <template v-slot:activator>
                  <div class="mr-4">
                    <v-icon>{{ item.icon }}</v-icon>
                  </div>
                  <v-list-item-content>
                    <v-list-item-title class="grey--text">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="(subItem, j) in item.subItems" :key="j" :to="subItem.url" link>                  
                  <v-list-item-content class="mr-4">
                    <v-list-item-title class="grey--text">
                      {{ subItem.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <div>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </div>
                </v-list-item>
              </v-list-group>
            </template>
            <template>
              <v-list-item link @click="logout()">
                <div class="mr-4">
                  <v-icon>mdi-logout</v-icon>
                </div>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Выход
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex md12 lg10 class="px-4"><router-view></router-view></v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data() {
    return {
      limit: this.$store.getters.limit,
      requestCount: "",
      drawer: false,
      itemsPersonalDrawer: [
        {
          url: "/admin/profileAdmin",
          icon: "mdi-account",
          text: "Мой профиль",
        },
        {
          url: "/admin/newtenders",
          icon: "list",
          text: "Новые объявления",
        },
        {
          url: "/admin/closedtenders",
          icon: "lock",
          text: "Закрытые объявления",
        },
        {
          url: "/admin/request/registration",
          icon: "notifications_active",
          text: "Заявки",
        },
        {
          url: "",
          icon: "group",
          text: "Участники",
          subItems: [
            {
              url: "/admin/participants/organizations",
              icon: "double_arrow",
              text: "Организации"
            },
            {
              url: "/admin/participants/users",
              icon: "double_arrow",
              text: "Пользователи"
            },
          ],
        },
        {
          url: "/admin/logcontractor",
          icon: "menu_book",
          text: "Журнал посещений",
        },
        {
          url: "/admin/report/counttendersreport",
          icon: "description",
          text: "Отчеты",
        },
        {
          url: "/admin/AccessGroup",
          icon: "security",
          text: "Страхование",
        },
        { divider: true },
      ],
      itemsPersonalMenu: [
        {
          url: "/admin/profileAdmin",
          icon: "mdi-account",
          text: "Мой профиль",
        },
        {
          url: "/admin/newtenders",
          icon: "list",
          text: "Новые объявления",
        },
        {
          url: "/admin/closedtenders",
          icon: "lock",
          text: "Закрытые объявления",
        },
        {
          url: "/admin/request/registration",
          icon: "notifications_active",
          text: "Заявки",
        },
        {
          url: "",
          icon: "group",
          text: "Участники",
          subItems: [
            {
              url: "/admin/participants/organizations",
              icon: "double_arrow",
              text: "Организации"
            },
            {
              url: "/admin/participants/users",
              icon: "double_arrow",
              text: "Пользователи"
            },
          ],
        },
        {
          url: "/admin/logcontractor",
          icon: "menu_book",
          text: "Журнал посещений",
        },
        {
          url: "/admin/report/counttendersreport",
          icon: "description",
          text: "Отчеты",
        },
        {
          url: "/admin/AccessGroup",
          icon: "security",
          text: "Страхование",
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.commit("kickoff");
      this.$router.push("/login");
    },
    getRequestCount() {
      return new Promise((resolve) => {
        const action = "getRequestCount";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.requestCount = resp.requestCount;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  computed: {
    role() {
      return this.$store.getters.role;
    },
  },
  mounted() {
    this.getRequestCount();
    if (this.limit) {
      this.itemsPersonalDrawer.splice(6, 2);
      this.itemsPersonalMenu.splice(6, 2);
    }
    // this.interval = setInterval(() => {
    //   this.getRequestCount();
    // }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.content {
  max-width: 1970px;
}

/* .v-list-group__header__prepend-icon {
  margin-right: 16px !important;
} */

#keep .v-navigation-drawer__border {
  display: none;
}
</style>
