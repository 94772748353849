<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0"
      ><v-flex>Заявка на допуск для подрядчика "{{ orgInfo.name }}"</v-flex>
      <v-btn
        outlined
        color="primary"
        class="btn_action"
        @click="confirmPermissionsAdmin"
        :disabled="!permInfo.length"
        >Подтвердить</v-btn
      ></v-card-title
    >
    <v-subheader v-if="!permInfo.length">Заявок нет.</v-subheader>
    <v-card-text v-else class="px-0">
      <v-list-item-subtitle>Допуск</v-list-item-subtitle>
      <v-treeview
        v-model="permSelect"
        selectable
        return-object
        selection-type="leaf"
        selected-color="primary"
        :items="permInfo"
      ></v-treeview>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        class="btn_action"
        to="/admin/request/access"
        >назад</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      orgId: this.$route.query.id,
      orgInfo: "",
      usersInfo: "",
      permInfo: [],
      permSelect: [],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    //получаем
    getPermData() {
      return new Promise((resolve) => {
        const action = "getPermData";
        const dataParams = {
          orgId: this.orgId,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.orgInfo = resp.org;
            //модификация массива разрешений для использования в checkbox
            this.permInfo = this.transformForCheckBox(resp.perm);
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    confirmPermissionsAdmin() {
      return new Promise((resolve) => {
        const action = "confirmPermissionsAdmin";
        const dataParams = {
          orgId: this.orgId,
          permissions: this.permSelect,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result === "success") this.getPermData();
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    transformForCheckBox(array) {
      const newArray = [];
      let id = 0;
      array.forEach((itemCitylevel) => {
        const city = { id: "", name: "", children: [] };
        city.id = ++id;
        city.name = itemCitylevel.cityname;
        array.forEach((itemPermLevel) => {
          if (itemCitylevel.cityid == itemPermLevel.cityid) {
            //'-1' -  чтобы не пропускал индекс 0, worktypeid начинаются с 1
            city.children[itemPermLevel.worktypeid - 1] = {
              id: ++id,
              name: itemPermLevel.typename,
              typeId: itemPermLevel.worktypeid,
              cityId: itemCitylevel.cityid,
              cityName: itemCitylevel.cityname,
            };
          }
          this.$set(newArray, []);
          newArray[itemCitylevel.cityid - 1] = city;
        });
      });
      return newArray;
    },
  },
  computed: {   
    orgStatus() {
      let status = "";
      if (this.orgInfo.orgstatus) {
        status = "Активна";
      } else if (!this.orgInfo.orgstatus) {
        status = "Ожидает подтверждения";
      }
      return status;
    },
    userStatus() {
      let status = "";
      if (this.usersInfo.userstatus) {
        status = "Активен";
      } else if (!this.usersInfo.userstatus) {
        status = "Ожидает подтверждения";
      }
      return status;
    },
  },
  mounted() {
    this.getPermData();
  },
};
</script>

<style scoped>
.btn_wrap {
  justify-content: flex-end;
}
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
</style>
