<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0">
      Заявка подрядчика «{{ proposalData.orgname }}» по объявлению «{{
        proposalData.name
      }}»
    </v-card-title>
    <v-card-text class="px-0">
      <v-list two-line>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{ proposalData.organizer }}</v-list-item-title>
            <v-list-item-subtitle>Заказчик работ</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title
              >г. {{ proposalData.cityname }}</v-list-item-title
            >
            <v-list-item-subtitle>Адрес</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{
              formatCurrency(proposalData.betamount)
            }}</v-list-item-title>
            <v-list-item-subtitle>Стоимость работ</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title
              ><v-chip
                class="chips_proposal"
                :color="getColor(proposalData.proposalstatus)"
                dark
                >{{
                  translateProposalStatus(proposalData.proposalstatus)
                }}</v-chip
              >
            </v-list-item-title>
            <v-list-item-subtitle>Статус</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="proposalData.rejectreason" class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{
              proposalData.rejectreason
            }}</v-list-item-title>
            <v-list-item-subtitle>Причина отказа</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content v-if="proposalData.comment">
            <v-list-item-title>{{ proposalData.comment }}</v-list-item-title>
            <v-list-item-subtitle>Комментарий</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-subtitle>Комментария нет</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item class="px-0">
          <v-list-item-title>Документация:</v-list-item-title>
        </v-list-item>
        <v-list-item class="px-0" v-if="!attachments">
          <v-list-item-subtitle>Документации нет</v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          class="px-0"
          v-else
          v-for="(attach, i) in attachments"
          :key="i"
        >
          <v-layout wrap class="justify-space-between">
            <v-flex sx12 sm10 d-flex class="data_wrap align-center">
              <div>{{ attach.filename }}</div>
            </v-flex>
            <v-flex sx12 sm2 d-flex class="data_wrap align-center">
              <v-btn
                class="btn_action"
                outlined
                color="primary"
                :href="uploadUrl + attach.proposalid + '/' + attach.attachment"
                download
                >скачать</v-btn
              >
            </v-flex>
          </v-layout>
        </v-list-item>
        <v-divider class="px-0"></v-divider>
        <v-list-item
          v-if="proposalData.proposalstatus !== 'rejected'"
          class="px-0"
        >
          <v-layout column>
            <v-flex xs12 class="my-6"
              ><v-list-item-title>Отказать</v-list-item-title></v-flex
            >
            <v-flex xs12
              ><v-layout wrap class="justify-space-between">
                <v-flex sx12 sm7 d-flex class="align-center mr-2">
                  <v-textarea
                    v-model="rejectReason"
                    outlined
                    label="Причина отказа"
                    rows="2"
                    clearable
                    dense
                    no-resize
                    :rules="[(v) => !!v || 'Необходимо ввести причину']"
                  ></v-textarea>
                </v-flex>
                <v-flex sx12 sm2 d-flex class="data_wrap">
                  <v-btn
                    class="btn_action mt-3"
                    color="primary"
                    :disabled="!rejectReason"
                    @click="openDialogConfirm('Отказать?', 'reject')"
                    >отказать</v-btn
                  >
                </v-flex>
              </v-layout></v-flex
            >
          </v-layout>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-layout wrap class="justify-space-around">
        <v-btn
          outlined
          color="primary"
          :to="{
            path: '/admin/proposalsListAdmin',
            query: { tenderId },
          }"
          >вернуться к списку заявок</v-btn
        ><v-btn
          v-if="
            proposalData.tenderstatus == 'publish' &&
              (proposalData.proposalstatus == 'new' ||
                proposalData.proposalstatus == 'work')
          "
          color="primary"
          @click="openDialogConfirm('Принять предложение?', 'youWin')"
          >Принять предложение</v-btn
        >
      </v-layout>
    </v-card-actions>
    <Dialog
      @dialogDataOut="dialogDataOut = $event"
      :dialogDataIn="dialogDataIn"
    ></Dialog>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinProposal";
import mixinDialog from "../mixin/js/mixinDialog";
import Lib from "../mixin/js/mixinLib";

export default {
  mixins: [Mixin, mixinDialog, Lib],
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_PROPOSAL_URL,
      proposalId: this.$route.query.proposalId,
      tenderId: this.$route.query.tenderId,
      proposalData: "",
      attachments: [],
      dialog: false,
      dialogReject: false,
      rejectReason: null,
    };
  },
  methods: {
    getProposal() {
      return new Promise((resolve) => {
        const action = "getProposal";
        const dataParams = {
          proposalId: this.proposalId,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.proposalData = resp.proposal;
            this.attachments = resp.attachments;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    reject() {
      return new Promise((resolve) => {
        const action = "rejectAdmin";
        const dataParams = {
          proposalId: this.proposalId,
          proposalStatus: this.proposalData.proposalstatus,
          tenderId: this.proposalData.tenderid,
          rejectReason: this.rejectReason,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.openDialogInfo("Отказано!", "ok");
            } else if (resp.result === "error") {
              this.openDialogInfo(
                "Вы не являетесь владельцем данного объявления! Доступ запрещен!",
                "redirectToMainPage"
              );
            } else {
              this.openDialogInfo("Ошибка отказа!");
            }

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    youWin() {
      return new Promise((resolve) => {
        const action = "youWin";
        const dataParams = {
          proposalId: this.proposalId,
          proposalStatus: this.proposalData.proposalstatus,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.openDialogInfo("Предложение принято!", "ok");
            } else {
              this.openDialogInfo("Ошибка принятия предложения!");
            }

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    ok() {
      this.$router.push({
        path: "/admin/proposalsListAdmin",
        query: { tenderId: this.tenderId},
      });
    },
  },
  mounted() {
    this.getProposal();
  },
};
</script>

<style scoped>
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
.data_wrap {
  max-width: max-content;
}
</style>
