<template>
  <v-card
    outlined
    class="px-5"
  >
    <v-card-title class="px-0">Заявка на отзыв согласия</v-card-title>
    <v-subheader v-if="!userInfo">Заявок нет.</v-subheader>
    <v-card-text class="px-0">
      <v-card
        outlined
        v-if="userInfo"
        class="px-5"
        style="border-color: crimson"
      >
        <v-card-title class="px-0">
          <v-flex>Пользователь "{{ userInfo.login }}"</v-flex>
        </v-card-title>
        <v-card-text>
          <v-list two-line>
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title>{{ userInfo.forgetcontact }} </v-list-item-title>
                <v-list-item-subtitle>Телефон</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title>{{ userInfo.forgetreason }}</v-list-item-title>
                <v-list-item-subtitle>Причина отзыва</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            color="primary"
            class="btn_action"
            @click="openDialogConfirm(`Отклонить запрос?`,'rejectRevoke')"
          >Отклонить</v-btn>
          <v-btn
            color="primary"
            class="btn_action"
            @click="openDialogConfirm(`Забыть пользователя?`,'confirmRevoke')"
          >Подтвердить</v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        class="btn_action"
        @click="back"
      >назад</v-btn>
    </v-card-actions>
    <Dialog
      @dialogDataOut="dialogDataOut = $event"
      :dialogDataIn="dialogDataIn"
    ></Dialog>
  </v-card>
</template>

<script>
import Mixin from '../mixin/js/mixinDialog';
export default {
  mixins: [Mixin],
  data() {
    return {
      userId: this.$route.query.id,
      userInfo: ''
    };
  },
  methods: {
    confirmRevoke() {
      this.loading = true;
      this.loader = true;
      return new Promise(resolve => {
        const action = 'confirmRevoke';
        const dataParams = { userId: this.userId };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.openDialogInfo(`Пользователь забыт.`, 'back');
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    rejectRevoke() {
      this.loading = true;
      this.loader = true;
      return new Promise(resolve => {
        const action = 'rejectRevoke';
        const dataParams = { userId: this.userId };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            if (resp.result == 'success') {
              this.openDialogInfo(`Запрос пользователя отклонен.`, 'back');
            }
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getRevokeUserInfo() {
      this.loading = true;
      this.loader = true;
      return new Promise(resolve => {
        const action = 'getRevokeUserInfo';
        const dataParams = {
          userId: this.userId
        };
        this.$store
          .dispatch('Api', { action, dataParams })
          .then(resp => {
            this.userInfo = resp.data;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    }
  },
  computed: {},
  mounted() {
    this.getRevokeUserInfo();
  }
};
</script>

<style scoped>
.btn_wrap {
  justify-content: flex-end;
}
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
</style>
