<template>
  <div>
    <v-data-table
      :headers="headersTableRestriction"
      :items-per-page.sync="searchData.pagination"
      :items="dataTable.restrictions"
      locale="ru"
    >
      <template style="border-radius" v-slot:item.action="{ item }">
        <v-icon
          class="mr-5"
          outlined
          color="primary"
          @click="removeRestriction(item)"
        >
          delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Mixin from "../../mixin/js/mixinRestriction";
export default {
  mixins: [Mixin],
  props: ["dataTable"],
  data() {
    return {};
  },
  methods: {
    removeRestriction(item) {
      return new Promise((resolve) => {
        const action = "removeRestriction";
        const dataParams = {
          worktypeId: item.worktypeid,
          cityId: item.cityid,
          contractorId: item.contractorid,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.$emit("updateTable", true);
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style scoped></style>>
