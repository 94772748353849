<template>
  <v-card outlined>
    <v-card-title class="pl-9">
      Организации
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout wrap class="justify-start">
            <v-flex sx12 sm4 class=" wrap_column">
              <v-layout column class="px-5">
                <v-flex class="text_field">
                  <v-text-field
                    v-model="searchOrg"
                    append-icon="search"
                    label="Организация"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="mt-10">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            @click:row="goTo"
            locale="ru"
          >
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      noDataText: "Нет данных",
      organizations: [],
      searchOrg: null,
      headers: [
        {
          text: "Организация",
          align: "left",
          sortable: false,
          value: "name"
        }
      ]
    };
  },
  methods: {
    goTo(item) {
      this.$router.push({ path: "/admin/orgcard", query: { id: item.id } });
    },    
    getAllOrgs() {
      return new Promise(resolve => {
        const action = "getAllOrgs";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            // this.organizations = resp.organizations;
            //Для поиска добавляем элемент "все"
            // this.organizations = [{ id: 0, name: "все" }, ...resp.organizations];
            this.organizations = resp.organizations;
            // this.filteredItems = resp.organizations;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    filteredItems: {
      get() {
        return this.organizations.filter(i => {
          return (
            this.searchOrg === null ||
            i.name.toLowerCase().indexOf(this.searchOrg.toLowerCase()) != -1
          );
        });
      },
      set(v) {
        return v;
      }
    }
  },
  mounted() {
    this.getAllOrgs();
  }
};
</script>

<style scoped></style>
