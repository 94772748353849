<template>
  <v-container fluid class="content">
    <v-card>
      <v-toolbar color="elevation-0">
        <v-toolbar-title>Обработка персональных данных</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <h3>
          <v-layout column class="px-5 justify-start">
            <v-flex>
              <span>Для продолжения работы необходимо подтвердить ваше </span>
              <a color="primary" :href="uploadUrl + 'Согласие_на_получение_рассылки.pdf'" target="blank">
                <span>согласие на получение рассылки</span>
              </a>
              <span> и </span>
              <a color="primary" :href="uploadUrl + 'Согласие_на_обработку_ПД.pdf'" target="blank">
                <span>согласие на обработку персональных данных.</span>
              </a>
            </v-flex>
            <v-flex>
              <v-checkbox v-model="approveMail" label="Согласен на получение рассылки"></v-checkbox>
              <v-checkbox v-model="approveData" label="Согласен на обработку ПД"></v-checkbox>
            </v-flex>
          </v-layout>
        </h3>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="goOn()" :disabled="!approveMail || !approveData">Продолжить работу</v-btn>
        <v-btn color="primary" outlined @click="logout()">Выход</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      approveData: this.$store.getters.approveData,
      approveMail: this.$store.getters.approveMail
    };
  },
  methods: {    
    logout() {
      this.$store.commit("kickoff");
      this.$router.push("/login");
    },
    goOn() {
      return new Promise((resolve) => {
        const action = "setApprove";
        const dataParams = {
          approveData: this.approveData,
          approveMail: this.approveMail,
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result == "success") {
              this.$store.commit("setApproveData", { approveData: this.approveData });
              this.$store.commit("setApproveMail", { approveMail: this.approveMail });
              this.$router.push("/login");
            }
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },   
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped>
.content {
  max-width: 1970px;
}

span {
  white-space: pre-wrap;
}
</style>
