<template>
  <v-container fluid class="content">
    <v-card>
      <v-toolbar color="elevation-0">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-lg-and-up" />
        <v-toolbar-title>Личный кабинет заказчика</v-toolbar-title>
      </v-toolbar>
      <v-navigation-drawer v-model="drawer" bottom app disable-resize-watcher color="grey lighten-4">
        <v-list class="grey lighten-4">
          <v-flex d-flex class="justify-end mr-3">
            <v-btn icon @click="drawer = !drawer">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
          <template v-for="(item, i) in itemsPersonalDrawer">
            <v-divider v-if="item.divider" :key="i" dark class="my-4" />
            <v-list-item v-else :key="i" :to="item.url" link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template>
            <v-list-item link @click="logout()">
              <v-list-item-avatar size="20">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  Выход
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-layout>
        <v-flex lg2 class="hidden-md-and-down">
          <v-list class="grey lighten-4 py-0">
            <template v-for="(item, i) in itemsPersonalMenu">
              <v-list-item :key="i" :to="item.url" link>
                <v-list-item-avatar size="20">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template>
              <v-list-item link @click="logout()">
                <v-list-item-avatar size="20">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Выход
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex md12 lg10 class="px-4">
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: false,
    itemsPersonalDrawer: [
      {
        url: "/organizer/profileorganizer",
        icon: "mdi-account",
        text: "Мой профиль"
      },
      {
        url: "/organizer/newtenders",
        icon: "list",
        text: "Новые объявления"
      },
      {
        url: "/organizer/closedTenders",
        icon: "lock",
        text: "Закрытые объявления"
      },
      {
        url: "/organizer/restriction",
        icon: "block",
        text: "Ограничения"
      },
      { divider: true }
    ],
    itemsPersonalMenu: [
      {
        url: "/organizer/profileorganizer",
        icon: "mdi-account",
        text: "Мой профиль"
      },
      {
        url: "/organizer/newtenders",
        icon: "list",
        text: "Новые объявления"
      },
      {
        url: "/organizer/closedTenders",
        icon: "lock",
        text: "Закрытые объявления"
      },
      {
        url: "/organizer/restriction",
        icon: "block",
        text: "Ограничения"
      }
    ]
  }),
  methods: {
    logout() {
      this.$store.commit("kickoff");
      this.$router.push("/login");
    }
  },
  computed: {
    role() {
      return this.$store.getters.role;
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
.content {
  max-width: 1970px;
}

#keep .v-navigation-drawer__border {
  display: none;
}
</style>
