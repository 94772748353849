<template>
  <v-card outlined>
    <v-card-text>
      <v-layout column>
        <v-flex d-flex class="justify-end">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            class="mx-5 white--text"
            outlined
            fab
            small
            @click="getDoneTenders"
          >
            <v-icon dark>mdi-cached</v-icon>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-flex>
        <v-flex>
          <SearchTender @data="searchData = $event"></SearchTender>
        </v-flex>
        <v-flex class="mt-10">
          <TableTenders :dataTable="dataTable"></TableTenders>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Mixin from "../../mixin/js/mixinTender";
export default {
  mixins: [Mixin],
  data() {
    return {
      loader: null,
      loading: false,
      menuType: "request/done",
    };
  },
  methods: {
    getDoneTenders() {
      this.loading = true;
      this.loader = true;
      return new Promise((resolve) => {
        const action = "getDoneTenders";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.tenders = resp.tenders;
            this.filteredItems = resp.tenders;
            this.loading = false;
            this.loader = null;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  computed: {
    dataTable() {
      return {
        tenders: this.filteredItems,
        menuType: this.menuType,
        insertMenuItem: true,
      };
    },
  },
  mounted() {
    this.getDoneTenders();
  },
};
</script>

<style scoped></style>
