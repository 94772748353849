<template>
  <v-container fluid class="content">
    <v-card>
      <v-toolbar color="elevation-0">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-lg-and-up" />
        <v-toolbar-title>Личный кабинет подрядчика</v-toolbar-title>
      </v-toolbar>
      <v-navigation-drawer v-model="drawer" bottom app disable-resize-watcher color="grey lighten-4">
        <v-list class="grey lighten-4">
          <v-flex d-flex class="justify-end mr-3">
            <v-btn icon @click="drawer = !drawer">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
          <template v-for="(item, i) in itemsPersonalDrawer">
            <v-divider v-if="item.divider" :key="i" dark class="my-4" />
            <v-list-item v-else :key="i" :to="item.url" link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template>
            <v-list-item link @click="logout()">
              <v-list-item-avatar size="20">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  Выход
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-layout>
        <v-flex lg2 class="hidden-md-and-down">
          <v-list class="grey lighten-4 py-0">
            <template v-for="(item, i) in itemsPersonalMenu">
              <v-list-item :key="i" :to="item.url" link>
                <v-list-item-avatar size="20">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template>
              <v-list-item link @click="logout()">
                <v-list-item-avatar size="20">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Выход
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex md12 lg10 class="px-4">
          <router-view></router-view>
        </v-flex>
      </v-layout>
      <v-bottom-sheet v-model="notifyInsurance" persistent>
        <v-sheet class="text-center pt-3">
          <!-- <v-flex d-flex class="justify-end mr-5">
            <v-btn
              color="primary"
              icon
              @click="notifyInsurance = !notifyInsurance"
              ><v-icon>close</v-icon></v-btn
            >
          </v-flex> -->
          <v-flex class="px-5">
            <div class="font-weight-light mb-3">
              Уважаемый пользователь! Обратите внимание, что в вашем регионе
              подключена услуга страхования ответственности при заключении
              договора подряда.
            </div>
            <v-flex d-flex class="justify-center">
              <v-checkbox @click="setCookieInsurance()" v-model="checkbox" label="Больше не напоминать"></v-checkbox>
            </v-flex>
          </v-flex>
        </v-sheet>
      </v-bottom-sheet>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data() {
    return {
      prevRoute: null,
      checkbox: false,
      notifyInsurance: false,
      insurance: this.$store.getters.insurance,
      drawer: false,
      itemsPersonalDrawer: [
        {
          url: "/contractor/profile",
          icon: "mdi-account",
          text: "Мой профиль",
        },
        {
          url: "/contractor/mytenders",
          icon: "favorite_border",
          text: "Мои объявления",
        },
        {
          url: "/contractor/myproposalslist",
          icon: "flag",
          text: "Мои заявки",
        },
        {
          url: "/contractor/newtenders",
          icon: "list",
          text: "Новые объявления",
        },
        {
          url: "/contractor/wintenders",
          icon: "star",
          text: "Выигранные объявления",
        },
        { divider: true },
      ],
      itemsPersonalMenu: [
        {
          url: "/contractor/profile",
          icon: "mdi-account",
          text: "Мой профиль",
        },
        {
          url: "/contractor/mytenders",
          icon: "favorite_border",
          text: "Мои объявления",
        },
        {
          url: "/contractor/myproposalslist",
          icon: "flag",
          text: "Мои заявки",
        },
        {
          url: "/contractor/newtenders",
          icon: "list",
          text: "Новые объявления",
        },
        {
          url: "/contractor/wintenders",
          icon: "star",
          text: "Выигранные объявления",
        },
      ],
    };
  },
  methods: {
    setCookieInsurance() {
      this.$cookies.set("notifyInsurance", this.checkbox, Infinity);
      this.notifyInsurance = false;
    },
    logout() {
      this.$store.commit("kickoff");
      this.$router.push("/login");
    },
  },
  computed: {
    role() {
      return this.$store.getters.role;
    },
  },
  mounted() {
    if (this.insurance) {
      const item = {
        url: "/contractor/insurance",
        icon: "security",
        text: "Страхование",
      };
      this.itemsPersonalDrawer.splice(3, 0, item);
      this.itemsPersonalMenu.splice(3, 0, item);
    }

    if (
      this.prevRoute &&
      this.prevRoute.path == "/login" &&
      this.insurance &&
      this.$cookies.get("notifyInsurance") != "true"
    ) {
      this.notifyInsurance = true;
    }    
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped>
.content {
  max-width: 1970px;
}

#keep .v-navigation-drawer__border {
  display: none;
}
</style>
