<template>
  <v-card outlined>
    <v-card-title class="px-9">
      <v-flex>Новые объявления</v-flex>
      <v-btn outlined color="primary" to="/organizer/addtender">Создать</v-btn>
    </v-card-title>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <SearchTender @data="searchData = $event"></SearchTender>
        </v-flex>
        <v-flex class="mt-10">
          <TableTenders :dataTable="dataTable"></TableTenders>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Mixin from "../mixin/js/mixinTender";

export default {
  mixins: [Mixin],
  data() {
    return {
      menuType: "newtenders"
    };
  },
  methods: {
    getNewTendersOrganizer() {
      return new Promise(resolve => {
        const action = "getNewTendersOrganizer";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then(resp => {
            this.tenders = resp.tenders;
            this.filteredItems = resp.tenders;
            resolve(resp);
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  },
  computed: {
    dataTable() {
      return {
        tenders: this.filteredItems,
        menuType: this.menuType,
        insertMenuItem: true
      };
    }
  },
  mounted() {
    this.getNewTendersOrganizer();
  }
};
</script>

<style scoped></style>
