<template>
  <v-card outlined>
    <v-bottom-navigation
      min-height="50px"
      flat
      height="auto"
      grow
      color="primary"
    >
      <v-layout wrap>
        <v-flex xs12 sm4 class="item_title"
          ><v-btn
            block
            value="reg"
            min-height="50px"
            height="100%"
            to="/admin/report/wintendersreport"
          >
            <span color="primary" class="subtitle-2"
              >Отчет по выигрышным объявлениям</span
            >
          </v-btn></v-flex
        >
        <v-flex xs12 sm4 class="item_title"
          ><v-btn
            block
            value="access"
            min-height="50px"
            height="100%"
            to="/admin/report/counttendersreport"
          >
            <span class="subtitle-2">Отчет по объявлениям</span>
          </v-btn></v-flex
        >
        <v-flex xs12 sm4 class="item_title"
          ><v-btn
            block
            value="publish"
            min-height="50px"
            height="100%"
            to="/admin/report/analyticalreport"
          >
            <span color="primary" class="subtitle-2"
              >Аналитический отчет по объявлениям</span
            >
          </v-btn></v-flex
        >
      </v-layout>
    </v-bottom-navigation>
    <router-view></router-view>
  </v-card>
</template>

<script>
import Lib from "../../mixin/js/mixinLib";

export default {
  mixins: [Lib],
  data() {
    return {};
  },
};
</script>

<style scoped>
@media screen and (min-width: 1px) and (max-width: 850px) {
  .item_title {
    max-width: 100%;
    flex-basis: 100%;
  }
}
</style>
