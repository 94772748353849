<template>
  <v-card outlined class="px-5">
    <v-card-title class="px-0"
      ><v-flex>Группа доступа "страхование"</v-flex>
      <v-btn
        v-show="superuser"
        outlined
        color="primary"
        class="btn_action"
        @click="savePermInsuranceAdmin"
        :disabled="!permInfo.length"
        >Записать</v-btn
      ></v-card-title
    >
    <v-card-text class="px-0">
      <v-list-item-subtitle>Допуск</v-list-item-subtitle>
      <v-treeview
        v-if="superuser"
        v-model="permSelect"
        selectable
        return-object
        selection-type="leaf"
        selected-color="primary"
        :items="permInfo"
      ></v-treeview>
      <v-treeview
        v-else
        selected-color="primary"
        :items="permActive"
      ></v-treeview>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-btn
        outlined
        color="primary"
        class="btn_action"
        to="/admin/request/access"
        >назад</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      permInfo: [],
      permSelect: [],
      permActive: [],
      superuser: this.$store.getters.super,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;
      date = date.slice(0, 10);
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    //получаем
    getPermInsurance() {
      return new Promise((resolve) => {
        const action = "getPermInsurance";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.permInfo = [];
            this.permSelect = [];
            this.permActive = [];
            //модификация массива разрешений для использования в checkbox
            this.transformForCheckBox(resp.perm);
            this.transformForCheckBoxActive(resp.perm);
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    savePermInsuranceAdmin() {
      return new Promise((resolve) => {
        const action = "savePermInsuranceAdmin";
        const dataParams = {
          permissions: this.permSelect.map((item) => item.permId),
        };
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            if (resp.result === "success") this.getPermInsurance();
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    transformForCheckBox(array) {
      const newArray = [];
      let id = 0;
      array.forEach((itemCitylevel) => {
        const city = { id: "", name: "", children: [] };
        city.id = ++id;
        city.name = itemCitylevel.cityname;
        array.forEach((itemPermLevel) => {
          if (itemCitylevel.cityid == itemPermLevel.cityid) {
            const newItem = {
              id: ++id,
              name: itemPermLevel.typename,
              typeId: itemPermLevel.worktypeid,
              cityId: itemCitylevel.cityid,
              cityName: itemCitylevel.cityname,
              permId: itemPermLevel.id,
              insurance: itemPermLevel.insurance,
            };

            //'-1' -  чтобы не пропускал индекс 0, worktypeid начинаются с 1
            city.children[itemPermLevel.worktypeid - 1] = newItem;
            if (itemPermLevel.insurance) this.permSelect.push(newItem);
          }
          newArray[itemCitylevel.cityid - 1] = city;
        });
      });
      this.permInfo = newArray;
    },
    transformForCheckBoxActive(array) {
      let newArray = [];
      let id = 0;
      array.forEach((itemCitylevel) => {
        const city = { id: "", name: "", children: [] };
        city.id = ++id;
        city.name = itemCitylevel.cityname;
        array.forEach((itemPermLevel) => {
          if (
            itemCitylevel.cityid == itemPermLevel.cityid &&
            itemPermLevel.insurance
          ) {
            //'-1' -  чтобы не пропускал индекс 0, worktypeid начинаются с 1
            city.children.push({ name: itemPermLevel.typename });
          }
          if (city.children.length) {
            newArray[itemCitylevel.cityid - 1] = city;
          }
        });
      });
      this.permActive = newArray;
    },
  },
  mounted() {
    this.getPermInsurance();
  },
};
</script>

<style scoped>
.btn_wrap {
  justify-content: flex-end;
}
.new_proposal {
  font-weight: 700;
  color: lime;
}
.text-center {
  text-align: right;
}
.btnWidth {
  width: 150px;
}
</style>
