var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pl-8"},[_vm._v("Новый объект к объявлению")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-list',[_c('v-list-item',[_c('v-layout',{staticClass:"justify-space-between",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"maxlength":_vm.$store.getters.titleAdMaxLength,"type":"text","label":"Название","rules":[function (v) { return !!v || 'Необходимо ввести название объявления'; }],"required":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)],1),_c('v-list-item',[_c('v-layout',{staticClass:"justify-space-between",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm5":""}},[_c('v-text-field',{attrs:{"type":"text","label":"Объем работ","rules":[function (v) { return !!v || 'Необходимо ввести объем работ'; }],"required":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})],1),_c('v-flex',{attrs:{"xs12":"","sm5":""}},[_c('v-text-field',{attrs:{"type":"text","label":"Улица","rules":[
                  function (v) { return !!v || 'Необходимо ввести улицу'; },
                  function (v) { return (v && v.length <= this$1.maxStreetLength) ||
                    ("Наименование улицы должно быть не более " + (this$1.maxStreetLength) + " символов"); }
                ],"clearable":"","required":"","outlined":"","dense":""},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})],1)],1)],1),_c('v-list-item',[_c('v-layout',{staticClass:"justify-space-between",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm5":""}},[_c('v-text-field',{attrs:{"type":"text","label":"Дом","rules":[
                  function (v) { return !!v || 'Необходимо ввести дом'; },
                  function (v) { return (v && v.length <= this$1.maxHouseNumberLength) ||
                    ("Номер дома должен быть не более " + (this$1.maxHouseNumberLength) + " символов"); }
                ],"clearable":"","required":"","outlined":"","dense":""},model:{value:(_vm.homenumber),callback:function ($$v) {_vm.homenumber=$$v},expression:"homenumber"}})],1)],1)],1),_c('v-card-subtitle',{staticClass:"px-0"},[_vm._v("Приложения")]),_c('v-card-text',_vm._l((_vm.attach),function(item,i){return _c('v-layout',{key:i,staticClass:"justify-space-between",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mb-4",attrs:{"xs12":"","sm5":""}},[_c('v-file-input',{attrs:{"type":"file","outlined":"","label":"Загрузить документ","rules":_vm.uploadRules,"dense":""},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}})],1),_c('v-flex',{staticClass:"mb-4",attrs:{"d-flex":"","xs12":"","sm5":""}},[_c('v-text-field',{attrs:{"maxlength":_vm.$store.getters.fileNameMaxLength,"type":"text","label":"Имя файла","rules":[function (v) { return !!v || 'Необходимо ввести имя файла'; }],"outlined":"","dense":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-btn',{staticClass:"ml-3 mt-n2",attrs:{"x-large":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.removeAttach(i)}}},[_c('v-icon',[_vm._v("delete_outline")])],1)],1)],1)}),1),_c('v-divider'),_c('v-card-actions',{staticClass:"px-4"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addAttach}},[_c('div',[_vm._v("Добавить приложение")])])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{attrs:{"outlined":"","color":"primary","to":{
        path: '/organizer/tender',
        query: { id: _vm.tenderId }
      }}},[_vm._v(" отмена ")]),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.openDialogConfirm('Сохранить объект?', 'addLot')}}},[_vm._v(" Сохранить объект ")])],1),_c('Dialog',{attrs:{"dialogDataIn":_vm.dialogDataIn},on:{"dialogDataOut":function($event){_vm.dialogDataOut = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }