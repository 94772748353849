import "../css/tender.css";
import "../css/style.css";

export default {
  data() {
    return {
      searchData: this.$store.getters[`${this.$router.currentRoute.name}`],
      citiesList: [],
      worktypes: [],
      citiesListSearch: [],
      worktypesSearch: [],
      restrictions: [],
      headersTableRestriction: [
        {
          text: "Организация",
          align: "left",
          sortable: false,
          value: "contractor",
        },
        {
          text: "Город",
          sortable: false,
          value: "city",
        },
        {
          text: "Тип работ",
          sortable: false,
          value: "type",
        },
        {
          text: "Действие",
          align: "right",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  computed: {   
    start_date: {
      get() {
        return this.formatDate(this.searchData.start_dateISO);
      },
      set(v) {
        this.searchData.start_dateISO = v;
        return v;
      },
    },
    end_date: {
      get() {
        return this.formatDate(this.searchData.end_dateISO);
      },
      set(v) {
        this.searchData.end_dateISO = v;
        return v;
      },
    },
    filteredItems: {
      get() {
        return this.restrictions
          .filter((i) => {
            return (
              this.searchData.contractor === null ||
              i.contractor
                .toLowerCase()
                .indexOf(this.searchData.contractor.toLowerCase()) != -1
            );
          })
          .filter((i) => {
            return (
              this.searchData.city === "все" ||
              i.city
                .toLowerCase()
                .indexOf(this.searchData.city.toLowerCase()) != -1
            );
          })
          .filter((i) => {
            return (
              this.searchData.type === "все" ||
              i.type
                .toLowerCase()
                .indexOf(this.searchData.type.toLowerCase()) != -1
            );
          });
      },
      set(v) {
        return v;
      },
    },
  },
  methods: {
    getRestrictions() {
      return new Promise((resolve) => {
        const action = "getRestrictions";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.restrictions = resp.restrictions;
            this.filteredItems = resp.restrictions;
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getCitiesList() {
      return new Promise((resolve) => {
        const action = "getCitiesList";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.citiesList = resp.citiesList;
            //Для поиска добавляем элемент "все"
            this.citiesListSearch = [
              { id: 0, name: "все" },
              ...resp.citiesList,
            ];

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getWorkTypes() {
      return new Promise((resolve) => {
        const action = "getWorkTypes";
        const dataParams = {};
        this.$store
          .dispatch("Api", { action, dataParams })
          .then((resp) => {
            this.worktypes = resp.worktypes;
            //Для поиска добавляем элемент "все"
            this.worktypesSearch = [{ id: 0, type: "все" }, ...resp.worktypes];
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  } 
};
